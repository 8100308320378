import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles';
import {
	Redirect
} from "react-router-dom";
import '../App.css'
import StyledButton from "../StyledButton";
import Paper from '@material-ui/core/Paper'
import GetStepContentComponentDirections from './GetStepContentComponentDirections'
import '../App.css'

axios.defaults.baseURL = process.env.REACT_APP_BACK_END_URL;

const styles = theme => ({
	root: {
		width: '65%',
		margin: 'auto'
	},
	backButton: {
		marginRight: theme.spacing.unit,
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	stepIcon: {
		color: '#00677F !important'
	},
});

function getSteps() {
	return ['Welcome', 'Get Information', 'Results'];
}

class HorizontalLabelPositionBelowStepperDirections extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			activeStep: 0,
			isReserving: false,
			orderWording: 'Each party shall provide standard disclosure by list to all other parties by 4pm on 01 March 2019.',
			mostLikely: '',
			leastLikely: '',
			date: '',
			isTextExtracted: false,
		}
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleChangeText = this.handleChangeText.bind(this)
	}

	handleNext = () => {

		this.setState(state => ({
			activeStep: state.activeStep + 1,
		}));
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1,
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 1,
			orderWording: 'Each party shall provide standard disclosure by list to all other parties by 4pm on 01 March 2019.'
		});
	};

	handleClickReserving = () => {
		this.setState(prevState => {
			return {
				isReserving: !prevState.isReserving
			}
		})
	}

	handleSubmit(event) {
		event.preventDefault()
		axios.post("api/getdirection",
			{
				wording: this.state.orderWording
			}, {
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				}
			})
			.then(function (response) {
				this.setState({
					date: response.data.date,
					mostLikely: response.data.most_likely,
					leastLikely: response.data.least_likely,
					isTextExtracted: !this.state.isTextExtracted,
					activeStep: this.state.activeStep + 1
				});
			}.bind(this))
	}

	handleChange = name => event => {
		this.setState({[name]: event.target.value})
	}

	handleChangeText(event) {
		const {name, value} = event.target
		this.setState({[name]: value})
	}

	render() {
		const {classes} = this.props;
		const steps = getSteps();
		const {activeStep} = this.state;

		if (this.state.isReserving) {
			return <Redirect to={{pathname: '/reserving'}}/>
		}

		return (
			<div className='projects-container'>
				<Paper classes={{root: 'projects-paper'}} elevation={0}>
					<div>
						<div>
							<GetStepContentComponentDirections
								step={activeStep}
								handleChange={this.handleChange}
								handleChangeText={this.handleChangeText}
								handleSubmit={this.handleSubmit}
								data={this.state}
							/>
							<div>
								{activeStep === 2 ?
									<StyledButton
										variant="contained"
										color="primary"
										className={classes.backButton}
										onClick={this.handleReset}
									>
										Reset
									</StyledButton>
									:
									<StyledButton
										disabled={activeStep === 0}
										onClick={this.handleBack}
										className={classes.backButton}
									>
										Back
									</StyledButton>}
								{activeStep === 1 ?
									<StyledButton
										type="submit"
										variant="contained"
										color="primary"
										onClick={this.handleSubmit}>
										Next
									</StyledButton> :
									activeStep === 2 ?
										<StyledButton
											onClick={this.handleClickReserving}
											variant="contained"
											color="primary"
										>
											Next Project
										</StyledButton>
										:
										<StyledButton variant="contained" color="primary" onClick={this.handleNext}>
											Next
										</StyledButton>
								}
							</div>
						</div>
					</div>
				</Paper>
			</div>
		);
	}
}

HorizontalLabelPositionBelowStepperDirections.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepperDirections);
