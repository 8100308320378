import React from 'react'
import HorizontalLabelPositionBelowStepperReserving from './HorizontalStepperReserving'

const StepperCustom = {
  textAlign: 'center',
  marginTop: "4%"
}

const Reserving = () => (
  <div>
    <br />
    <div style={StepperCustom}>
      <HorizontalLabelPositionBelowStepperReserving />
    </div>
  </div>
)

export default Reserving
