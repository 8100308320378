import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
} from 'react-router-dom'

import './App.css';
import Home from './Home/Home.js'
import Projects from './Home/Projects'
import About from './Home/About'
import ContactUs from './Home/ContactUs'
import NavBar from "./NavBar";
import Directions from "./directions_app/Directions";
import Extraction from "./databreach_app/Extraction";
import Reserving from "./reserving_app/Reserving";
import Fraud from "./fraud_app/Fraud";
import Coverage from "./coverage_app/Coverage"
import Find from "./find_app/Find";

require('es6-promise').polyfill();
require('isomorphic-fetch');

const App = () => (
	<div>
		<Router>
			<NavBar/>
			<Switch>
				<Route path="/" component={Home} exact/>
				<Route path="/home" component={Home}/>
				<Route path="/projects" component={Projects}/>
				<Route path="/about-us" component={About}/>
				<Route path="/contact-us" component={ContactUs}/>
				<Route path="/databreach" component={Extraction}/>
				<Route path="/coverage" component={Coverage}/>
				<Route path="/find" component={Find}/>
				<Route path="/directions" component={Directions}/>
				<Route path="/reserving" component={Reserving}/>
				<Route path="/fraud" component={Fraud}/>
			</Switch>
		</Router>
	</div>
)

export default App