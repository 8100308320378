import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles';
import {
	Redirect
} from "react-router-dom";
import '../App.css'
import StyledButton from "../StyledButton";
import Paper from '@material-ui/core/Paper'
import GetStepContentComponentReserving from './GetStepContentComponentReserving'

const styles = theme => ({
	root: {
		width: '65%',
		margin: 'auto'
	},
	backButton: {
		marginRight: theme.spacing.unit,
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	stepIcon: {
		color: '#00677F !important'
	},
});

function getSteps() {
	return ['Welcome', 'Get Information', 'Results'];
}

class HorizontalLabelPositionBelowStepperReserving extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			activeStep: 0,
			isFind: false,
			injury: 'Neck',
			severity: 'Mild',
			businessLine: 'PUB',
			instructionType: 'QUA',
			track: 'MUL',
			matterRate: 'Zurich - Scheme Delegated Authority - Fixed Fee',
			clMfu: 'ZM',
			dateIncident: '',
			dateReceiptInstructions: '',
			matterLocation: 'GB21  Manchester  C&Co Claims LLP',
			experts: '0',
			solicitors: '2',
			claimantSolicitors: 'Bakers',
			selectedFile: '',
			isFile: false,
			totalCost: '',
			reserve: ''
			//postcode: 'CT1 1ZA'
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeText = this.handleChangeText.bind(this);
		this.handleChangeSolicitors = this.handleChangeSolicitors.bind(this);
	}

	handleNext = () => {

		this.setState(state => ({
			activeStep: state.activeStep + 1,
		}));
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1,
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 1,
			department: 'ENT',
			injury: 'Neck',
			severity: 'Mild',
			businessLine: 'PUB',
			instructionType: 'QUA',
			track: 'MUL',
			matterRate: 'Zurich - Scheme Delegated Authority - Fixed Fee',
			clMfu: 'ZM',
			dateIncident: '',
			dateReceiptInstructions: '',
			matterLocation: 'GB21  Manchester  C&Co Claims LLP',
			experts: '0',
			solicitors: '2',
			claimantSolicitors: 'Bakers',
			selectedFile: '',
			isFile: false,
			totalCost: '',
			reserve: ''
			//postcode: 'CT1 1ZA'
		});
	};

	handleClickFind = () => {
		this.setState(prevState => {
			return {
				isFind: !prevState.isFind
			}
		})
	}

	handleSubmit(event) {
		event.preventDefault()
		axios.post("/api/getreserve",
			{
				data_options: {
					'injury': this.state.injury,
					'severity': this.state.severity,
					'businessLine': this.state.businessLine,
					'instructionType': this.state.instructionType,
					'track': this.state.track,
					'matterRate': this.state.matterRate,
					'clMfu': this.state.clMfu,
					'dateIncident': this.state.dateIncident,
					'dateReceiptInstructions': this.state.dateReceiptInstructions,
					'matterLocation': this.state.matterLocation,
					'experts': this.state.experts,
					'solicitors': this.state.solicitors,
					'claimantSolicitors': this.state.claimantSolicitors,
					'reserve': this.state.reserve
				}
			},{
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				}
			})
			.then(function(response){
				this.setState({
					totalCost: response.data.totalCost,
					activeStep: this.state.activeStep + 1
				});
			}.bind(this))
	}

	handleChange = name => event => {
		console.log('here')
		this.setState({[name]: event.target.value})
	}

	handleChangeSolicitors = name => event => {
		console.log('here')
		this.setState({[name]: event.target.value})
	}

	handleChangeText(event) {
		const {name, value} = event.target
		this.setState({[name]: value})
	}

	render() {
		const {classes} = this.props;
		const steps = getSteps();
		const {activeStep} = this.state;

		if (this.state.isFind) {
			return <Redirect to={{pathname: '/find'}}/>
		}

		return (
			<div className="projects-container">
				<Paper classes={{root: 'projects-paper'}} elevation={0}>
					<div>
						<div>
							<GetStepContentComponentReserving
								step={activeStep}
								handleChange={this.handleChange}
								handleChangeText={this.handleChangeText}
								handleSubmit={this.handleSubmit}
								handleChangeSolicitors={this.handleChangeSolicitors}
								data={this.state}
							/>
							<div>
								{activeStep === 2 ?
									<StyledButton
										variant="contained"
										color="primary"
										className={classes.backButton}
										onClick={this.handleReset}
									>
										Reset
									</StyledButton>
									:
									<StyledButton
										disabled={activeStep === 0}
										onClick={this.handleBack}
										className={classes.backButton}
									>
										Back
									</StyledButton>}
								{activeStep === 1 ?
									<StyledButton
										type="submit"
										variant="contained"
										color="primary"
										onClick={this.handleSubmit}>
										Next
									</StyledButton> :
									activeStep === 2 ?
										<StyledButton
											onClick={this.handleClickFind}
											variant="contained"
											color="primary"
										>
											Next Project
										</StyledButton>
										:
										<StyledButton variant="contained" color="primary" onClick={this.handleNext}>
											Next
										</StyledButton>
								}
							</div>
						</div>
					</div>
				</Paper>
			</div>
		);
	}
}

HorizontalLabelPositionBelowStepperReserving.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepperReserving);
