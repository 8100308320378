import React from "react"
import FormComponentInitial from './FormComponentInitial'

function FormComponentSecond(props){

  return(
    <div>
      <div className='projects-container-text'>
        <br/>
        <FormComponentInitial
          handleChange={props.handleChange}
          handleChangeText={props.handleChangeText}
          data={props.data}
        />
        <br />
      </div>
      <br />
    </div>
  )
}

export default FormComponentSecond
