import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles ={
  card:{
    minHeight: 140
  },
  title:{
    fontSize: 16,
    textAlign: 'left'
  },
  button:{
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}


function FormComponentThirdDirections(props){

  const noDates =
  "No dates were found in the input. Please input some text with dates."

  const noDirections =
  "Please input some text with directions."

  const disclosure=
  "This direction relates to Disclosure."

  const witness=
  "This direction relates to Witness Statements."

  const expert=
  "This direction relates to Expert Reports."

  const joint=
  "This direction relates to Expert Joint Statements."

  const notSure=
  "This machine does not recognise this direction. This may be because it is "+
  "not one of the 4 direction types it has been trained on or because it is not " +
  "sufficiently confident that it can recognise it."

  return(
    <div>
      <div className='projects-container-text'>
        <br/>
        { props.data.orderWording.length > 0 ?
        <div>
          {props.data.mostLikely === 'Disclosure' ?
          <h3 className='projects-question'>Classification: {disclosure}</h3> :
          props.data.mostLikely === 'Expert' ?
          <h3 className='projects-question'>Classification: {expert}</h3> :
          props.data.mostLikely === 'Joint' ?
          <h3 className='projects-question'>Classification: {joint}</h3> :
          props.data.mostLikely === 'Witness' ?
          <h3 className='projects-question'>Classification: {witness}</h3> :
          props.data.mostLikely === 'Not Sure' ?
          <h3 className='projects-question'>Classification: {notSure}</h3> :
          null
          }
          <h3 className='projects-question'>Date: {props.data.date}</h3>
          <br/>
          { props.data.date === 'No date detected'?
            <p className='projects-error'>{noDates}</p> :null
          }
        </div> : <p className='projects-error'>{noDirections}</p>
      }
      </div>
      <br />
      <br />
    </div>
  )
}

export default withStyles(styles)(FormComponentThirdDirections)
