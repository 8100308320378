import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles';
import {
	Redirect
} from "react-router-dom";
import Paper from '@material-ui/core/Paper'
import '../App.css'
import GetStepContentComponentFraud from './GetStepContentComponentFraud'
import StyledButton from "../StyledButton";

const styles = theme => ({
	root: {
		width: '65%',
		margin: 'auto'
	},
	backButton: {
		marginRight: theme.spacing.unit,
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	stepIcon: {
		color: '#00677F !important'
	},
});

function getSteps() {
	return ['Welcome', 'Get Information', 'Results'];
}


//const [dateAccident, handleDateChange] = useState(new Date());

class HorizontalLabelPositionBelowStepperFraud extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			activeStep: 0,
			isCoverage: false,
			dateCaseOpenned: '',
			dateMatterOpenned: '',
			dateAccident: '',
			litigated: 'N',
			personalInjury: 'Y',
			defendants: '1',
			claimants: '5',
			claimantDob: '',
			claimantGender: 'M',
			claimantOccupation: 'Unknown',
			claimantVehicle: 'BMW',
			insuredVehicle: 'BMW',
			//insuredPostcode: 'RM11 1PH',
			claimantSolicitors: 'Armstrongs Solicitors',
			claimantStatus: 'Middle Class',
			location: 'Junction',
			injuryFirstReserve: '50',
			vehicleFirstReserve: '0',
			answer: ''
		}
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleChangeText = this.handleChangeText.bind(this)
	}

	handleNext = () => {
		this.setState(state => ({
			activeStep: state.activeStep + 1,
		}));
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1,
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 1,
			dateCaseOpenned: '',
			dateMatterOpenned: '',
			dateAccident: '',
			litigated: 'N',
			personalInjury: 'Y',
			defendants: '1',
			claimants: '5',
			claimantDob: '',
			claimantGender: 'M',
			claimantOccupation: 'Unknown',
			claimantVehicle: 'BMW',
			insuredVehicle: 'BMW',
			//insuredPostcode: 'RM11 1PH',
			claimantSolicitors: 'Armstrongs Solicitors',
			claimantStatus: 'Middle Class',
			location: 'Junction',
			injuryFirstReserve: '50',
			vehicleFirstReserve: '0',
		});
	};

	handleClickCoverage = () => {
		this.setState(prevState => {
			return {
				isCoverage: !prevState.isCoverage
			}
		})
	}

	handleSubmit(event) {
		event.preventDefault()
		axios.post("/api/getfraud",{
			data_options: {
				'dateCaseOpenned': this.state.dateMatterOpenned,
				'dateMatterOpenned': this.state.dateMatterOpenned,
				'dateAccident': this.state.dateAccident,
				'litigated': this.state.litigated,
				'personalInjury': this.state.personalInjury,
				'defendants': this.state.defendants,
				'claimants': this.state.claimants,
				'claimantDob': this.state.claimantDob,
				'claimantGender': this.state.claimantGender,
				'claimantOccupation': this.state.claimantOccupation,
				'claimantVehicle': this.state.claimantVehicle,
				'insuredVehicle': this.state.insuredVehicle,
				'claimantSolicitors': this.state.claimantSolicitors,
				'claimantStatus': 'Middle Class',
				'location': 'Aylestone Road, Leicester',
				'injuryFirstReserve': this.state.injuryFirstReserve,
				'vehicleFirstReserve': this.state.vehicleFirstReserve
			}
		},{
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		}).then(function(response){
			this.setState({
				answer: response.data.answer,
				activeStep: this.state.activeStep + 1
			});
		}.bind(this))
	}

	handleChange = name => event => {
		this.setState({[name]: event.target.value})
	}

	handleChangeText(event) {
		const {name, value} = event.target
		this.setState({[name]: value})
	}

	render() {
		const {classes} = this.props;
		const steps = getSteps();
		const {activeStep} = this.state;

		if (this.state.isCoverage) {
			return <Redirect to={{pathname: '/coverage'}}/>
		}

		return (
			<div className="projects-container">
				<Paper classes={{root: 'projects-paper'}} elevation={0}>
					<div>
						<div>
							<GetStepContentComponentFraud
								step={activeStep}
								handleChange={this.handleChange}
								handleChangeText={this.handleChangeText}
								data={this.state}
							/>
							<div>
								{activeStep === 2 ?
									<StyledButton
										variant="contained"
										color="primary"
										className={classes.backButton}
										onClick={this.handleReset}
									>
										Reset
									</StyledButton>
									:
									<StyledButton
										disabled={activeStep === 0}
										onClick={this.handleBack}
										className={classes.backButton}
									>
										Back
									</StyledButton>}
								{activeStep === 1 ?
									<StyledButton
										type="submit"
										variant="contained"
										color="primary"
										onClick={this.handleSubmit}>
										Next
									</StyledButton> :
									activeStep === 2 ?
										<StyledButton
											onClick={this.handleClickCoverage}
											variant="contained"
											color="primary"
										>
											Next Project
										</StyledButton>
									:
									<StyledButton variant="contained" color="primary" onClick={this.handleNext}>
										Next
									</StyledButton>
								}
							</div>
						</div>
					</div>
				</Paper>
			</div>
		);
	}
}

HorizontalLabelPositionBelowStepperFraud.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepperFraud);
