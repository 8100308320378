import React from 'react';
import '../App.css'
import {homeP1, homeP2, homeP2a, homeP2b, homeP3, homeP4, homeHeading}  from '../descriptions'
import {Link} from "react-router-dom";


function Home(){
	return(
		<div className="home">
			<div className='home-about-text'>
				<h1>{homeHeading}</h1>
				<br/>
				<h2>{homeP1}</h2>
				<h2>{homeP2}</h2>
				<ul>
					<li>{homeP2a}</li>
					<li>{homeP2b}</li>
				</ul>
				<h2>{homeP3}</h2>
				<h2>{homeP4}</h2>
				<br/>
				<Link to={'/projects'} style={{textDecoration: 'none'}}>
					<h2 style={{color:'white', fontSize: "bold", fontWeight: "800"}}>See our Projects</h2>
				</Link>
				<br/>
			</div>
		</div>
	)
}

export default Home