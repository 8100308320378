import React from 'react'
import TextField from "@material-ui/core/TextField"
import {withStyles} from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import {
	litigated,
	personalInjury,
	claimantGender,
	claimantOccupationReduced,
	vehicleReduced,
} from './fraudData'

import {claimantSolicitorsReduced} from './fraudSolicitors'
import {locationPartOfStreet} from './fraudLocation'

import {
	fraud_intro
} from '../descriptions'

const styles = {
	card: {
		minHeight: 140
	},
	title: {
		fontSize: 16,
		textAlign: 'left',
		marginLeft: '5%'
	},
	subtitle: {
		fontSize: 14,
		textAlign: 'left',
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: '5%',
		width: 150
	},
	textField: {
		//width: 400,
		textAlign: 'left',
		//marginLeft: '5%'
	},
	menu: {
	}
}

function FormComponentSecondFraud(props) {

	const squareStyle = {
		display: 'inline-block',
		margin: '0 5px',
		transform: 'scale(0.8)',
		color: '#00677F'
	}

	const {classes} = props
	const square = <span style={squareStyle}>■</span>

	return (
		<div>
			<div className='projects-container-text'>
				<h3 className='projects-question'>{fraud_intro}</h3>
				<br/>
				<h3 className='projects-heading'>{square}Our instruction</h3>
				<h3 className='projects-question'>When were we instructed?</h3>
				<TextField
					id="date-matter-openned"
					type="date"
					onChange={props.handleChangeText}
					value={props.data.dateMatterOpenned}
					name="dateMatterOpenned"
					InputLabelProps={{
						shrink: true
					}}
					//className = {classes.textField}
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-heading'>{square}About the incident</h3>
				<h3 className='projects-question'>When did the accident occur?</h3>
				<TextField
					id="date-accident"
					type="date"
					onChange={props.handleChangeText}
					value={props.data.dateAccident}
					name="dateAccident"
					InputLabelProps={{
						shrink: true
					}}
					//className = {classes.textField}
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>Where did the accident occur?</h3>
				<TextField
					id="location"
					select
					value={props.data.location}
					className={classes.textField}
					onChange={props.handleChange('location')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{locationPartOfStreet.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>Was there personal injury?</h3>
				<TextField
					id="personal-injury"
					select
					value={props.data.personalInjury}
					className={classes.textField}
					onChange={props.handleChange('personalInjury')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{personalInjury.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-heading'>{square}About the claim</h3>
				<h3 className='projects-question'>Is the claim being litigated?</h3>
				<TextField
					id="litigated"
					select
					value={props.data.litigated}
					className={classes.textField}
					onChange={props.handleChange('litigated')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{litigated.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>How many defendants are involved?</h3>
				<TextField
					id='defendants'
					value={props.data.defendants}
					onChange={props.handleChange('defendants')}
					type="text"
					className={classes.textField}
					InputLabelProps={{
						shrink: true
					}}
					margin='normal'
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>How many claimants are involved?</h3>
				<TextField
					id='claimants'
					value={props.data.claimants}
					onChange={props.handleChange('claimants')}
					type="text"
					className={classes.textField}
					InputLabelProps={{
						shrink: true
					}}
					margin='normal'
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What is the primary claimant's date of birth?</h3>
				<TextField
					id="claimant-dob"
					type="date"
					onChange={props.handleChangeText}
					value={props.data.claimantDob}
					name="claimantDob"
					InputLabelProps={{
						shrink: true
					}}
					//className = {classes.textField}
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What is the primary claimant's gender?</h3>
				<TextField
					id="claimant-gender"
					select
					value={props.data.claimantGender}
					className={classes.textField}
					onChange={props.handleChange('claimantGender')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{claimantGender.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What is the primary claimant's occupation?</h3>
				<TextField
					id="claimant-occupation"
					select
					value={props.data.claimantOccupation}
					className={classes.textField}
					onChange={props.handleChange('claimantOccupation')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{claimantOccupationReduced.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What make is the primary claimant's vehicle?</h3>
				<TextField
					id="claimant-vehicle-type"
					select
					value={props.data.claimantVehicle}
					className={classes.textField}
					onChange={props.handleChange('claimantVehicle')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{vehicleReduced.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What make is the primary defendant's (insured) vehicle?</h3>
				<TextField
					id="insured-vehicle-type"
					select
					value={props.data.insuredVehicle}
					className={classes.textField}
					onChange={props.handleChange('insuredVehicle')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{vehicleReduced.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>Who are the primary claimant's solicitors?</h3>
				<TextField
					id="claimant-solicitors"
					select
					value={props.data.claimantSolicitors}
					className={classes.textField}
					onChange={props.handleChange('claimantSolicitors')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{claimantSolicitorsReduced.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				{/*<h3 style={question}>Claimant social class:</h3>*/}
				{/*<TextField*/}
				{/*  id = "claimant-postcode"*/}
				{/*  select*/}
				{/*  value = {props.data.claimantStatus}*/}
				{/*  className = {classes.textField}*/}
				{/*  onChange = {props.handleChange('claimantStatus')}*/}
				{/*  SelectProps = {{*/}
				{/*    MenuProps: {*/}
				{/*      className: classes.menu,*/}
				{/*    },*/}
				{/*  }}*/}
				{/*  margin = "normal"*/}
				{/*  fullWidth*/}
				{/*>*/}
				{/*  {claimantStatus.map(option => (*/}
				{/*    <MenuItem key={option.value} value={option.value}>*/}
				{/*      {option.label}*/}
				{/*    </MenuItem>*/}
				{/*  ))}*/}
				{/*</TextField>*/}
				{/*<br/>*/}
				{/*<br />*/}
				{/*<br />*/}

				<h3 className='projects-question'>What are the estimated damages for injury (£)?</h3>
				<TextField
					id='injury-first-reserve'
					value={props.data.injuryFirstReserve}
					onChange={props.handleChange('injuryFirstReserve')}
					type="text"
					className={classes.textField}
					InputLabelProps={{
						shrink: true
					}}
					margin='normal'
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What are the estimated vehicle repair costs (£)?</h3>
				<TextField
					id='vehicle-first-reserve'
					value={props.data.vehicleFirstReserve}
					onChange={props.handleChange('vehicleFirstReserve')}
					type="text"
					className={classes.textField}
					InputLabelProps={{
						shrink: true
					}}
					margin='normal'
					fullWidth
				/>
			</div>
			<br/>
			<br/>
			<br/>
			<br/>
		</div>
	)
}

export default withStyles(styles)(FormComponentSecondFraud)
