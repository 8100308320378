const claimantSolicitors = [
  {
    'value': 'Armstrongs',
    'label': 'Armstrongs'
  }, {
    'value': 'Coyne Learmonth',
    'label': 'Coyne Learmonth'
  }, {
    'value': 'Chambers Solicitors',
    'label': 'Chambers Solicitors'
  }, {
    'value': 'Marsdens',
    'label': 'Marsdens'
  }, {
    'value': 'Tickle Hall Cross',
    'label': 'Tickle Hall Cross'
  }, {
    'value': 'Curtis Law',
    'label': 'Curtis Law'
  }, {
    'value': 'Mendell Solicitors',
    'label': 'Mendell Solicitors'
  }, {
    'value': 'Minster Law',
    'label': 'Minster Law'
  }, {
    'value': 'Cartwrights Solicitors',
    'label': 'Cartwrights Solicitors'
  }, {
    'value': 'Greenhead Solicitors',
    'label': 'Greenhead Solicitors'
  }, {
    'value': 'J C & A',
    'label': 'J C & A'
  }, {
    'value': 'PM Law',
    'label': 'PM Law'
  }, {
    'value': 'Mooney Everett',
    'label': 'Mooney Everett'
  }, {
    'value': 'True Solicitors',
    'label': 'True Solicitors'
  }, {
    'value': 'AJ Law',
    'label': 'AJ Law'
  }, {
    'value': 'Gorman Hamilton Solicitors',
    'label': 'Gorman Hamilton Solicitors'
  }, {
    'value': 'JC & A Solicitors',
    'label': 'JC & A Solicitors'
  }, {
    'value': 'Broadway Solicitors',
    'label': 'Broadway Solicitors'
  }, {
    'value': 'Marlowe Braide',
    'label': 'Marlowe Braide'
  }, {
    'value': 'Easthams',
    'label': 'Easthams'
  }, {
    'value': 'Camps',
    'label': 'Camps'
  }, {
    'value': 'Latimer Lee',
    'label': 'Latimer Lee'
  }, {
    'value': 'Sovereign Solicitors',
    'label': 'Sovereign Solicitors'
  }, {
    'value': 'Sintons LLP',
    'label': 'Sintons LLP'
  }, {
    'value': 'McKays Solicitors',
    'label': 'McKays Solicitors'
  }, {
    'value': 'Nesbitt Law Group',
    'label': 'Nesbitt Law Group'
  }, {
    'value': 'Amanda Cunliffe',
    'label': 'Amanda Cunliffe'
  }, {
    'value': 'Gorman Hamilton',
    'label': 'Gorman Hamilton'
  }, {
    'value': 'Cartwright Solicitors',
    'label': 'Cartwright Solicitors'
  }, {
    'value': 'SGI Legal',
    'label': 'SGI Legal'
  }, {
    'value': 'Versus Law',
    'label': 'Versus Law'
  }, {
    'value': 'DGM Solicitors',
    'label': 'DGM Solicitors'
  }, {
    'value': 'Asons',
    'label': 'Asons'
  }, {
    'value': 'Abbey Solicitors',
    'label': 'Abbey Solicitors'
  }, {
    'value': 'True',
    'label': 'True'
  }, {
    'value': 'Bollin Legal',
    'label': 'Bollin Legal'
  }, {
    'value': 'Wosskow Brown',
    'label': 'Wosskow Brown'
  }, {
    'value': 'Silverbeck Rymer',
    'label': 'Silverbeck Rymer'
  }, {
    'value': 'Hadi Law',
    'label': 'Hadi Law'
  }, {
    'value': 'HPA Solicitors',
    'label': 'HPA Solicitors'
  }, {
    'value': 'Scott Rees',
    'label': 'Scott Rees'
  }, {
    'value': 'Pabla & Pabla',
    'label': 'Pabla & Pabla'
  }, {
    'value': 'AJ Law solicitors',
    'label': 'AJ Law solicitors'
  }, {
    'value': 'Nesbitt Law',
    'label': 'Nesbitt Law'
  }, {
    'value': 'Westgate Law',
    'label': 'Westgate Law'
  }, {
    'value': 'Lance Mason Solicitors',
    'label': 'Lance Mason Solicitors'
  }, {
    'value': 'Simpson Millar Solicitors',
    'label': 'Simpson Millar Solicitors'
  }, {
    'value': 'Lance Mason',
    'label': 'Lance Mason'
  }, {
    'value': 'Jones Gough',
    'label': 'Jones Gough'
  }, {
    'value': 'MTA Solicitors',
    'label': 'MTA Solicitors'
  }, {
    'value': 'Amanda Cunnliffe',
    'label': 'Amanda Cunnliffe'
  }, {
    'value': 'Amanda Cunliffe Solicitors',
    'label': 'Amanda Cunliffe Solicitors'
  }, {
    'value': 'Irwin Mitchell',
    'label': 'Irwin Mitchell'
  }, {
    'value': 'Stephen Laycock & Co',
    'label': 'Stephen Laycock & Co'
  }, {
    'value': 'Nationwide Solicitors',
    'label': 'Nationwide Solicitors'
  }, {
    'value': 'NLG',
    'label': 'NLG'
  }, {
    'value': 'Thorneycroft',
    'label': 'Thorneycroft'
  }, {
    'value': 'Seth Law',
    'label': 'Seth Law'
  }, {
    'value': 'Kipper Beaumont Lewis',
    'label': 'Kipper Beaumont Lewis'
  }, {
    'value': 'Unrepresented',
    'label': 'Unrepresented'
  }, {
    'value': 'Azam Rahman Solicitors',
    'label': 'Azam Rahman Solicitors'
  }, {
    'value': 'Ford Legal',
    'label': 'Ford Legal'
  }, {
    'value': 'Swift Legal',
    'label': 'Swift Legal'
  }, {
    'value': 'Ask Legal',
    'label': 'Ask Legal'
  }, {
    'value': 'Minards Pavlou Solicitors',
    'label': 'Minards Pavlou Solicitors'
  }, {
    'value': 'DLG Legal Services',
    'label': 'DLG Legal Services'
  }, {
    'value': 'Lawtons Lawyers',
    'label': 'Lawtons Lawyers'
  }, {
    'value': 'Nesbit Law Group',
    'label': 'Nesbit Law Group'
  }, {
    'value': 'GSD Law Ltd.',
    'label': 'GSD Law Ltd.'
  }, {
    'value': 'Scott Rees & Co',
    'label': 'Scott Rees & Co'
  }, {
    'value': 'Kinas solicitors',
    'label': 'Kinas solicitors'
  }, {
    'value': 'Kinas Solicitors',
    'label': 'Kinas Solicitors'
  }, {
    'value': 'United Solicitors',
    'label': 'United Solicitors'
  }, {
    'value': 'Hampson Hughes',
    'label': 'Hampson Hughes'
  }, {
    'value': 'Dunne and Gray',
    'label': 'Dunne and Gray'
  }, {
    'value': 'Glaysiers',
    'label': 'Glaysiers'
  }, {
    'value': 'AR Solicitors',
    'label': 'AR Solicitors'
  }, {
    'value': 'Lawton Lawyers',
    'label': 'Lawton Lawyers'
  }, {
    'value': 'Carter Law',
    'label': 'Carter Law'
  }, {
    'value': 'Armstrongs Solicitors',
    'label': 'Armstrongs Solicitors'
  }, {
    'value': 'Ascot Lawyers',
    'label': 'Ascot Lawyers'
  }, {
    'value': 'Acsot Lawyers',
    'label': 'Acsot Lawyers'
  }, {
    'value': 'Rabheru Solicitors',
    'label': 'Rabheru Solicitors'
  }, {
    'value': 'Steve Davies',
    'label': 'Steve Davies'
  }, {
    'value': 'Nesbit Law Group LLP',
    'label': 'Nesbit Law Group LLP'
  }, {
    'value': 'Asons Solicitors',
    'label': 'Asons Solicitors'
  }, {
    'value': 'TPC Solicitors',
    'label': 'TPC Solicitors'
  }, {
    'value': 'Scott Rees Solicitors',
    'label': 'Scott Rees Solicitors'
  }, {
    'value': 'Viceroy Law',
    'label': 'Viceroy Law'
  }, {
    'value': 'Sheirs Solicitors',
    'label': 'Sheirs Solicitors'
  }, {
    'value': 'LPS Solicitors',
    'label': 'LPS Solicitors'
  }, {
    'value': 'Vincents Solicitors',
    'label': 'Vincents Solicitors'
  }, {
    'value': 'Scott Rees & Co.',
    'label': 'Scott Rees & Co.'
  }, {
    'value': '2020 Legal Ltd.',
    'label': '2020 Legal Ltd.'
  }, {
    'value': 'IC Law',
    'label': 'IC Law'
  }, {
    'value': 'Vincents',
    'label': 'Vincents'
  }, {
    'value': 'Abbott Solicitors',
    'label': 'Abbott Solicitors'
  }, {
    'value': 'Lawson Taylor',
    'label': 'Lawson Taylor'
  }, {
    'value': 'Amelius Solicitors',
    'label': 'Amelius Solicitors'
  }, {
    'value': 'Ronald Fletcher & Co',
    'label': 'Ronald Fletcher & Co'
  }, {
    'value': 'AGI Solicitors',
    'label': 'AGI Solicitors'
  }, {
    'value': 'Co-Operative Legal Services',
    'label': 'Co-Operative Legal Services'
  }, {
    'value': 'Bamber & Co',
    'label': 'Bamber & Co'
  }, {
    'value': 'Bott & Co',
    'label': 'Bott & Co'
  }, {
    'value': 'Veja & Co. Solicitors',
    'label': 'Veja & Co. Solicitors'
  }, {
    'value': 'Berwicks Solicitors',
    'label': 'Berwicks Solicitors'
  }, {
    'value': 'Meade Law',
    'label': 'Meade Law'
  }, {
    'value': 'Morgan Has',
    'label': 'Morgan Has'
  }, {
    'value': 'GT Law',
    'label': 'GT Law'
  }, {
    'value': 'MRH Solicitors',
    'label': 'MRH Solicitors'
  }, {
    'value': 'cAMPS',
    'label': 'cAMPS'
  }, {
    'value': 'Harwood Solicitors',
    'label': 'Harwood Solicitors'
  }, {
    'value': 'Brearleys Solicitors',
    'label': 'Brearleys Solicitors'
  }, {
    'value': 'Truman Law / AK Solicitors',
    'label': 'Truman Law / AK Solicitors'
  }, {
    'value': 'Concept Law',
    'label': 'Concept Law'
  }, {
    'value': 'Dunne & Gray',
    'label': 'Dunne & Gray'
  }, {
    'value': 'Dennue & Gray',
    'label': 'Dennue & Gray'
  }, {
    'value': 'Jigsaw Law Ltd',
    'label': 'Jigsaw Law Ltd'
  }, {
    'value': 'Carpenters',
    'label': 'Carpenters'
  }, {
    'value': 'Setfords',
    'label': 'Setfords'
  }, {
    'value': 'Unknown',
    'label': 'Unknown'
  }, {
    'value': 'Aden & Co Solicitors',
    'label': 'Aden & Co Solicitors'
  }, {
    'value': 'Law Room',
    'label': 'Law Room'
  }, {
    'value': 'Nayyars Solicitors',
    'label': 'Nayyars Solicitors'
  }, {
    'value': 'Blakewater Solicitors',
    'label': 'Blakewater Solicitors'
  }, {
    'value': 'AMT Lawyers',
    'label': 'AMT Lawyers'
  }, {
    'value': 'TLW solicitors',
    'label': 'TLW solicitors'
  }, {
    'value': 'Slater Heelis',
    'label': 'Slater Heelis'
  }, {
    'value': 'Resolve Solicitors',
    'label': 'Resolve Solicitors'
  }, {
    'value': 'AMT Lawyers Ltd.',
    'label': 'AMT Lawyers Ltd.'
  }, {
    'value': 'Armstrong Solicitors',
    'label': 'Armstrong Solicitors'
  }, {
    'value': 'Horwich Cohen Coghlan',
    'label': 'Horwich Cohen Coghlan'
  }, {
    'value': 'Accidentally Injured Legal Services',
    'label': 'Accidentally Injured Legal Services'
  }, {
    'value': 'Matrix Solicitors',
    'label': 'Matrix Solicitors'
  }, {
    'value': 'Barings Solicitors',
    'label': 'Barings Solicitors'
  }, {
    'value': 'Gavin Edmondson Solicitors',
    'label': 'Gavin Edmondson Solicitors'
  }, {
    'value': 'Gavin Edmonson Solicitors',
    'label': 'Gavin Edmonson Solicitors'
  }, {
    'value': 'Adnan Hanif',
    'label': 'Adnan Hanif'
  }, {
    'value': 'Walter Barr Ltd',
    'label': 'Walter Barr Ltd'
  }, {
    'value': 'Infinity Law',
    'label': 'Infinity Law'
  }, {
    'value': 'Aspen Solicitors',
    'label': 'Aspen Solicitors'
  }, {
    'value': 'FMG Legal',
    'label': 'FMG Legal'
  }, {
    'value': 'Seth law',
    'label': 'Seth law'
  }, {
    'value': 'Ferns',
    'label': 'Ferns'
  }, {
    'value': 'Ferns Solicitors',
    'label': 'Ferns Solicitors'
  }, {
    'value': 'Adan Hanif',
    'label': 'Adan Hanif'
  }, {
    'value': 'Legis Chambers',
    'label': 'Legis Chambers'
  }, {
    'value': 'Sovereign',
    'label': 'Sovereign'
  }, {
    'value': 'Penn Faraday',
    'label': 'Penn Faraday'
  }, {
    'value': 'Equitas Solicitors',
    'label': 'Equitas Solicitors'
  }, {
    'value': 'Michael Lewin Solicitors',
    'label': 'Michael Lewin Solicitors'
  }, {
    'value': 'Concept Law Solicitors',
    'label': 'Concept Law Solicitors'
  }, {
    'value': 'Carter Law Solicitors Ltd.',
    'label': 'Carter Law Solicitors Ltd.'
  }, {
    'value': 'Ford Galaxy',
    'label': 'Ford Galaxy'
  }, {
    'value': 'Silverdale Solicitors',
    'label': 'Silverdale Solicitors'
  }, {
    'value': 'Rushton Hinchy Solicitors Limited',
    'label': 'Rushton Hinchy Solicitors Limited'
  }, {
    'value': 'Mooney Everett Solicitors',
    'label': 'Mooney Everett Solicitors'
  }, {
    'value': 'Easthams Solicitors Limited',
    'label': 'Easthams Solicitors Limited'
  }, {
    'value': 'Mendell Solicitors Limited',
    'label': 'Mendell Solicitors Limited'
  }, {
    'value': 'Horwich Cohen Coghlan Solicitors',
    'label': 'Horwich Cohen Coghlan Solicitors'
  }, {
    'value': 'Carter Law LLP',
    'label': 'Carter Law LLP'
  }, {
    'value': 'Azmi Rana Solicitors',
    'label': 'Azmi Rana Solicitors'
  }, {
    'value': 'Ashmans Solicitors',
    'label': 'Ashmans Solicitors'
  }, {
    'value': 'Asmans Solicitors',
    'label': 'Asmans Solicitors'
  }, {
    'value': 'Bryne & Co Solicitors',
    'label': 'Bryne & Co Solicitors'
  }, {
    'value': 'Byrne & Co Solicitors',
    'label': 'Byrne & Co Solicitors'
  }, {
    'value': 'Ersan & Co',
    'label': 'Ersan & Co'
  }, {
    'value': 'Minster Law Solicitors',
    'label': 'Minster Law Solicitors'
  }, {
    'value': 'John Cunningham & Associates',
    'label': 'John Cunningham & Associates'
  }, {
    'value': 'Hampson Hughes Solicitors',
    'label': 'Hampson Hughes Solicitors'
  }, {
    'value': 'AA Law Solicitors',
    'label': 'AA Law Solicitors'
  }, {
    'value': 'Adnan Hanif Solicitors',
    'label': 'Adnan Hanif Solicitors'
  }, {
    'value': 'J C & A Solicitors Limited',
    'label': 'J C & A Solicitors Limited'
  }, {
    'value': 'Nesbit Law',
    'label': 'Nesbit Law'
  }, {
    'value': 'PD Law Solicitors',
    'label': 'PD Law Solicitors'
  }, {
    'value': 'Dennison Greer Solicitors',
    'label': 'Dennison Greer Solicitors'
  }, {
    'value': 'Lexadeen Solicitors',
    'label': 'Lexadeen Solicitors'
  }, {
    'value': 'Hewitts Solicitors',
    'label': 'Hewitts Solicitors'
  }, {
    'value': 'Winn Solicitors Ltd',
    'label': 'Winn Solicitors Ltd'
  }, {
    'value': 'Browell Smith & Co',
    'label': 'Browell Smith & Co'
  }, {
    'value': 'Barber & Co Solicitors',
    'label': 'Barber & Co Solicitors'
  }, {
    'value': 'Clinch Solicitors',
    'label': 'Clinch Solicitors'
  }, {
    'value': 'Azmi Rana Solciitors',
    'label': 'Azmi Rana Solciitors'
  }, {
    'value': 'Azmi Rana Soliciitors',
    'label': 'Azmi Rana Soliciitors'
  }, {
    'value': 'Amanda Cunliffe Solicitors Ltd',
    'label': 'Amanda Cunliffe Solicitors Ltd'
  }, {
    'value': 'Joseph Daniel Solicitors Ltd',
    'label': 'Joseph Daniel Solicitors Ltd'
  }, {
    'value': 'Prestige Law',
    'label': 'Prestige Law'
  }, {
    'value': 'Lex Solicitors',
    'label': 'Lex Solicitors'
  }, {
    'value': 'Universa Law',
    'label': 'Universa Law'
  }, {
    'value': 'Just Law Solicitors',
    'label': 'Just Law Solicitors'
  }, {
    'value': 'MTA Solicitors LLP',
    'label': 'MTA Solicitors LLP'
  }, {
    'value': 'Garvins Solicitors',
    'label': 'Garvins Solicitors'
  }, {
    'value': 'Gladstone Solicitors',
    'label': 'Gladstone Solicitors'
  }, {
    'value': 'CTS Claim Today Solicitors',
    'label': 'CTS Claim Today Solicitors'
  }, {
    'value': 'Resolution Law',
    'label': 'Resolution Law'
  }, {
    'value': 'Mendell  Solicitors Limited',
    'label': 'Mendell  Solicitors Limited'
  }, {
    'value': 'Goldsmith Williams',
    'label': 'Goldsmith Williams'
  }, {
    'value': 'Veja & Co Solicitors',
    'label': 'Veja & Co Solicitors'
  }, {
    'value': 'Brooklands Solicitors Ltd',
    'label': 'Brooklands Solicitors Ltd'
  }, {
    'value': 'Waring & Co',
    'label': 'Waring & Co'
  }, {
    'value': 'Quality Solicitors Mirza',
    'label': 'Quality Solicitors Mirza'
  }, {
    'value': 'Freeman Harris Solicitors',
    'label': 'Freeman Harris Solicitors'
  }, {
    'value': 'Themis Lawyers',
    'label': 'Themis Lawyers'
  }, {
    'value': 'Levenes',
    'label': 'Levenes'
  }, {
    'value': 'Ersan & Co Solicitors',
    'label': 'Ersan & Co Solicitors'
  }, {
    'value': 'ersan & Co Solicitors',
    'label': 'ersan & Co Solicitors'
  }, {
    'value': 'Miya Solicitors',
    'label': 'Miya Solicitors'
  }, {
    'value': 'Slater & Gordon',
    'label': 'Slater & Gordon'
  }, {
    'value': 'Reynards Solicitors',
    'label': 'Reynards Solicitors'
  }, {
    'value': 'Partners & Co. Solicitors Ltd.',
    'label': 'Partners & Co. Solicitors Ltd.'
  }, {
    'value': 'Mckays Solicitors',
    'label': 'Mckays Solicitors'
  }, {
    'value': 'Feeman Harris',
    'label': 'Feeman Harris'
  }, {
    'value': 'Virdee Solicitors',
    'label': 'Virdee Solicitors'
  }, {
    'value': 'Brooklands Solicitors',
    'label': 'Brooklands Solicitors'
  }, {
    'value': 'Axiom Solicitors Ltd.',
    'label': 'Axiom Solicitors Ltd.'
  }, {
    'value': 'Goldsmith Williiams',
    'label': 'Goldsmith Williiams'
  }, {
    'value': 'Pabla and Pabla',
    'label': 'Pabla and Pabla'
  }, {
    'value': 'Midas Law',
    'label': 'Midas Law'
  }, {
    'value': 'Cogent Law',
    'label': 'Cogent Law'
  }, {
    'value': 'Applebys Solicitors',
    'label': 'Applebys Solicitors'
  }, {
    'value': 'GSD Law',
    'label': 'GSD Law'
  }, {
    'value': 'Cheadle Solictors',
    'label': 'Cheadle Solictors'
  }, {
    'value': 'Cheadle Solicitors',
    'label': 'Cheadle Solicitors'
  }, {
    'value': 'Jacobs Law',
    'label': 'Jacobs Law'
  }, {
    'value': 'Veja & Co',
    'label': 'Veja & Co'
  }, {
    'value': 'Accident Injury Solicitors',
    'label': 'Accident Injury Solicitors'
  }, {
    'value': 'PMN Law',
    'label': 'PMN Law'
  }, {
    'value': 'Silverbeck Rymer \n',
    'label': 'Silverbeck Rymer  '
  }, {
    'value': 'Carter Law Solicitors',
    'label': 'Carter Law Solicitors'
  }, {
    'value': 'Silverbeck Rymer Solicitors',
    'label': 'Silverbeck Rymer Solicitors'
  }, {
    'value': 'CArter Law Solicitors',
    'label': 'CArter Law Solicitors'
  }, {
    'value': 'Asons Solicitors \n',
    'label': 'Asons Solicitors  '
  }, {
    'value': 'Rushton Hinchy Solicitors Limited \n',
    'label': 'Rushton Hinchy Solicitors Limited  '
  }, {
    'value': 'Aegis Legal',
    'label': 'Aegis Legal'
  }, {
    'value': 'Irwin Mitchell LLP \n',
    'label': 'Irwin Mitchell LLP  '
  }, {
    'value': 'Mirza Solicitors \n',
    'label': 'Mirza Solicitors  '
  }, {
    'value': 'Scott Rees & Co \n',
    'label': 'Scott Rees & Co  '
  }, {
    'value': 'Wosskow Brown Solicitors',
    'label': 'Wosskow Brown Solicitors'
  }, {
    'value': 'LPS Solicitors \n',
    'label': 'LPS Solicitors  '
  }, {
    'value': 'Hannah Gold Solicitors',
    'label': 'Hannah Gold Solicitors'
  }, {
    'value': 'Curtis Law Solicitors',
    'label': 'Curtis Law Solicitors'
  }, {
    'value': 'Emsleys',
    'label': 'Emsleys'
  }, {
    'value': 'High Street Solicitors Ltd',
    'label': 'High Street Solicitors Ltd'
  }, {
    'value': 'Easthams Solicitors',
    'label': 'Easthams Solicitors'
  }, {
    'value': 'Curtis Law Solicitors \n',
    'label': 'Curtis Law Solicitors  '
  }, {
    'value': 'Clear Law LLP',
    'label': 'Clear Law LLP'
  }, {
    'value': 'Austen Jones Solicitors',
    'label': 'Austen Jones Solicitors'
  }, {
    'value': 'Aman Solicitor Advocates',
    'label': 'Aman Solicitor Advocates'
  }, {
    'value': 'Seth Law Solicitors Ltd',
    'label': 'Seth Law Solicitors Ltd'
  }, {
    'value': 'MTG Solicitors',
    'label': 'MTG Solicitors'
  }, {
    'value': 'Birchfields Solicitors',
    'label': 'Birchfields Solicitors'
  }, {
    'value': 'Camps Solicitors',
    'label': 'Camps Solicitors'
  }, {
    'value': 'Intricate Law',
    'label': 'Intricate Law'
  }, {
    'value': 'Law 4 U Solicitors',
    'label': 'Law 4 U Solicitors'
  }, {
    'value': 'RJ Gill LLP',
    'label': 'RJ Gill LLP'
  }, {
    'value': 'AA Solicitors Ltd',
    'label': 'AA Solicitors Ltd'
  }, {
    'value': 'RJ GIll LLP',
    'label': 'RJ GIll LLP'
  }, {
    'value': 'Michael Lewin Solicitors Limited',
    'label': 'Michael Lewin Solicitors Limited'
  }, {
    'value': 'Graham Coffey & Co Solicitors',
    'label': 'Graham Coffey & Co Solicitors'
  }, {
    'value': 'TJL Solicitors',
    'label': 'TJL Solicitors'
  }, {
    'value': 'True Solicitors LLP',
    'label': 'True Solicitors LLP'
  }, {
    'value': 'Advantage Solicitors',
    'label': 'Advantage Solicitors'
  }, {
    'value': 'TLW Solicitors',
    'label': 'TLW Solicitors'
  }, {
    'value': 'BLC Solicitors',
    'label': 'BLC Solicitors'
  }, {
    'value': 'Neil Hudgell',
    'label': 'Neil Hudgell'
  }, {
    'value': 'Savas & Savage Solicitors Limited',
    'label': 'Savas & Savage Solicitors Limited'
  }, {
    'value': '1st Central Law',
    'label': '1st Central Law'
  }, {
    'value': 'Claim Time Solicitors',
    'label': 'Claim Time Solicitors'
  }, {
    'value': 'Versus Law Solicitors',
    'label': 'Versus Law Solicitors'
  }, {
    'value': 'Nayyars Solicitors Limited',
    'label': 'Nayyars Solicitors Limited'
  }, {
    'value': 'Slater Heelis LLP',
    'label': 'Slater Heelis LLP'
  }, {
    'value': 'ACSL Ltd',
    'label': 'ACSL Ltd'
  }, {
    'value': 'Naughtons Solicitors',
    'label': 'Naughtons Solicitors'
  }, {
    'value': 'SGI Legal LLP',
    'label': 'SGI Legal LLP'
  }, {
    'value': 'Alliance Law',
    'label': 'Alliance Law'
  }, {
    'value': 'Thorneycroft Solicitors',
    'label': 'Thorneycroft Solicitors'
  }, {
    'value': 'Allweis & Co',
    'label': 'Allweis & Co'
  }, {
    'value': 'McAlinneys Solicitors',
    'label': 'McAlinneys Solicitors'
  }, {
    'value': 'Irwin Mitchell LLP',
    'label': 'Irwin Mitchell LLP'
  }, {
    'value': 'Sheldon Davidson Solicitors',
    'label': 'Sheldon Davidson Solicitors'
  }, {
    'value': 'Alliance Law Solicitors',
    'label': 'Alliance Law Solicitors'
  }, {
    'value': 'Allied Law Chambers Solicitors',
    'label': 'Allied Law Chambers Solicitors'
  }, {
    'value': 'Zen Law Solicitors',
    'label': 'Zen Law Solicitors'
  }, {
    'value': 'RKS Solicitors',
    'label': 'RKS Solicitors'
  }, {
    'value': 'Rabheru Solicitor',
    'label': 'Rabheru Solicitor'
  }, {
    'value': 'Middletons Solicitors',
    'label': 'Middletons Solicitors'
  }, {
    'value': 'Turner & White Solicitors',
    'label': 'Turner & White Solicitors'
  }, {
    'value': 'Good & Co Solicitors LLP',
    'label': 'Good & Co Solicitors LLP'
  }, {
    'value': 'Isaac Abraham Solicitors',
    'label': 'Isaac Abraham Solicitors'
  }, {
    'value': 'Addison & Khan Solicitors',
    'label': 'Addison & Khan Solicitors'
  }, {
    'value': 'Slater & Gordon Lawyers',
    'label': 'Slater & Gordon Lawyers'
  }, {
    'value': 'BMC Law',
    'label': 'BMC Law'
  }, {
    'value': 'Bromiley Holcroft Solicitors Ltd',
    'label': 'Bromiley Holcroft Solicitors Ltd'
  }, {
    'value': 'Alison Law Solicitors LLP',
    'label': 'Alison Law Solicitors LLP'
  }, {
    'value': 'Hadi Law Solicitors',
    'label': 'Hadi Law Solicitors'
  }, {
    'value': 'Gull Law Chambers Ltd',
    'label': 'Gull Law Chambers Ltd'
  }, {
    'value': 'Smith Jones Solicitors',
    'label': 'Smith Jones Solicitors'
  }, {
    'value': 'Harrison Bryce Solicitors Ltd',
    'label': 'Harrison Bryce Solicitors Ltd'
  }, {
    'value': 'NGA Solicitors',
    'label': 'NGA Solicitors'
  }, {
    'value': 'DFR Solicitors',
    'label': 'DFR Solicitors'
  }, {
    'value': 'Stocks Legal',
    'label': 'Stocks Legal'
  }, {
    'value': 'Slater Gordon Solutions Legal',
    'label': 'Slater Gordon Solutions Legal'
  }, {
    'value': 'YMH Solicitors',
    'label': 'YMH Solicitors'
  }, {
    'value': 'YHM Solicitors',
    'label': 'YHM Solicitors'
  }, {
    'value': 'Graham Coffey & Co Solicitors  \n',
    'label': 'Graham Coffey & Co Solicitors   '
  }, {
    'value': 'Ask Legal Solicitors',
    'label': 'Ask Legal Solicitors'
  }, {
    'value': 'Zest Legal',
    'label': 'Zest Legal'
  }, {
    'value': 'Affinity Seven Law Solicitors',
    'label': 'Affinity Seven Law Solicitors'
  }, {
    'value': 'NA.AKB.12355.PI-A',
    'label': 'NA.AKB.12355.PI-A'
  }, {
    'value': 'Minards Pavlou Solicitors \n',
    'label': 'Minards Pavlou Solicitors  '
  }, {
    'value': 'Concept Law Solicitors \n',
    'label': 'Concept Law Solicitors  '
  }, {
    'value': 'Knightsbridge Solicitors',
    'label': 'Knightsbridge Solicitors'
  }, {
    'value': 'LEX Solicitors',
    'label': 'LEX Solicitors'
  }, {
    'value': 'Armstrong Solicitors \n',
    'label': 'Armstrong Solicitors  '
  }, {
    'value': 'Ashwood Solicitors',
    'label': 'Ashwood Solicitors'
  }, {
    'value': 'Silverman Peake Solicitors',
    'label': 'Silverman Peake Solicitors'
  }, {
    'value': 'IQ Law',
    'label': 'IQ Law'
  }, {
    'value': 'Ford Legal Solicitors',
    'label': 'Ford Legal Solicitors'
  }, {
    'value': 'AWH Legal Limited',
    'label': 'AWH Legal Limited'
  }, {
    'value': 'Infinity Law Ltd T/A DGM Solicitors',
    'label': 'Infinity Law Ltd T/A DGM Solicitors'
  }, {
    'value': 'Pabla & Pabla Solicitors',
    'label': 'Pabla & Pabla Solicitors'
  }, {
    'value': 'Dylan Nair Solicitors',
    'label': 'Dylan Nair Solicitors'
  }, {
    'value': 'Amanda Cunliffe Solicitors Ltd \n',
    'label': 'Amanda Cunliffe Solicitors Ltd  '
  }, {
    'value': 'Ronald Fletcher & Co \n',
    'label': 'Ronald Fletcher & Co  '
  }, {
    'value': 'Kaizen Law Solicitors',
    'label': 'Kaizen Law Solicitors'
  }, {
    'value': 'Satchell Moran Solicitors Limited',
    'label': 'Satchell Moran Solicitors Limited'
  }, {
    'value': 'Lawson Taylor LLP',
    'label': 'Lawson Taylor LLP'
  }, {
    'value': 'MRH Solicitors \n',
    'label': 'MRH Solicitors  '
  }, {
    'value': 'Samuels Law Solicitors \n',
    'label': 'Samuels Law Solicitors  '
  }, {
    'value': 'Samuels Law Solicitors',
    'label': 'Samuels Law Solicitors'
  }, {
    'value': 'Petherbridge Bassra Solicitors \n',
    'label': 'Petherbridge Bassra Solicitors  '
  }, {
    'value': 'IC Law Solicitors',
    'label': 'IC Law Solicitors'
  }, {
    'value': 'Scott Rees and Co',
    'label': 'Scott Rees and Co'
  }, {
    'value': 'Broadway Solicitors \n',
    'label': 'Broadway Solicitors  '
  }, {
    'value': 'Graham Coffey & Co',
    'label': 'Graham Coffey & Co'
  }, {
    'value': 'PCJ Solicitors',
    'label': 'PCJ Solicitors'
  }, {
    'value': 'Pabla + Pabla Solicitors',
    'label': 'Pabla + Pabla Solicitors'
  }, {
    'value': 'Allison Law',
    'label': 'Allison Law'
  }, {
    'value': 'Deen Wahid Solicitors',
    'label': 'Deen Wahid Solicitors'
  }, {
    'value': 'Jiva Solicitor Ltd',
    'label': 'Jiva Solicitor Ltd'
  }, {
    'value': 'McHale & Co Solicitors',
    'label': 'McHale & Co Solicitors'
  }, {
    'value': 'Coupland Cavendish Ltd',
    'label': 'Coupland Cavendish Ltd'
  }, {
    'value': 'Freeman Harris',
    'label': 'Freeman Harris'
  }, {
    'value': 'Joseph Daniel Solicitors',
    'label': 'Joseph Daniel Solicitors'
  }, {
    'value': 'Reiss Solicitors',
    'label': 'Reiss Solicitors'
  }, {
    'value': 'Diamond Law',
    'label': 'Diamond Law'
  }, {
    'value': 'The Co-Operative Legal Services \n',
    'label': 'The Co-Operative Legal Services  '
  }, {
    'value': '2020 Legal Limited \n',
    'label': '2020 Legal Limited  '
  }, {
    'value': 'Gowing Law Solicitors',
    'label': 'Gowing Law Solicitors'
  }, {
    'value': 'Barber & Co Solicitors  \n',
    'label': 'Barber & Co Solicitors   '
  }, {
    'value': 'G & K Law',
    'label': 'G & K Law'
  }, {
    'value': 'Intricate Law \n',
    'label': 'Intricate Law  '
  }, {
    'value': 'Nesbit Law Group LLP \n',
    'label': 'Nesbit Law Group LLP  '
  }, {
    'value': 'Whitestone Solicitors',
    'label': 'Whitestone Solicitors'
  }, {
    'value': 'Carpenters Limited \n',
    'label': 'Carpenters Limited  '
  }, {
    'value': 'Slater Gordon Solutions Legal \n',
    'label': 'Slater Gordon Solutions Legal  '
  }
]


const claimantSolicitorsReduced = [
  {
    'value': 'Armstrongs Solicitors',
    'label': 'Armstrongs Solicitors'
  }, {
    'value': 'Asons Solicitors',
    'label': 'Asons Solicitors'
  }, {
    'value': 'BLC Solicitors',
    'label': 'BLC Solicitors'
  }, {
    'value': 'Bott & Co',
    'label': 'Bott & Co'
  }, {
    'value': 'Cartwright Solicitors',
    'label': 'Cartwright Solicitors'
  }, {
    'value': 'Dunne & Gray',
    'label': 'Dunne & Gray'
  }, {
    'value': 'Graham Coffey & Co Solicitors',
    'label': 'Graham Coffey & Co Solicitors'
  }, {
    'value': 'Harwood Solicitors',
    'label': 'Harwood Solicitors'
  }, {
    'value': 'HPA Solicitors',
    'label': 'HPA Solicitors'
  }, {
    'value': 'Lance Mason Solicitors',
    'label': 'Lance Mason Solicitors'
  }, {
    'value': 'Lawson Taylor',
    'label': 'Lawson Taylor'
  }, {
    'value': 'MRH Solicitors',
    'label': 'MRH Solicitors'
  }, {
    'value': 'Nesbitt Law Group',
    'label': 'Nesbitt Law Group'
  }, {
    'value': 'Scott Rees & Co',
    'label': 'Scott Rees & Co'
  }, {
    'value': 'Silverbeck Rymer',
    'label': 'Silverbeck Rymer'
  }, {
    'value': 'TJL Solicitors',
    'label': 'TJL Solicitors'
  }, {
    'value': 'TLW solicitors',
    'label': 'TLW solicitors'
  }, {
    'value': 'True Solicitors LLP',
    'label': 'True Solicitors LLP'
  }, {
    'value': 'Turner & White Solicitors',
    'label': 'Turner & White Solicitors'
  }, {
    'value': 'Vincents Solicitors',
    'label': 'Vincents Solicitors'
  }
]

export {
  claimantSolicitors,
  claimantSolicitorsReduced
};
