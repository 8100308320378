const departments = [
  {
    value: 'A&E',
    label: 'A&E'
  }, {
    value: 'Child Protection',
    label: 'Child Protection'
  }, {
    value: 'Dermatology',
    label: 'Dermatology'
  }, {
    value: 'ENT',
    label: 'ENT'
  }, {
    value: 'Gastroenterology',
    label: 'Gastroenterology'
  }, {
    value: 'General Surgery',
    label: 'General Surgery'
  }, {
    value: 'GP',
    label: 'GP'
  }, {
    value: 'Infectious Disease',
    label: 'Infectious Disease'
  }, {
    value: 'Maxillofacial surgery',
    label: 'Maxillofacial surgery'
  }, {
    value: 'Neurology',
    label: 'Neurology'
  }, {
    value: 'No Injuries',
    label: 'No Injuries'
  }, {
    value: 'Ophthalmology',
    label: 'Ophthalmology'
  }, {
    value: 'Orthopaedic Surgery',
    label: 'Orthopaedic Surgery'
  }, {
    value: 'Other',
    label: 'Other'
  }, {
    value: 'Plastic Surgery',
    label: 'Plastic Surgery'
  }, {
    value: 'Psychiatry',
    label: 'Psychiatry'
  }, {
    value: 'Rheumatology',
    label: 'Rheumatology'
  }, {
    value: 'Thoracic Surgery',
    label: 'Thoracic Surgery'
  }, {
    value: 'Urology',
    label: 'Urology'
  }
]

const injuryParts = [
  {
    value: 'Ankle',
    label: 'Ankle'
  }, {
    value: 'Back',
    label: 'Back'
  }, {
    value: 'Bladder',
    label: 'Bladder'
  }, {
    value: 'Brain',
    label: 'Brain'
  }, {
    value: 'Chest',
    label: 'Chest'
  }, {
    value: 'Digestive Systems',
    label: 'Digestive Systems'
  }, {
    value: 'Disease',
    label: 'Disease'
  }, {
    value: 'Ear',
    label: 'Ear'
  }, {
    value: 'Eye',
    label: 'Eye'
  }, {
    value: 'Facial',
    label: 'Facial'
  }, {
    value: 'Foot',
    label: 'Foot'
  }, {
    value: 'Hand/Finger',
    label: 'Hand/Finger'
  }, {
    value: 'Head',
    label: 'Head'
  }, {
    value: 'Kidney',
    label: 'Kidney'
  }, {
    value: 'Knee',
    label: 'Knee'
  }, {
    value: 'Leg',
    label: 'Leg'
  }, {
    value: 'Male',
    label: 'Male'
  }, {
    value: 'Mental',
    label: 'Mental'
  }, {
    value: 'Multiple',
    label: 'Multiple'
  }, {
    value: 'Neck',
    label: 'Neck'
  }, {
    value: 'No Injuries',
    label: 'No Injuries'
  }, {
    value: 'Other',
    label: 'Other'
  }, {
    value: 'Pelvis / Hips',
    label: 'Pelvis / Hips'
  }, {
    value: 'Shoulder',
    label: 'Shoulder'
  }, {
    value: 'Spinal',
    label: 'Spinal'
  }, {
    value: 'Toe',
    label: 'Toe'
  }, {
    value: 'Whole',
    label: 'Whole'
  }, {
    value: 'Wrist',
    label: 'Wrist'
  }
]

const injuryNature = [
  {
    value: 'Abuse',
    label: 'Abuse'
  }, {
    value: 'Achilles Tendon',
    label: 'Achilles Tendon'
  }, {
    value: 'Acoustic Shock',
    label: 'Acoustic Shock'
  }, {
    value: 'Amputation',
    label: 'Amputation'
  }, {
    value: 'Anthrax',
    label: 'Anthrax'
  }, {
    value: 'Asbestos/Mesothelioma',
    label: 'Asbestos/Mesothelioma'
  }, {
    value: 'Asthma/Bronchitis/Emphysema',
    label: 'Asthma/Bronchitis/Emphysema'
  }, {
    value: 'Burn',
    label: 'Burn'
  }, {
    value: 'Chronic Regional Pain Syndrome',
    label: 'Chronic Regional Pain Syndrome'
  }, {
    value: 'Cut/Bruise',
    label: 'Cut/Bruise'
  }, {
    value: 'Damage',
    label: 'Damage'
  }, {
    value: 'Degloving Injury',
    label: 'Degloving Injury'
  }, {
    value: 'Demititis/Eczema',
    label: 'Demititis/Eczema'
  }, {
    value: 'Digestive Systems',
    label: 'Digestive Systems'
  }, {
    value: 'Disfigurement',
    label: 'Disfigurement'
  }, {
    value: 'Epilepsy',
    label: 'Epilepsy'
  }, {
    value: 'Fracture',
    label: 'Fracture'
  }, {
    value: 'Fractured Skull',
    label: 'Fractured Skull'
  }, {
    value: 'Hernia',
    label: 'Hernia'
  }, {
    value: 'Industrial Deafness',
    label: 'Industrial Deafness'
  }, {
    value: 'Injury',
    label: 'Injury'
  }, {
    value: 'Loss of Sight',
    label: 'Loss of Sight'
  }, {
    value: 'Multiple Chemical Exposure',
    label: 'Multiple Chemical Exposure'
  }, {
    value: 'No Injuries',
    label: 'No Injuries'
  }, {
    value: 'Other',
    label: 'Other'
  }, {
    value: 'Post-traumatic Stress Disorder',
    label: 'Post-traumatic Stress Disorder'
  }, {
    value: 'Psychiatric Illness',
    label: 'Psychiatric Illness'
  }, {
    value: 'Reproductive System',
    label: 'Reproductive System'
  }, {
    value: 'Sprain',
    label: 'Sprain'
  }, {
    value: 'Stress',
    label: 'Stress'
  }, {
    value: 'Tenosynovitis/and or WRULD',
    label: 'Tenosynovitis/and or WRULD'
  }, {
    value: 'WF/Reynauds phenomenon',
    label: 'WF/Reynauds phenomenon'
  }, {
    value: 'Whiplash',
    label: 'Whiplash'
  }
]

const businessLine = [
  {
    value: 'EMP',
    label: 'Employers Liability'
  }, {
    value: 'MOT',
    label: 'Motor'
  }, {
    value: 'PUB',
    label: 'Public Liability'
  }
]

const instructionType = [
  {
    value: 'LIA',
    label: 'Liability Dispute'
  }, {
    value: 'LIQ',
    label: 'Quantum & Liability Dispute'
  }, {
    value: 'QUA',
    label: 'Quantum Dispute'
  }
]

const track = [
  {
    value: 'FAS',
    label: 'Fast'
  }, {
    value: 'MUL',
    label: 'Multi'
  }
]

const matterRate = [
  {
    value: 'Zurich - Scheme Delegated Authority - Fixed Fee',
    label: 'Fixed Fee (Delegated Authority)'
  }, {
    value: 'Zurich - Scheme Standard - Fixed Fee',
    label: 'Fixed Fee (Non Delegated Authority)'
  }, {
    value: 'Zurich - £0 to £50k Non Delegated Authority',
    label: 'Hourly Rate (Minor Injury)'
  },
  //{
  //    value: 'No Rate Model Applicable',
  //    label: 'No Rate Model Applicable'
  //},
  {
    value: 'Zurich - £50k to £100k Non Delegated Authority',
    label: 'Hourly Rate (Mid Injury)'
  },
  //{
  //    value: 'Zurich - £100k to £250k Non Delegated Authority',
  //    label: 'Zurich - £100k to £250k Non Delegated Authority'
  //},
  //{
  //    value: 'Zurich Casualty & Disease - £0-£50k - FF - E&W',
  //    label: 'Zurich Casualty & Disease - £0-£50k - FF - E&W'
  //},
  //{
  //    value: 'Z 0k to 50k Non DA',
  //    label: 'Z 0k to 50k Non DA'
  //},
  //{
  //    value: 'Z 0k to 50k DA',
  //    label: 'Z 0k to 50k DA'
  //},
  //{
  //    value: 'Zurich - £0 to £50k Delegated Authority',
  //    label: 'Zurich - £0 to £50k Delegated Authority'
  //},
  {
    value: 'Zurich - £250k to £500k',
    label: 'Hourly Rate (Complex Injury)'
  },
  //{
  //    value: 'Zurich - Scheme Motor Fast Track Non Delegated Au',
  //    label: 'Zurich - Scheme Motor Fast Track Non Delegated Au'
  //},
  //{
  //    value: 'Zurich - Scheme Motor Fast Track Delegated Auth',
  //    label: 'Zurich - Scheme Motor Fast Track Delegated Auth'
  //},
  //{
  //    value: 'Zurich Casualty & Disease - Non DA - £0-£50,000 -',
  //    label: 'Zurich Casualty & Disease - Non DA - £0-£50,000 -'
  //},
  //{
  //    value: 'Zurich - £50k to £100k Delegated Authority',
  //    label: 'Zurich - £50k to £100k Delegated Authority'
  //},
  //{
  //    value: 'Z 50k to 100k Non DA',
  //    label: 'Z 50k to 100k Non DA'
  //},
  //{
  //    value: 'Zurich - Motor £0 to £50k Non Delegated Authority',
  //    label: 'Zurich - Motor £0 to £50k Non Delegated Authority'
  //},
  //{
  //    value: 'Zurich - Motor £0-£50k - Fixed Fee - E&W',
  //    label: 'Zurich - Motor £0-£50k - Fixed Fee - E&W'
  //},
  {
    value: 'Zurich - £500k +',
    label: 'Hourly Rate (Catastrophic Injury)'
  }
  //{
  //    value: 'Zurich Casualty& Disease - NonDA -£100k -£249,999',
  //    label: 'Zurich Casualty& Disease - NonDA -£100k -£249,999'
  //}
]

const clMfu = [
  //{
  //    value: '~Z',
  //    label: '~Z'
  //},
  {
    value: 'GC',
    label: 'Large Corporate'
  },
  //{
  //    value: 'Gr',
  //    label: 'Gr'
  //},
  //{
  //    value: 'Mi',
  //    label: 'Mi'
  //},
  //{
  //    value: 'PC',
  //    label: 'PC'
  //},
  {
    value: 'PL',
    label: 'Individual'
  },
  //{
  //    value: 'WT',
  //    label: 'WT'
  //},
  {
    value: 'ZC',
    label: 'Commercial Business'
  }, {
    value: 'ZM',
    label: 'Government / Not for Profit'
  }
  //{
  //    value: 'ZZ',
  //    label: 'ZZ'
  //}
]

const matterLocation = [
  // {
  //   value: 'GB01  London  C&Co LLP',
  //   label: 'GB01  London  C&Co LLP'
  // },
  //{
  //    value: 'GB02  Guildford  C&Co LLP',
  //    label: 'GB02  Guildford  C&Co LLP'
  //},
  //{
  //    value: 'GB03  Manchester  C&Co LLP',
  //    label: 'GB03  Manchester  C&Co LLP'
  //},
  {
    value: 'GB21  Manchester  C&Co Claims LLP',
    label: 'Clyde & Co Manchester'
  }
  //{
  //    value: 'London',
  //    label: 'London'
  //},
  //{
  //    'value': 'Manchester',
  //    'label': 'Manchester'
  //}
]

const injury = [
  {
    'value': 'Abuse',
    'label': 'Abuse'
  }, {
    'value': 'Amputation',
    'label': 'Amputation'
  }, {
    'value': 'Ankle',
    'label': 'Ankle'
  }, {
    'value': 'Anthrax',
    'label': 'Anthrax'
  }, {
    'value': 'Arm/Elbow/Shoulder',
    'label': 'Arm/Elbow/Shoulder'
  }, {
    'value': 'Asbestos Related',
    'label': 'Asbestos Related'
  }, {
    'value': 'Back',
    'label': 'Back'
  }, {
    'value': 'Brain Damage',
    'label': 'Brain Damage'
  }, {
    'value': 'Burns',
    'label': 'Burns'
  }, {
    'value': 'Cardiology/Vascular',
    'label': 'Cardiology/Vascular'
  }, {
    'value': 'Chest',
    'label': 'Chest'
  }, {
    'value': 'Dermatology',
    'label': 'Dermatology'
  }, {
    'value': 'Digestive',
    'label': 'Digestive'
  }, {
    'value': 'Ear',
    'label': 'Ear'
  }, {
    'value': 'Eye',
    'label': 'Eye'
  }, {
    'value': 'Facial',
    'label': 'Facial'
  }, {
    'value': 'Fatal Injury',
    'label': 'Fatal Injury'
  }, {
    'value': 'Hand/Finger',
    'label': 'Hand/Finger'
  }, {
    'value': 'Head',
    'label': 'Head'
  }, {
    'value': 'Hernia /Strain',
    'label': 'Hernia /Strain'
  }, {
    'value': 'Knee',
    'label': 'Knee'
  }, {
    'value': 'Leg',
    'label': 'Leg'
  }, {
    'value': 'Minor Head Injury',
    'label': 'Minor Head Injury'
  }, {
    'value': 'Multiple',
    'label': 'Multiple'
  }, {
    'value': 'Multiple Injuries (Including Orthopaedic)',
    'label': 'Multiple Injuries (Including Orthopaedic)'
  }, {
    'value': 'Neck',
    'label': 'Neck'
  }, {
    'value': 'No Injury',
    'label': 'No Injury'
  }, {
    'value': 'Pain',
    'label': 'Pain'
  }, {
    'value': 'Psychological',
    'label': 'Psychological'
  }, {
    'value': 'Respiratory',
    'label': 'Respiratory'
  }, {
    'value': 'Spine/Spinal Cord',
    'label': 'Spine/Spinal Cord'
  }, {
    'value': 'Toe/Foot',
    'label': 'Toe/Foot'
  }, {
    'value': 'Urology',
    'label': 'Urology'
  }, {
    'value': 'Wrist',
    'label': 'Wrist'
  }
]

const severity = [
  {
    'value': 'Mild',
    'label': 'Mild'
  }, {
    'value': 'Moderate',
    'label': 'Moderate'
  }, {
    'value': 'None',
    'label': 'None'
  }, {
    'value': 'Severe',
    'label': 'Severe'
  }, {
    'value': 'Unknown',
    'label': 'Unknown'
  }
]

export {
  departments,
  injuryParts,
  injuryNature,
  businessLine,
  instructionType,
  track,
  matterRate,
  clMfu,
  matterLocation,
  injury,
  severity
};
