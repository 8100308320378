import React from 'react';
import {
	Link,
} from "react-router-dom";

import '../App.css'


function ButtonTool({label, route, urlImage}){
	return(
		<div style={{marginBottom: "2%"}}>
			<Link to={route} style={{textDecoration: 'none'}}>
				<div className="button-tool">
					<div style={{textAlign: 'center', paddingTop: '10%'}}>
						<img src={urlImage} alt={label}/>
					</div>
					<h4>{label}</h4>
				</div>
			</Link>
		</div>
	)
}

function ButtonToolDisabled({label, route, urlImage}){
	return(
		<div style={{marginBottom: "2%"}}>
				<div className="hidden-product">
					<div style={{textAlign: 'center', paddingTop: '10%'}}>
						<img src={urlImage} alt={label}/>
					</div>
					<h4>{label}</h4>
					<p>COMING SOON</p>
				</div>
		</div>
	)
}

function ButtonToolProduct({label, route, urlImage}){
	return(
		<div style={{marginBottom: "2%"}}>
			<div className="button-tool" >
				<a href={route} style={{textDecoration: 'none'}}>
					<div style={{textAlign: 'center', cursor: 'pointer', paddingTop: '10%'}}>
						<img src={urlImage} alt={label}/>
					</div>
					<h4>{label}</h4>
				</a>
			</div>
		</div>
	)
}


function Projects(){
	return(
		<div style={{marginLeft: 'auto', marginRight: 'auto', width:'80%', display:'block', paddingTop: '5%'}}>
			<div className='projects-overview'>
				<h1>Demonstrations</h1>
				<h3>Proofs of concept which illustrate a key facet of machine learning</h3>
				<br/>
				<div className='projects-elements'>
					<ButtonTool
						label={'Directions Reader'}
						route={'/directions'}
						urlImage={'./directions_icon_1.png'}
					/>
					<ButtonTool
						label={'Reserving Estimator'}
						route={'/reserving'}
						urlImage={'./reserving_icon_1.png'}
					/>
					<ButtonTool
						label={'Find a Friend'}
						route={'/find'}
						urlImage={'./find_icon_1.png'}
					/>
				</div>
			</div>
			<div className='projects-overview'>
				<h1>Prototypes</h1>
				<h3>Proofs of concept which we have developed into prototypes</h3>
				<br/>
				<div className='projects-elements'>
					<ButtonTool
						label={'Data Breach PII Identifier'}
						route={'/databreach'}
						urlImage={'./data_breach_icon_1.png'}
					/>
					<ButtonTool
						label={'Fraud Validation'}
						route={'/fraud'}
						urlImage={'./fraud_icon_1.png'}
					/>
					<ButtonTool
						label={'Coverage Advisor'}
						route={'/coverage'}
						urlImage={'./coverage_icon_1.png'}
					/>
				</div>
			</div>
			<div className='projects-overview'>
				<h1>Products</h1>
				<h3>Prototypes which we have developed into products</h3>
				<br/>
				<div className="project-last-element">
					<ButtonToolProduct
						label={'Employment Tribunal'}
						urlImage={'./employment_icon_1.png'}
						route={"http://employment.clydeco.ai/"}
					/>
					<ButtonToolDisabled
						label={'Data Breach'}
						urlImage={'./data_breach_icon_1.png'}
						route={""}
						style={{cursor: "default", backgroundColor: "red"}}
					/>
				</div>
			</div>
		</div>
	)
}

export default Projects;
