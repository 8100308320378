import React from "react"
import FormComponentFirstDirections from "./FormComponentFirstDirections"
import FormComponentSecondDirections from "./FormComponentSecondDirections"
import FormComponentThirdDirections from "./FormComponentThirdDirections"

class GetStepContentComponentDirections extends React.Component{

  constructor(props){
    super(props)
  }

  render(){
    const stepIndex = this.props.step
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <FormComponentFirstDirections />
          </div>
        );
      case 1:
        return (
          <div>
            <FormComponentSecondDirections
              handleChange={this.props.handleChange}
              handleChangeText={this.props.handleChangeText}
              handleSubmit={this.props.handleSubmit}
              data={this.props.data}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FormComponentThirdDirections
              data={this.props.data}
            />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  }
}

export default GetStepContentComponentDirections
