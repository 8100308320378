import React from "react"
import {
	reserving1,
	reserving2,
	reserving2a,
	reserving2b,
	reserving2c,
	reserving2d,
	reserving3,
	reserving4,
	reserving5,
	reserving6,
	reserving7,
	reserving8
} from '../descriptions'

import HoverText from '../HoverText'
import {
	PredictiveKeyword
} from '../glossary'

function FormComponentFirstReserving() {

	const titleReserving = "Reserving Estimator";

	return (
		<div>
			<div className='projects-container-text'>
				<br/>
				<h2 className="projects-container-introduction-subtitle">{titleReserving}</h2>
				<div style={{textAlign:'justify'}}>
					<p className='projects-container-introduction-text'>{reserving1}</p>
					<p className='projects-container-introduction-text'>{reserving2}</p>
					<ul className='projects-container-introduction-text'>
						<li>{reserving2a}</li>
						<li>{reserving2b}</li>
						<li>{reserving2c}</li>
						<li>{reserving2d}</li>
					</ul>
					<p className='projects-container-introduction-text'>{reserving3}</p>
					<p className='projects-container-introduction-text'>{reserving4}</p>
					<p className='projects-container-introduction-text'>{reserving5}</p>
					<p className='projects-container-introduction-text'>{reserving6}</p>
					<p className='projects-container-introduction-text'>{reserving7}</p>
					<p className='projects-container-introduction-text'>{reserving8}</p>
				</div>
			</div>
			<br/>
			<br/>
		</div>
	)
}

export default FormComponentFirstReserving
