import React from 'react'
import HorizontalLabelPositionBelowStepperDirections from './HorizontalStepperDirections'

const StepperCustom = {
  textAlign: 'center',
  marginTop: "4%",
}

const Directions = () => (
  <div>
    <br />
    <div style = {StepperCustom}>
      <HorizontalLabelPositionBelowStepperDirections />
    </div>
  </div>
)

export default Directions
