import React from "react"
import FormComponentLiability from './FormComponentLiability'
import FormComponentAware from './FormComponentAware'
import FormComponentNotification from './FormComponentNotification'
import {coverage_not_ready} from '../descriptions'

function FormComponentThird(props) {

	const noText =
		"Please input the type of policy."

	return (
		<div>
			<div className="projects-container-text">
				<br/>
				{props.data.clause.clause_predict === 'Representation' ?
					<h3 className='projects-question'>You have submitted a: Fair representation clause</h3> :
					<h3 className='projects-question'>You have submitted a: {props.data.clause.clause_predict} clause</h3>
				}
				{props.data.clause.clause_predict === 'Claim notification' ?
					<div>
						{props.data.policyType === 'PI' ?
							<div>
								{/*<p className='projects-question'>{continueText}</p>*/}
								{/*<br/>*/}
								{/*<br/>*/}
								{/*<FormComponentProperty*/}
								{/*	handleChange={props.handleChange}*/}
								{/*	data={props.data}*/}
								{/*/>*/}
							</div>
							: props.data.policyType === 'GL' ?
								<div>
									{/*<p className='projects-question'>{continueText}</p>*/}
									<FormComponentLiability
										handleChange={props.handleChange}
										data={props.data}
									/>

								</div>
								: <p className='projects-question'>{noText}</p>}
						<br/>
						{props.data.policyType === 'PI' ?
								<FormComponentLiability
									handleChange={props.handleChange}
									data={props.data}
								/>
							: null}
						<br/>
						{props.data.isAware === 'no' ?
							<FormComponentAware
								handleChange={props.handleChange}
								data={props.data}
							/> : null}
						<br/>
						{props.data.policyType === 'PI' ?
							<FormComponentNotification
								handleChange={props.handleChange}
								data={props.data}
							/>
							: props.data.policyType === 'GL' ?
							<FormComponentNotification
								handleChange={props.handleChange}
								data={props.data}
							/>
							: null}
						<br/>
					</div> :
					<h3 className='projects-question'>{coverage_not_ready}</h3>
				}
			</div>
			<br/>
		</div>
	)
}

export default FormComponentThird
