const location = [
	{
		'value': 'Jenkins Street, Birmingham',
		'label': 'Jenkins Street, Birmingham'
	}, {
		'value': 'Higher Road / New Hutte Lane, Halewood, Knowsley',
		'label': 'Higher Road / New Hutte Lane, Halewood, Knowsley'
	}, {
		'value': 'Southfield Lane, Bradford',
		'label': 'Southfield Lane, Bradford'
	}, {
		'value': 'Somerset Way, Warrington',
		'label': 'Somerset Way, Warrington'
	}, {
		'value': 'Broadway/Moston Lane, Manchester Moston Lane East, Mancehster',
		'label': 'Broadway/Moston Lane, Manchester Moston Lane East, Mancehster'
	}, {
		'value': 'Reading Road,  Berkshire',
		'label': 'Reading Road,  Berkshire'
	}, {
		'value': 'Shepherd Shepherds Lane, leeds',
		'label': 'Shepherd Shepherds Lane, leeds'
	}, {
		'value': 'Gledhows/Bayswater Place, Leeds',
		'label': 'Gledhows/Bayswater Place, Leeds'
	}, {
		'value': 'Tiltshills Lane, Doncaster',
		'label': 'Tiltshills Lane, Doncaster'
	}, {
		'value': 'Turners Hill Road, Crawley',
		'label': 'Turners Hill Road, Crawley'
	}, {
		'value': 'A12 Southbound',
		'label': 'A12 Southbound'
	}, {
		'value': 'Pallion Road',
		'label': 'Pallion Road'
	}, {
		'value': 'Brandon Way Roundabout, West Midlands',
		'label': 'Brandon Way Roundabout, West Midlands'
	}, {
		'value': 'Newark Road, Lincoln',
		'label': 'Newark Road, Lincoln'
	}, {
		'value': 'End of M57',
		'label': 'End of M57'
	}, {
		'value': 'M25 (Junction 25) B1170',
		'label': 'M25 (Junction 25) B1170'
	}, {
		'value': 'Clannell Road, Northampton',
		'label': 'Clannell Road, Northampton'
	}, {
		'value': 'B1404, Salters Lane Junction, Sunderland',
		'label': 'B1404, Salters Lane Junction, Sunderland'
	}, {
		'value': 'St Peters Road, Swinton',
		'label': 'St Peters Road, Swinton'
	}, {
		'value': 'M1',
		'label': 'M1'
	}, {
		'value': 'Stephenson Way',
		'label': 'Stephenson Way'
	}, {
		'value': 'On Slip of M1, junction 5 heading northbound',
		'label': 'On Slip of M1, junction 5 heading northbound'
	}, {
		'value': 'Crowthorne Road, Birknell',
		'label': 'Crowthorne Road, Birknell'
	}, {
		'value': 'Junction of Chiltern Avenue and Chairborough Road',
		'label': 'Junction of Chiltern Avenue and Chairborough Road'
	}, {
		'value': 'Well Road, Scholfield Lane',
		'label': 'Well Road, Scholfield Lane'
	}, {
		'value': 'A12',
		'label': 'A12'
	}, {
		'value': 'Bowling Park Drive, Bradford',
		'label': 'Bowling Park Drive, Bradford'
	}, {
		'value': 'Whitechapel Road',
		'label': 'Whitechapel Road'
	}, {
		'value': 'Wheelers Lane/ St Albans Hill',
		'label': 'Wheelers Lane/ St Albans Hill'
	}, {
		'value': 'A404, Kenton Road',
		'label': 'A404, Kenton Road'
	}, {
		'value': 'Brampton Road, Wellings',
		'label': 'Brampton Road, Wellings'
	}, {
		'value': 'Old Costessey High Street, Norwich',
		'label': 'Old Costessey High Street, Norwich'
	}, {
		'value': 'Ruislip Road B445',
		'label': 'Ruislip Road B445'
	}, {
		'value': 'Chapeltown Road/Reginald Street, Leeds',
		'label': 'Chapeltown Road/Reginald Street, Leeds'
	}, {
		'value': 'Dewsbury Road, Wakefield',
		'label': 'Dewsbury Road, Wakefield'
	}, {
		'value': 'Horsham Road, Crawley',
		'label': 'Horsham Road, Crawley'
	}, {
		'value': 'A50 in Nottingham',
		'label': 'A50 in Nottingham'
	}, {
		'value': 'Shepherds Lane, Telford',
		'label': 'Shepherds Lane, Telford'
	}, {
		'value': 'A41, Hockley Circus in Birmingham',
		'label': 'A41, Hockley Circus in Birmingham'
	}, {
		'value': 'Bristol Road, Birmingham',
		'label': 'Bristol Road, Birmingham'
	}, {
		'value': 'Higham Hill Road, East London',
		'label': 'Higham Hill Road, East London'
	}, {
		'value': 'Bolton Road',
		'label': 'Bolton Road'
	}, {
		'value': 'Chalkwell Road, Sittingbourne',
		'label': 'Chalkwell Road, Sittingbourne'
	}, {
		'value': 'Frizinghell Road Bradford',
		'label': 'Frizinghell Road Bradford'
	}, {
		'value': 'Fishponds Road, Bristol',
		'label': 'Fishponds Road, Bristol'
	}, {
		'value': 'Rochdale Road',
		'label': 'Rochdale Road'
	}, {
		'value': 'Holden Ing Way, Birstall',
		'label': 'Holden Ing Way, Birstall'
	}, {
		'value': 'Junction 3 of the M4',
		'label': 'Junction 3 of the M4'
	}, {
		'value': 'A639 Barnsdale Road (also known as Church Side)',
		'label': 'A639 Barnsdale Road (also known as Church Side)'
	}, {
		'value': 'Uxbridge Road',
		'label': 'Uxbridge Road'
	}, {
		'value': 'Billet Road, London',
		'label': 'Billet Road, London'
	}, {
		'value': 'Geat Cambridge Road London',
		'label': 'Geat Cambridge Road London'
	}, {
		'value': 'Montrose Avenue, Wigan',
		'label': 'Montrose Avenue, Wigan'
	}, {
		'value': 'Hyde Park Road, Leeds',
		'label': 'Hyde Park Road, Leeds'
	}, {
		'value': 'Aylestone Road, Leicester',
		'label': 'Aylestone Road, Leicester'
	}, {
		'value': 'Brudenell Road Headingly, Leeds',
		'label': 'Brudenell Road Headingly, Leeds'
	}, {
		'value': 'Cobetts Hill',
		'label': 'Cobetts Hill'
	}, {
		'value': 'A650, Bradford',
		'label': 'A650, Bradford'
	}, {
		'value': 'Golf Oak Road',
		'label': 'Golf Oak Road'
	}, {
		'value': 'Park Lane, Rothwell, West Yorkshire',
		'label': 'Park Lane, Rothwell, West Yorkshire'
	}, {
		'value': 'Havelock Street / Low Mill Lane, Dewsbury',
		'label': 'Havelock Street / Low Mill Lane, Dewsbury'
	}, {
		'value': 'Ruislip Road, Greenford',
		'label': 'Ruislip Road, Greenford'
	}, {
		'value': 'Eve Road, London',
		'label': 'Eve Road, London'
	}, {
		'value': 'A505, Towards Luton',
		'label': 'A505, Towards Luton'
	}, {
		'value': 'Great Northway. London',
		'label': 'Great Northway. London'
	}, {
		'value': 'A40 towards Slough',
		'label': 'A40 towards Slough'
	}, {
		'value': 'A10',
		'label': 'A10'
	}, {
		'value': 'Greens Road, Ellesmere Port',
		'label': 'Greens Road, Ellesmere Port'
	}, {
		'value': 'Ashtree Hill Road',
		'label': 'Ashtree Hill Road'
	}, {
		'value': 'Bank Bottom, Halifax',
		'label': 'Bank Bottom, Halifax'
	}, {
		'value': 'Longmore Avenue Barnet',
		'label': 'Longmore Avenue Barnet'
	}, {
		'value': 'Cambridge Heath Road, London, E1',
		'label': 'Cambridge Heath Road, London, E1'
	}, {
		'value': 'Bridge Street, Swinton',
		'label': 'Bridge Street, Swinton'
	}, {
		'value': 'A12 towards Romford',
		'label': 'A12 towards Romford'
	}, {
		'value': 'Mill Lane, Huthwaite',
		'label': 'Mill Lane, Huthwaite'
	}, {
		'value': 'A4031 All Saints Way in West Bromwich',
		'label': 'A4031 All Saints Way in West Bromwich'
	}, {
		'value': 'A41, Watford Bypass',
		'label': 'A41, Watford Bypass'
	}, {
		'value': 'Country Way, A316',
		'label': 'Country Way, A316'
	}, {
		'value': 'Scotthall Road, Leeds',
		'label': 'Scotthall Road, Leeds'
	}, {
		'value': 'delaide Grove, Hammersmith, London',
		'label': 'delaide Grove, Hammersmith, London'
	}, {
		'value': 'Wharncliffe Road',
		'label': 'Wharncliffe Road'
	}, {
		'value': 'Wrenthorpe Lane in Wakefield',
		'label': 'Wrenthorpe Lane in Wakefield'
	}, {
		'value': 'Hulmes Road, Failsworth Manchester',
		'label': 'Hulmes Road, Failsworth Manchester'
	}, {
		'value': 'Tower Hamlets Road Dover',
		'label': 'Tower Hamlets Road Dover'
	}, {
		'value': 'M1, Junction 229',
		'label': 'M1, Junction 229'
	}, {
		'value': 'Bridge Street',
		'label': 'Bridge Street'
	}, {
		'value': 'A229 Royal Engineers Road on route to Maidstone A229 Royal Engineers Road on route to Maidstone',
		'label': 'A229 Royal Engineers Road on route to Maidstone A229 Royal Engineers Road on route to Maidstone'
	}, {
		'value': 'Wilmer Road, Bradford',
		'label': 'Wilmer Road, Bradford'
	}, {
		'value': 'Devons Road, Lonond, E3 St Pauls Way, London',
		'label': 'Devons Road, Lonond, E3 St Pauls Way, London'
	}, {
		'value': 'Pursley Road Pursley Road',
		'label': 'Pursley Road Pursley Road'
	}, {
		'value': 'Burns Avenue/Masefield Avenue',
		'label': 'Burns Avenue/Masefield Avenue'
	}, {
		'value': 'Manor Farm Road',
		'label': 'Manor Farm Road'
	}, {
		'value': 'Hollinwood, Whixall',
		'label': 'Hollinwood, Whixall'
	}, {
		'value': 'Roundhay Park, Leeds',
		'label': 'Roundhay Park, Leeds'
	}, {
		'value': 'Cheetham Hill Road',
		'label': 'Cheetham Hill Road'
	}, {
		'value': 'A46 approaching Swinderby Roundabout',
		'label': 'A46 approaching Swinderby Roundabout'
	}, {
		'value': 'A46',
		'label': 'A46'
	}, {
		'value': 'Tottenhall Road in London',
		'label': 'Tottenhall Road in London'
	}, {
		'value': 'M6 towards Birmingham',
		'label': 'M6 towards Birmingham'
	}, {
		'value': 'Richmond Drive, Watford',
		'label': 'Richmond Drive, Watford'
	}, {
		'value': 'Junction 2 slip road, M40, Slough',
		'label': 'Junction 2 slip road, M40, Slough'
	}, {
		'value': 'A406 (Slip Road) A406',
		'label': 'A406 (Slip Road) A406'
	}, {
		'value': 'Portland Road, off Balfour Road',
		'label': 'Portland Road, off Balfour Road'
	}, {
		'value': "Tibbet's Corner Roundabout, London",
		'label': "Tibbet's Corner Roundabout, London"
	}, {
		'value': 'Conway Road, Luton, London',
		'label': 'Conway Road, Luton, London'
	}, {
		'value': 'London Road near Morecambe Gardens',
		'label': 'London Road near Morecambe Gardens'
	}, {
		'value': 'A406 Wembley Bypass London',
		'label': 'A406 Wembley Bypass London'
	}, {
		'value': 'Moston Lane East',
		'label': 'Moston Lane East'
	}, {
		'value': 'Hartley Road, Nottingham',
		'label': 'Hartley Road, Nottingham'
	}, {
		'value': 'Parkdale /Oakdale Road',
		'label': 'Parkdale /Oakdale Road'
	}, {
		'value': 'Brundell Street Wakefield',
		'label': 'Brundell Street Wakefield'
	}, {
		'value': 'Canal Road/Aviary Road, Leeds',
		'label': 'Canal Road/Aviary Road, Leeds'
	}, {
		'value': 'Colindeep Way Colindeep Way',
		'label': 'Colindeep Way Colindeep Way'
	}, {
		'value': 'A40 Western Avenue',
		'label': 'A40 Western Avenue'
	}, {
		'value': 'Arndale Centre Car Park, Bradford',
		'label': 'Arndale Centre Car Park, Bradford'
	}, {
		'value': 'Pyebush Roundabout, A355',
		'label': 'Pyebush Roundabout, A355'
	}, {
		'value': 'M25 at Junction 19 roundabout LONDON',
		'label': 'M25 at Junction 19 roundabout LONDON'
	}, {
		'value': 'M4 Motorway',
		'label': 'M4 Motorway'
	}, {
		'value': 'Park Lane, Hayes',
		'label': 'Park Lane, Hayes'
	}, {
		'value': 'Eldon Road, Nottingham',
		'label': 'Eldon Road, Nottingham'
	}, {
		'value': 'Grove Road in Romford',
		'label': 'Grove Road in Romford'
	}, {
		'value': 'A406 North Circular Road London',
		'label': 'A406 North Circular Road London'
	}, {
		'value': 'Junction of A6 and Watergate Lane Over Hulton, Bolton',
		'label': 'Junction of A6 and Watergate Lane Over Hulton, Bolton'
	}, {
		'value': 'roundabout joining the A1 and M25 at Bishops Strotford',
		'label': 'roundabout joining the A1 and M25 at Bishops Strotford'
	}, {
		'value': 'Mill Street/Jacob Street Dingle',
		'label': 'Mill Street/Jacob Street Dingle'
	}, {
		'value': 'M25-E A1 The Grove',
		'label': 'M25-E A1 The Grove'
	}, {
		'value': 'Beaconsfield Dual Carriage Way',
		'label': 'Beaconsfield Dual Carriage Way'
	}, {
		'value': 'University Boulevard Nottingham',
		'label': 'University Boulevard Nottingham'
	}, {
		'value': 'Saville Road',
		'label': 'Saville Road'
	}, {
		'value': 'Lomeshaye Road, Nelson',
		'label': 'Lomeshaye Road, Nelson'
	}, {
		'value': 'Wilmer Way, London, N14 London',
		'label': 'Wilmer Way, London, N14 London'
	}, {
		'value': 'Roundabout exiting Fanshawe Ave Barking',
		'label': 'Roundabout exiting Fanshawe Ave Barking'
	}, {
		'value': 'A41 Watford Way A41 Watford Way',
		'label': 'A41 Watford Way A41 Watford Way'
	}, {
		'value': 'Hendon Lane into Allandale Avenue. London N3',
		'label': 'Hendon Lane into Allandale Avenue. London N3'
	}, {
		'value': 'Mortlake Road, Ilford',
		'label': 'Mortlake Road, Ilford'
	}, {
		'value': 'A1 Great Northway junction Chatsworth Avenue',
		'label': 'A1 Great Northway junction Chatsworth Avenue'
	}, {
		'value': 'A3400, Birmingham Road towards Henley in Arden',
		'label': 'A3400, Birmingham Road towards Henley in Arden'
	}, {
		'value': 'Hartford Road, Borehamwood Hertfordshire',
		'label': 'Hartford Road, Borehamwood Hertfordshire'
	}, {
		'value': 'A406 towards waterworks roundabout',
		'label': 'A406 towards waterworks roundabout'
	}, {
		'value': 'Lodge Road West Bromwich',
		'label': 'Lodge Road West Bromwich'
	}, {
		'value': 'Ringway in Heston',
		'label': 'Ringway in Heston'
	}, {
		'value': 'Leytonstone Road, London, N15',
		'label': 'Leytonstone Road, London, N15'
	}, {
		'value': 'A355 (Beaconsfield)',
		'label': 'A355 (Beaconsfield)'
	}, {
		'value': 'Bunns Lane/Flower Lane Bunns Lane/Flower Lane',
		'label': 'Bunns Lane/Flower Lane Bunns Lane/Flower Lane'
	}, {
		'value': 'Devons Road, London E3',
		'label': 'Devons Road, London E3'
	}, {
		'value': 'South Accommodation Road/Hunslet Road, Leeds \n',
		'label': 'South Accommodation Road/Hunslet Road, Leeds  '
	}, {
		'value': 'Junction 4 Roundabout M3 Surrey',
		'label': 'Junction 4 Roundabout M3 Surrey'
	}, {
		'value': 'Martha Road LONDON',
		'label': 'Martha Road LONDON'
	}, {
		'value': 'Thomas Road London',
		'label': 'Thomas Road London'
	}, {
		'value': 'Mini Roundabout Junction Brookbank Kiln Lane and Cores End Road',
		'label': 'Mini Roundabout Junction Brookbank Kiln Lane and Cores End Road'
	}, {
		'value': 'A2 Lewisham',
		'label': 'A2 Lewisham'
	}, {
		'value': 'Whalley New Road, Blackburn \n',
		'label': 'Whalley New Road, Blackburn  '
	}, {
		'value': 'Green man Roundabout\n Leytonstone, Greater London \n',
		'label': 'Green man Roundabout  Leytonstone, Greater London  '
	}, {
		'value': 'Victoria Road Derby',
		'label': 'Victoria Road Derby'
	}, {
		'value': 'Alum Rock Road Birmingham',
		'label': 'Alum Rock Road Birmingham'
	}, {
		'value': 'Hyde Park Road Leeds',
		'label': 'Hyde Park Road Leeds'
	}, {
		'value': 'Arcadia Street, London Upper Street, London',
		'label': 'Arcadia Street, London Upper Street, London'
	}, {
		'value': 'Junction of Wortley Road and Town Street Leeds, West Yorkshire',
		'label': 'Junction of Wortley Road and Town Street Leeds, West Yorkshire'
	}, {
		'value': 'Ley Street, Ilford',
		'label': 'Ley Street, Ilford'
	}, {
		'value': 'Green Lane Birmngham',
		'label': 'Green Lane Birmngham'
	}, {
		'value': 'Clovelly Avenue. Newcastle upon Tyne',
		'label': 'Clovelly Avenue. Newcastle upon Tyne'
	}, {
		'value': 'A roundabout approaching Manchester Airport',
		'label': 'A roundabout approaching Manchester Airport'
	}, {
		'value': 'Goodmayes Road Iiford',
		'label': 'Goodmayes Road Iiford'
	}, {
		'value': 'Lane End Road. High Wycombe',
		'label': 'Lane End Road. High Wycombe'
	}, {
		'value': 'Garrick Road, Hendon London Garrick Road, Hendon London',
		'label': 'Garrick Road, Hendon London Garrick Road, Hendon London'
	}, {
		'value': 'Mincing Lane Car Park. Blackburn',
		'label': 'Mincing Lane Car Park. Blackburn'
	}, {
		'value': 'Moreland Road Portsmouth',
		'label': 'Moreland Road Portsmouth'
	}, {
		'value': 'Burdett Road / Thomas Road London E3',
		'label': 'Burdett Road / Thomas Road London E3'
	}, {
		'value': 'Wallington Avenue, Reading \n',
		'label': 'Wallington Avenue, Reading  '
	}, {
		'value': 'A5 towards st albans \n',
		'label': 'A5 towards st albans  '
	}, {
		'value': 'Shipley Airedale Road Bradford',
		'label': 'Shipley Airedale Road Bradford'
	}, {
		'value': 'Central Park Road, Eastham \n',
		'label': 'Central Park Road, Eastham  '
	}, {
		'value': 'Kingsbury Road London',
		'label': 'Kingsbury Road London'
	}, {
		'value': 'Westfield Shopping Centre Stratford',
		'label': 'Westfield Shopping Centre Stratford'
	}, {
		'value': 'Bidston Village Road Prenton',
		'label': 'Bidston Village Road Prenton'
	}, {
		'value': 'A322 A332',
		'label': 'A322 A332'
	}, {
		'value': 'Hough Green Road',
		'label': 'Hough Green Road'
	}, {
		'value': 'Alworth Road Blackley',
		'label': 'Alworth Road Blackley'
	}, {
		'value': 'A40 Western Avenue London',
		'label': 'A40 Western Avenue London'
	}, {
		'value': '12 Trelves Trees Crescent. London \n',
		'label': '12 Trelves Trees Crescent. London  '
	}, {
		'value': 'White Horse Road',
		'label': 'White Horse Road'
	}, {
		'value': 'Harrowdene Road London',
		'label': 'Harrowdene Road London'
	}, {
		'value': 'Curzon Avenue EN3 9AZ',
		'label': 'Curzon Avenue EN3 9AZ'
	}, {
		'value': 'Staygate Roundabout Bradford',
		'label': 'Staygate Roundabout Bradford'
	}, {
		'value': 'Haydon Road, London, SW19 (Outside Sainsburys) \n Haydon Road, London, SW19 (Outside Sainsburys) \n',
		'label': 'Haydon Road, London, SW19 (Outside Sainsburys)   Haydon Road, London, SW19 (Outside Sainsburys)  '
	}, {
		'value': 'A25 Epsom Road London',
		'label': 'A25 Epsom Road London'
	}, {
		'value': 'Cannon Street Road London',
		'label': 'Cannon Street Road London'
	}, {
		'value': 'Mays Lane, Emerged from Barnet Lane, Barnet EN5 \n Mays Lane, Emerged from Barnet Lane, Barnet EN5 \n',
		'label': 'Mays Lane, Emerged from Barnet Lane, Barnet EN5   Mays Lane, Emerged from Barnet Lane, Barnet EN5  '
	}, {
		'value': 'B4144 Lozells Road B4144 Lozells Road',
		'label': 'B4144 Lozells Road B4144 Lozells Road'
	}, {
		'value': 'Tottenham High Street Tottenham, London',
		'label': 'Tottenham High Street Tottenham, London'
	}, {
		'value': 'Wolverton Road',
		'label': 'Wolverton Road'
	}, {
		'value': 'Whitehall Road Leeds',
		'label': 'Whitehall Road Leeds'
	}, {
		'value': 'A41 Junction Hillfield Lane \n Edgeware London',
		'label': 'A41 Junction Hillfield Lane   Edgeware London'
	}, {
		'value': 'Junction 6 M56 Manchester',
		'label': 'Junction 6 M56 Manchester'
	}, {
		'value': 'Country Road Liverpool',
		'label': 'Country Road Liverpool'
	}, {
		'value': 'Brushfield Street Nottingham',
		'label': 'Brushfield Street Nottingham'
	}, {
		'value': 'B4058/Stapleton Road \n Bristol',
		'label': 'B4058/Stapleton Road   Bristol'
	}, {
		'value': 'Waterloo Road, Capehill, Smethwick \n',
		'label': 'Waterloo Road, Capehill, Smethwick  '
	}, {
		'value': 'A428 Near Station Road (Turvey Village)',
		'label': 'A428 Near Station Road (Turvey Village)'
	}, {
		'value': 'A406 London',
		'label': 'A406 London'
	}, {
		'value': 'Roe Green/Stag Lane Edgeware',
		'label': 'Roe Green/Stag Lane Edgeware'
	}, {
		'value': 'Morning Lane, Corner of Mare Street \n',
		'label': 'Morning Lane, Corner of Mare Street  '
	}, {
		'value': 'Green Lane Palmers Green N13 North West London',
		'label': 'Green Lane Palmers Green N13 North West London'
	}, {
		'value': 'Dutton Road, Aldermans Green Industrial Estate Coventry, West Midlands',
		'label': 'Dutton Road, Aldermans Green Industrial Estate Coventry, West Midlands'
	}, {
		'value': 'High Street, Ponders End, Enfield, london \n',
		'label': 'High Street, Ponders End, Enfield, london  '
	}, {
		'value': 'Fallowdon Way, London \n A1',
		'label': 'Fallowdon Way, London   A1'
	}, {
		'value': 'Kenwowrthy Road, Hackney \n',
		'label': 'Kenwowrthy Road, Hackney  '
	}, {
		'value': 'Hampton Road Peterborough \n',
		'label': 'Hampton Road Peterborough  '
	}, {
		'value': 'Wessex Gardens London',
		'label': 'Wessex Gardens London'
	}, {
		'value': 'Roundabout Junction - Butts Road and A4053 (Ring Road) Coventry, West Midlands',
		'label': 'Roundabout Junction - Butts Road and A4053 (Ring Road) Coventry, West Midlands'
	}, {
		'value': 'Oakridge Road Basingstoke \n',
		'label': 'Oakridge Road Basingstoke  '
	}, {
		'value': 'Oxford Lane Ilford \n',
		'label': 'Oxford Lane Ilford  '
	}, {
		'value': 'West Street Roundabout A27 \n',
		'label': 'West Street Roundabout A27  '
	}, {
		'value': 'Ringley Road Whitefield \n',
		'label': 'Ringley Road Whitefield  '
	}, {
		'value': 'Eldon Back Road Old Eldon \n',
		'label': 'Eldon Back Road Old Eldon  '
	}, {
		'value': 'Dukes Meadow \n',
		'label': 'Dukes Meadow  '
	}, {
		'value': 'Leckie Road, Walsall West Midlands \n',
		'label': 'Leckie Road, Walsall West Midlands  '
	}, {
		'value': 'Headingly Avenue Leeds \n',
		'label': 'Headingly Avenue Leeds  '
	}, {
		'value': 'Smalewell Road \n Leeds',
		'label': 'Smalewell Road   Leeds'
	}, {
		'value': 'A5100 Station Road Edgeware, London',
		'label': 'A5100 Station Road Edgeware, London'
	}, {
		'value': 'South Gate A645 \n',
		'label': 'South Gate A645  '
	}, {
		'value': 'Fryent Way Barnet London',
		'label': 'Fryent Way Barnet London'
	}, {
		'value': 'Arlington Street,',
		'label': 'Arlington Street,'
	}, {
		'value': 'Great North Way London',
		'label': 'Great North Way London'
	}, {
		'value': 'Junction of Vernon Road and Vernon Avenue Nottingham',
		'label': 'Junction of Vernon Road and Vernon Avenue Nottingham'
	}, {
		'value': 'Drummond Road, Liderland, Liverpool \n',
		'label': 'Drummond Road, Liderland, Liverpool  '
	}, {
		'value': 'Co-Op car Park \n Chesnut Lodge, Widnes \n',
		'label': 'Co-Op car Park   Chesnut Lodge, Widnes  '
	}, {
		'value': 'A206 Dartford \n',
		'label': 'A206 Dartford  '
	}, {
		'value': 'A3016 New Road \n',
		'label': 'A3016 New Road  '
	}, {
		'value': 'Bassano Road, Blackheath, Rowley Regis \n',
		'label': 'Bassano Road, Blackheath, Rowley Regis  '
	}, {
		'value': 'Watford Way London \n',
		'label': 'Watford Way London  '
	}, {
		'value': 'Bury Road/College Road Rochdale \n',
		'label': 'Bury Road/College Road Rochdale  '
	}, {
		'value': 'Northwick Park Roundabout Harrow',
		'label': 'Northwick Park Roundabout Harrow'
	}, {
		'value': 'A312/A40, \n Western Avenue, London \n',
		'label': 'A312/A40,   Western Avenue, London  '
	}, {
		'value': 'A30 Towards Feltham \n',
		'label': 'A30 Towards Feltham  '
	}, {
		'value': 'Staincliffe Hall Road, Batley \n',
		'label': 'Staincliffe Hall Road, Batley  '
	}, {
		'value': 'Killinghall Lane, Bradford',
		'label': 'Killinghall Lane, Bradford'
	}, {
		'value': 'A12 Eastern Avenue, Romford, Essex \n',
		'label': 'A12 Eastern Avenue, Romford, Essex  '
	}, {
		'value': 'Silverdale Road Stoke-On-Trent',
		'label': 'Silverdale Road Stoke-On-Trent'
	}, {
		'value': 'A1 Northbound Towards Newcastle \n',
		'label': 'A1 Northbound Towards Newcastle  '
	}, {
		'value': 'Broad Lane, London',
		'label': 'Broad Lane, London'
	}, {
		'value': 'Derby Street, Bolton',
		'label': 'Derby Street, Bolton'
	}, {
		'value': 'Merry Hill Carpark, Westfield \n',
		'label': 'Merry Hill Carpark, Westfield  '
	}, {
		'value': 'Hackney Road, A1208, London \n',
		'label': 'Hackney Road, A1208, London  '
	}, {
		'value': 'Thyra Grove London',
		'label': 'Thyra Grove London'
	}, {
		'value': 'Elms Lane/Homefield Road London',
		'label': 'Elms Lane/Homefield Road London'
	}, {
		'value': 'Gilnow Lane, Bolton \n',
		'label': 'Gilnow Lane, Bolton  '
	}, {
		'value': 'Jarman Fields Retail Park St Albans Road \n',
		'label': 'Jarman Fields Retail Park St Albans Road  '
	}, {
		'value': 'Meadows Way \n Nottingham',
		'label': 'Meadows Way   Nottingham'
	}, {
		'value': 'Commercial Road \n',
		'label': 'Commercial Road  '
	}, {
		'value': 'Bradford Street / Woodford Street',
		'label': 'Bradford Street / Woodford Street'
	}, {
		'value': 'A406',
		'label': 'A406'
	}, {
		'value': 'Junction of Venue 5 Car Park and Whitby Road Ruislip',
		'label': 'Junction of Venue 5 Car Park and Whitby Road Ruislip'
	}, {
		'value': 'Cathrine De Barnes Lane, Birmingham',
		'label': 'Cathrine De Barnes Lane, Birmingham'
	}, {
		'value': 'Henniker Garden, Eastham \n',
		'label': 'Henniker Garden, Eastham  '
	}, {
		'value': 'Brackenlea Drive, Blackley, M9 8NU \n',
		'label': 'Brackenlea Drive, Blackley, M9 8NU  '
	}, {
		'value': 'B383 at Ascot',
		'label': 'B383 at Ascot'
	}, {
		'value': 'Windmill Road N18',
		'label': 'Windmill Road N18'
	}, {
		'value': 'Darnall Road, Sheffield',
		'label': 'Darnall Road, Sheffield'
	}, {
		'value': 'Wirksworth Road \n Derby',
		'label': 'Wirksworth Road   Derby'
	}, {
		'value': 'Charing Cross Hospital Car Park, London, W6 8RF \n',
		'label': 'Charing Cross Hospital Car Park, London, W6 8RF  '
	}, {
		'value': 'Slip Road joining A1 Barnet by-Pass, London \n',
		'label': 'Slip Road joining A1 Barnet by-Pass, London  '
	}, {
		'value': 'Ilford Lane Essex',
		'label': 'Ilford Lane Essex'
	}, {
		'value': 'A217 Brighton Road Roundabout \n',
		'label': 'A217 Brighton Road Roundabout  '
	}, {
		'value': 'Thistley Hough \n',
		'label': 'Thistley Hough  '
	}, {
		'value': 'A4140, Harrow NW9',
		'label': 'A4140, Harrow NW9'
	}, {
		'value': 'Brancaster Road Iiford',
		'label': 'Brancaster Road Iiford'
	}, {
		'value': 'Nightingale Lane, London',
		'label': 'Nightingale Lane, London'
	}, {
		'value': 'Red Cow Drove, Holbeach, Spalding',
		'label': 'Red Cow Drove, Holbeach, Spalding'
	}, {
		'value': 'Oxhey Lane, Harrow. London',
		'label': 'Oxhey Lane, Harrow. London'
	}, {
		'value': "St John's Wood Road, London",
		'label': "St John's Wood Road, London"
	}, {
		'value': 'Meridean Way',
		'label': 'Meridean Way'
	}, {
		'value': 'Crest Roundabout, London, NW2',
		'label': 'Crest Roundabout, London, NW2'
	}, {
		'value': 'Cambridge Street in Cardiff',
		'label': 'Cambridge Street in Cardiff'
	}, {
		'value': 'Bishop Roundabout, Windsor. Maidenhead',
		'label': 'Bishop Roundabout, Windsor. Maidenhead'
	}, {
		'value': 'Grasmere Road, Tottenham',
		'label': 'Grasmere Road, Tottenham'
	}, {
		'value': 'Green Lanes/Turnpike Raod London',
		'label': 'Green Lanes/Turnpike Raod London'
	}, {
		'value': 'great Western Street, Manchester',
		'label': 'great Western Street, Manchester'
	}, {
		'value': 'Canal Street Leeds',
		'label': 'Canal Street Leeds'
	}, {
		'value': 'Manchester Road, Nelson',
		'label': 'Manchester Road, Nelson'
	}, {
		'value': 'Asda, Crosshabour, London',
		'label': 'Asda, Crosshabour, London'
	}, {
		'value': 'Oldham Road, Failsworth',
		'label': 'Oldham Road, Failsworth'
	}, {
		'value': 'Aylmer Road',
		'label': 'Aylmer Road'
	}, {
		'value': 'A4440',
		'label': 'A4440'
	}, {
		'value': 'Liverpol Road. Widness',
		'label': 'Liverpol Road. Widness'
	}, {
		'value': 'Acton High Street, A4020',
		'label': 'Acton High Street, A4020'
	}, {
		'value': 'M25 Motorway',
		'label': 'M25 Motorway'
	}, {
		'value': 'Brent Cross roundabout',
		'label': 'Brent Cross roundabout'
	}, {
		'value': 'Oak Lane, Bradford',
		'label': 'Oak Lane, Bradford'
	}, {
		'value': 'Hemel Hempstead Exit off M1',
		'label': 'Hemel Hempstead Exit off M1'
	}, {
		'value': 'London Road, High Wycombe',
		'label': 'London Road, High Wycombe'
	}, {
		'value': 'A1058 Coast Road',
		'label': 'A1058 Coast Road'
	}, {
		'value': 'Belgrave, Middleway Birmingham',
		'label': 'Belgrave, Middleway Birmingham'
	}, {
		'value': 'Fratton Road Portsmouth',
		'label': 'Fratton Road Portsmouth'
	}, {
		'value': 'Wellington Road, Dewsbury',
		'label': 'Wellington Road, Dewsbury'
	}, {
		'value': 'Talbot Street, Dewsbury',
		'label': 'Talbot Street, Dewsbury'
	}, {
		'value': 'Southend Road, London',
		'label': 'Southend Road, London'
	}, {
		'value': 'Valley Road / Hamm Strasse, Bradford',
		'label': 'Valley Road / Hamm Strasse, Bradford'
	}, {
		'value': 'Brasshouse Lane, Smethwick',
		'label': 'Brasshouse Lane, Smethwick'
	}, {
		'value': 'The Broadway (A1010)',
		'label': 'The Broadway (A1010)'
	}, {
		'value': 'Uckfield Road, Enfield',
		'label': 'Uckfield Road, Enfield'
	}, {
		'value': 'A41 (near the Green Lane roundabout)',
		'label': 'A41 (near the Green Lane roundabout)'
	}, {
		'value': 'Snowden Street, Bradford',
		'label': 'Snowden Street, Bradford'
	}, {
		'value': 'Lancaster Drive, Bolton',
		'label': 'Lancaster Drive, Bolton'
	}, {
		'value': 'A4146 M1 North',
		'label': 'A4146 M1 North'
	}, {
		'value': 'Slip Road joining A40 from the Target roundabout.',
		'label': 'Slip Road joining A40 from the Target roundabout.'
	}, {
		'value': 'Derby Road',
		'label': 'Derby Road'
	}, {
		'value': 'Highbury Gardens/Claremont Gardens London',
		'label': 'Highbury Gardens/Claremont Gardens London'
	}, {
		'value': 'Aston Road',
		'label': 'Aston Road'
	}, {
		'value': 'Neath Hill in Milton Keynes',
		'label': 'Neath Hill in Milton Keynes'
	}, {
		'value': 'Grange Road',
		'label': 'Grange Road'
	}, {
		'value': 'Hampstead Road, Handsworth Wood in the Westmidlands',
		'label': 'Hampstead Road, Handsworth Wood in the Westmidlands'
	}, {
		'value': 'Woolworth Road in London',
		'label': 'Woolworth Road in London'
	}, {
		'value': 'Redbridge roundabout, London',
		'label': 'Redbridge roundabout, London'
	}, {
		'value': 'Emscote Road',
		'label': 'Emscote Road'
	}, {
		'value': 'A40 London',
		'label': 'A40 London'
	}, {
		'value': 'Sleford Road',
		'label': 'Sleford Road'
	}, {
		'value': 'Edgware Way A41',
		'label': 'Edgware Way A41'
	}, {
		'value': 'Dale Lane, South Elmsall',
		'label': 'Dale Lane, South Elmsall'
	}, {
		'value': 'West Gate, Wakefield',
		'label': 'West Gate, Wakefield'
	}, {
		'value': 'Hendon Lane, N3 London',
		'label': 'Hendon Lane, N3 London'
	}, {
		'value': 'M25',
		'label': 'M25'
	}, {
		'value': 'M25 Junction in Staines',
		'label': 'M25 Junction in Staines'
	}, {
		'value': 'Beechwood Avenue / North Circular Finchley, London',
		'label': 'Beechwood Avenue / North Circular Finchley, London'
	}, {
		'value': 'M4 from Slough to London Junction 7',
		'label': 'M4 from Slough to London Junction 7'
	}, {
		'value': 'Croydon Road/ Windmill Road',
		'label': 'Croydon Road/ Windmill Road'
	}, {
		'value': 'St Marys Lane, Hornchurch',
		'label': 'St Marys Lane, Hornchurch'
	}, {
		'value': 'Globe Road London',
		'label': 'Globe Road London'
	}, {
		'value': 'M42 from Birmingham to Nottingham',
		'label': 'M42 from Birmingham to Nottingham'
	}, {
		'value': 'Savile Road (Roundabout), Dewsbury',
		'label': 'Savile Road (Roundabout), Dewsbury'
	}, {
		'value': 'Bradley Road Huddersfield',
		'label': 'Bradley Road Huddersfield'
	}, {
		'value': 'Redbridge, Dodd Way',
		'label': 'Redbridge, Dodd Way'
	}, {
		'value': 'A519 Newcastle-Under-Lyme',
		'label': 'A519 Newcastle-Under-Lyme'
	}, {
		'value': 'Enfield McDonalds Car park on the A10',
		'label': 'Enfield McDonalds Car park on the A10'
	}, {
		'value': 'Hurstwood Road London',
		'label': 'Hurstwood Road London'
	}, {
		'value': 'Park Avenue London',
		'label': 'Park Avenue London'
	}, {
		'value': 'M621, Leeds',
		'label': 'M621, Leeds'
	}, {
		'value': 'Longdon Road.',
		'label': 'Longdon Road.'
	}, {
		'value': 'Ordsall Lane, Manchester',
		'label': 'Ordsall Lane, Manchester'
	}, {
		'value': 'Queens Drive in Nottingham, Nottinghamshire',
		'label': 'Queens Drive in Nottingham, Nottinghamshire'
	}, {
		'value': 'Northfield Roundabout, A509, Milton Keynes',
		'label': 'Northfield Roundabout, A509, Milton Keynes'
	}, {
		'value': 'Fox Lane at its junction with Burford Gardens in Enfield',
		'label': 'Fox Lane at its junction with Burford Gardens in Enfield'
	}, {
		'value': 'A1237 York',
		'label': 'A1237 York'
	}, {
		'value': 'Eas Lane in Wembley',
		'label': 'Eas Lane in Wembley'
	}, {
		'value': 'Wightman Road j/w Turnpike Lane, London N22',
		'label': 'Wightman Road j/w Turnpike Lane, London N22'
	}, {
		'value': 'Hainault Road in Essex',
		'label': 'Hainault Road in Essex'
	}, {
		'value': 'Catherine Ded Barne Road, Birmingham',
		'label': 'Catherine Ded Barne Road, Birmingham'
	}, {
		'value': 'Bolton Street',
		'label': 'Bolton Street'
	}, {
		'value': 'Junction of Beechwood Road and Dunstable Road, Luton',
		'label': 'Junction of Beechwood Road and Dunstable Road, Luton'
	}, {
		'value': 'Brettenham Road in London',
		'label': 'Brettenham Road in London'
	}, {
		'value': 'Westgate Road Newcastle',
		'label': 'Westgate Road Newcastle'
	}, {
		'value': 'Hatfield \nRoad / Colney Heath Lane, AL4',
		'label': 'Hatfield  Road / Colney Heath Lane, AL4'
	}, {
		'value': 'Derby Street/ Cheetham \nHill Road',
		'label': 'Derby Street/ Cheetham  Hill Road'
	}, {
		'value': 'Queens Road Halifax',
		'label': 'Queens Road Halifax'
	}, {
		'value': 'Near Cock Fosters Roundabout',
		'label': 'Near Cock Fosters Roundabout'
	}, {
		'value': 'Argon Road, N18',
		'label': 'Argon Road, N18'
	}, {
		'value': 'A12, Eastern Avenue, Chadwell Heath',
		'label': 'A12, Eastern Avenue, Chadwell Heath'
	}, {
		'value': 'M27',
		'label': 'M27'
	}, {
		'value': 'Southerland Avenue, W1, Maida Vale',
		'label': 'Southerland Avenue, W1, Maida Vale'
	}, {
		'value': 'Upper Sunbury Road in London',
		'label': 'Upper Sunbury Road in London'
	}, {
		'value': 'Seven Sisters Road, Albert Road, N15',
		'label': 'Seven Sisters Road, Albert Road, N15'
	}, {
		'value': 'Ilford Road/Henly Road London',
		'label': 'Ilford Road/Henly Road London'
	}, {
		'value': 'Croft Holme Lane in Cambridge',
		'label': 'Croft Holme Lane in Cambridge'
	}, {
		'value': 'Thornton Road, Bradford',
		'label': 'Thornton Road, Bradford'
	}, {
		'value': 'A453, Nottingham',
		'label': 'A453, Nottingham'
	}, {
		'value': 'Addison Road, Hertford Road',
		'label': 'Addison Road, Hertford Road'
	}, {
		'value': 'A10 Great Cambridge Road',
		'label': 'A10 Great Cambridge Road'
	}, {
		'value': 'Hanger Lane Towards Greenford',
		'label': 'Hanger Lane Towards Greenford'
	}, {
		'value': 'A312 towards Heathrow/M4',
		'label': 'A312 towards Heathrow/M4'
	}, {
		'value': 'A13 Grays towards Tilbury',
		'label': 'A13 Grays towards Tilbury'
	}, {
		'value': 'Mill Road, Brighton',
		'label': 'Mill Road, Brighton'
	}, {
		'value': 'Avenue Road, London, NW3',
		'label': 'Avenue Road, London, NW3'
	}, {
		'value': 'Staples Corner, Edgware Road, London',
		'label': 'Staples Corner, Edgware Road, London'
	}, {
		'value': 'M1 Junction 5 Watford',
		'label': 'M1 Junction 5 Watford'
	}, {
		'value': 'Weston Road, Stoke ST3 6HD',
		'label': 'Weston Road, Stoke ST3 6HD'
	}, {
		'value': 'Denmark Road A5267',
		'label': 'Denmark Road A5267'
	}, {
		'value': 'Berwick Road Abbotts Drive',
		'label': 'Berwick Road Abbotts Drive'
	}, {
		'value': 'Junction of London Road amd Church Street Stoke on Trent',
		'label': 'Junction of London Road amd Church Street Stoke on Trent'
	}, {
		'value': 'Woodhead Road/ Leek Road Stoke on Trent',
		'label': 'Woodhead Road/ Leek Road Stoke on Trent'
	}, {
		'value': 'Winston Way in Ilford, Essex',
		'label': 'Winston Way in Ilford, Essex'
	}, {
		'value': 'High Town Road, Banbury',
		'label': 'High Town Road, Banbury'
	}, {
		'value': 'Dudden Hill Lane, London',
		'label': 'Dudden Hill Lane, London'
	}, {
		'value': 'Zebra Crossing on Chadderton Way in Oldham',
		'label': 'Zebra Crossing on Chadderton Way in Oldham'
	}, {
		'value': 'Barnet By Pass - Slip Road to A1',
		'label': 'Barnet By Pass - Slip Road to A1'
	}, {
		'value': 'A461 Roundabout of Junction 1, M54, Wolverhamton',
		'label': 'A461 Roundabout of Junction 1, M54, Wolverhamton'
	}, {
		'value': 'Streatham High Road',
		'label': 'Streatham High Road'
	}, {
		'value': 'Canterbury Road, Canterbury',
		'label': 'Canterbury Road, Canterbury'
	}, {
		'value': 'Car Park near church, Edmonton',
		'label': 'Car Park near church, Edmonton'
	}, {
		'value': 'Lordship Lane, N22 (Near Sirwan Food Centre)',
		'label': 'Lordship Lane, N22 (Near Sirwan Food Centre)'
	}, {
		'value': 'Kirkstall Road, Bradford',
		'label': 'Kirkstall Road, Bradford'
	}, {
		'value': 'A167/ A184',
		'label': 'A167/ A184'
	}, {
		'value': 'Muntz Sheet, Birmingham toward the Green Lane',
		'label': 'Muntz Sheet, Birmingham toward the Green Lane'
	}, {
		'value': 'Windsor Street, Colne',
		'label': 'Windsor Street, Colne'
	}, {
		'value': 'Main road of Harborough \nAvenue.',
		'label': 'Main road of Harborough  Avenue.'
	}, {
		'value': 'Stamford Hill',
		'label': 'Stamford Hill'
	}, {
		'value': 'Field Hill in Batley',
		'label': 'Field Hill in Batley'
	}, {
		'value': 'Cambridge Street off Bromford Lane',
		'label': 'Cambridge Street off Bromford Lane'
	}, {
		'value': 'Minworth Road, Birmingham, B46 1NH',
		'label': 'Minworth Road, Birmingham, B46 1NH'
	}, {
		'value': 'Friern Barnet Lane, London',
		'label': 'Friern Barnet Lane, London'
	}, {
		'value': 'Station Road, Chester',
		'label': 'Station Road, Chester'
	}, {
		'value': 'Roman Road, London, E6',
		'label': 'Roman Road, London, E6'
	}, {
		'value': 'Rochester Road, A226, M41 0RN',
		'label': 'Rochester Road, A226, M41 0RN'
	}, {
		'value': 'Princess Parkway, Manchester',
		'label': 'Princess Parkway, Manchester'
	}, {
		'value': 'Silver Street, Enfield',
		'label': 'Silver Street, Enfield'
	}, {
		'value': 'Coventry Road, Birmingham',
		'label': 'Coventry Road, Birmingham'
	}, {
		'value': 'Halifax Road, Dewsbury',
		'label': 'Halifax Road, Dewsbury'
	}, {
		'value': 'Colbank Road in Birmingham',
		'label': 'Colbank Road in Birmingham'
	}, {
		'value': 'Lieutenant Ellis Way (B198) approaching a roundabout',
		'label': 'Lieutenant Ellis Way (B198) approaching a roundabout'
	}, {
		'value': 'Old Ford Road in London',
		'label': 'Old Ford Road in London'
	}, {
		'value': 'Washwood Heath Road, \nBirmingham',
		'label': 'Washwood Heath Road,  Birmingham'
	}, {
		'value': 'Out of Northwood Street, across the major road',
		'label': 'Out of Northwood Street, across the major road'
	}, {
		'value': 'Linthorpe Road, \nMiddlesbrough',
		'label': 'Linthorpe Road,  Middlesbrough'
	}, {
		'value': 'Slaithwaite Road, Thornhill',
		'label': 'Slaithwaite Road, Thornhill'
	}, {
		'value': 'Ellwood Avenue in Southwick, Sunderland',
		'label': 'Ellwood Avenue in Southwick, Sunderland'
	}, {
		'value': 'Car Park in Imperial Way, Croydon',
		'label': 'Car Park in Imperial Way, Croydon'
	}, {
		'value': 'Roehampton Lane South West London',
		'label': 'Roehampton Lane South West London'
	}, {
		'value': 'A454 Black Country Route, Walsall',
		'label': 'A454 Black Country Route, Walsall'
	}, {
		'value': 'Birmingham Road Birmingham',
		'label': 'Birmingham Road Birmingham'
	}, {
		'value': 'Foleshill Road Coventry',
		'label': 'Foleshill Road Coventry'
	}, {
		'value': 'Laisteridge Lane',
		'label': 'Laisteridge Lane'
	}, {
		'value': 'A4 Bath Road',
		'label': 'A4 Bath Road'
	}, {
		'value': 'Hockley Hill, Birmingham',
		'label': 'Hockley Hill, Birmingham'
	}, {
		'value': 'The Drive, NW11, London The Drive, NW11, London',
		'label': 'The Drive, NW11, London The Drive, NW11, London'
	}, {
		'value': 'Tillford A442',
		'label': 'Tillford A442'
	}, {
		'value': 'A610 Dual Carriageway to Ripley',
		'label': 'A610 Dual Carriageway to Ripley'
	}, {
		'value': 'Mole Road',
		'label': 'Mole Road'
	}, {
		'value': 'Farnham Road, Slough',
		'label': 'Farnham Road, Slough'
	}, {
		'value': 'Smethurst Lane in Bolton',
		'label': 'Smethurst Lane in Bolton'
	}, {
		'value': 'Swan \nLane, Coventry',
		'label': 'Swan  Lane, Coventry'
	}, {
		'value': 'Leamouth Road \nRoundabout, Tower Hamlets, London',
		'label': 'Leamouth Road  Roundabout, Tower Hamlets, London'
	}, {
		'value': 'A454, Wolverhampton',
		'label': 'A454, Wolverhampton'
	}, {
		'value': 'Tunnel A1261 (A13)',
		'label': 'Tunnel A1261 (A13)'
	}, {
		'value': 'Dearsley Road, Enfield',
		'label': 'Dearsley Road, Enfield'
	}, {
		'value': 'Brain Clougn Way',
		'label': 'Brain Clougn Way'
	}, {
		'value': 'Oakley Grove Leeds',
		'label': 'Oakley Grove Leeds'
	}, {
		'value': 'New City Road, London, E13',
		'label': 'New City Road, London, E13'
	}, {
		'value': 'Romford Road London',
		'label': 'Romford Road London'
	}, {
		'value': 'Mount Street',
		'label': 'Mount Street'
	}, {
		'value': 'A264/B2195 Roundabout',
		'label': 'A264/B2195 Roundabout'
	}, {
		'value': 'Cricklewood, Crown Plaza Hotel',
		'label': 'Cricklewood, Crown Plaza Hotel'
	}, {
		'value': 'Leeds Road',
		'label': 'Leeds Road'
	}, {
		'value': 'Fore Street/Brettenham Road, London, N9',
		'label': 'Fore Street/Brettenham Road, London, N9'
	}, {
		'value': 'Victoria Avenue, Manchester',
		'label': 'Victoria Avenue, Manchester'
	}, {
		'value': 'Atlas Road (E13)',
		'label': 'Atlas Road (E13)'
	}, {
		'value': 'Hanger Lane Slip Road A40',
		'label': 'Hanger Lane Slip Road A40'
	}, {
		'value': 'Edgar Street Roundabout Hereford',
		'label': 'Edgar Street Roundabout Hereford'
	}, {
		'value': 'Coventry Road Bimingham',
		'label': 'Coventry Road Bimingham'
	}, {
		'value': 'New Market Street Colne',
		'label': 'New Market Street Colne'
	}, {
		'value': 'Henley Road in Coventry',
		'label': 'Henley Road in Coventry'
	}, {
		'value': 'Blackburn Road, Bolton',
		'label': 'Blackburn Road, Bolton'
	}, {
		'value': 'Harehills Road, Leeds',
		'label': 'Harehills Road, Leeds'
	}, {
		'value': 'Hollins Road, Oldham',
		'label': 'Hollins Road, Oldham'
	}, {
		'value': 'Oxhill Raad, \nA4044, Birmingham',
		'label': 'Oxhill Raad,  A4044, Birmingham'
	}, {
		'value': 'Stradford Road, Clahall',
		'label': 'Stradford Road, Clahall'
	}, {
		'value': 'M6',
		'label': 'M6'
	}, {
		'value': 'The junction and give way with \nRegent Rd',
		'label': 'The junction and give way with  Regent Rd'
	}, {
		'value': 'Sneinton Dale',
		'label': 'Sneinton Dale'
	}, {
		'value': 'Bruce Road Junction, Fforestrach Industrial Estate',
		'label': 'Bruce Road Junction, Fforestrach Industrial Estate'
	}, {
		'value': 'Tottenham',
		'label': 'Tottenham'
	}, {
		'value': 'Beckett Street, Leeds',
		'label': 'Beckett Street, Leeds'
	}, {
		'value': 'High Street',
		'label': 'High Street'
	}, {
		'value': 'Finchley Road, Outside Kwik Fit, NW11, London',
		'label': 'Finchley Road, Outside Kwik Fit, NW11, London'
	}, {
		'value': 'Tintern Road, North London',
		'label': 'Tintern Road, North London'
	}, {
		'value': 'A3 and A244 Roundabout',
		'label': 'A3 and A244 Roundabout'
	}, {
		'value': 'A406, intending to join the A40 travelling in the direction of Uxbridge',
		'label': 'A406, intending to join the A40 travelling in the direction of Uxbridge'
	}, {
		'value': 'B&Q Carpark, Filton, Bristol',
		'label': 'B&Q Carpark, Filton, Bristol'
	}, {
		'value': 'Church Road, A412 (London to Slough)',
		'label': 'Church Road, A412 (London to Slough)'
	}, {
		'value': 'A14 in Stow cum Quy',
		'label': 'A14 in Stow cum Quy'
	}, {
		'value': 'Great Marlborough Street, Manchester',
		'label': 'Great Marlborough Street, Manchester'
	}, {
		'value': 'Dearne Way/ Strathmore Grove, Rotherham',
		'label': 'Dearne Way/ Strathmore Grove, Rotherham'
	}, {
		'value': 'Finchley Road/ Hampstead Way in London',
		'label': 'Finchley Road/ Hampstead Way in London'
	}, {
		'value': 'Corporation Street, Colne, \nLancashire',
		'label': 'Corporation Street, Colne,  Lancashire'
	}, {
		'value': 'Mowbray Street, London',
		'label': 'Mowbray Street, London'
	}, {
		'value': 'A4180',
		'label': 'A4180'
	}, {
		'value': "Junction of St. Anne's Road and Beckett's Park Drive",
		'label': "Junction of St. Anne's Road and Beckett's Park Drive"
	}, {
		'value': 'Kenrick Way, West Bromwich',
		'label': 'Kenrick Way, West Bromwich'
	}, {
		'value': 'Euston Road in London',
		'label': 'Euston Road in London'
	}, {
		'value': 'Shepherds Grove',
		'label': 'Shepherds Grove'
	}, {
		'value': 'John Hall Way',
		'label': 'John Hall Way'
	}, {
		'value': 'Wedsley High Street',
		'label': 'Wedsley High Street'
	}, {
		'value': 'Brighton Road',
		'label': 'Brighton Road'
	}, {
		'value': 'A22 Harts Lane',
		'label': 'A22 Harts Lane'
	}, {
		'value': 'Oxhill Road',
		'label': 'Oxhill Road'
	}, {
		'value': 'Ross walk, Leicester',
		'label': 'Ross walk, Leicester'
	}, {
		'value': 'Canning Town station roundabout',
		'label': 'Canning Town station roundabout'
	}, {
		'value': '32 Steadman Terrace in\nBradford',
		'label': '32 Steadman Terrace in Bradford'
	}, {
		'value': 'Roundabout in Leeds',
		'label': 'Roundabout in Leeds'
	}, {
		'value': 'A452 Newport Road/Collector Road',
		'label': 'A452 Newport Road/Collector Road'
	}, {
		'value': 'White Hart Lane, N22',
		'label': 'White Hart Lane, N22'
	}, {
		'value': 'Crossgates Road, Leeds',
		'label': 'Crossgates Road, Leeds'
	}, {
		'value': 'Queens Drive in Nottingham',
		'label': 'Queens Drive in Nottingham'
	}, {
		'value': 'A638 Dewsbury',
		'label': 'A638 Dewsbury'
	}, {
		'value': 'Killinghall Road',
		'label': 'Killinghall Road'
	}, {
		'value': 'Whitmore Street, Birmingham',
		'label': 'Whitmore Street, Birmingham'
	}, {
		'value': 'A21 carriageway from Stanford to London',
		'label': 'A21 carriageway from Stanford to London'
	}, {
		'value': 'Rock Valley in Mansfield',
		'label': 'Rock Valley in Mansfield'
	}, {
		'value': 'A45, Sheldon, Birmingham',
		'label': 'A45, Sheldon, Birmingham'
	}, {
		'value': 'Warwick Road Birmingham',
		'label': 'Warwick Road Birmingham'
	}, {
		'value': 'Pendle Street, Nelson, \nLancashire',
		'label': 'Pendle Street, Nelson,  Lancashire'
	}, {
		'value': 'Caldmore Green, Walsall',
		'label': 'Caldmore Green, Walsall'
	}, {
		'value': 'Lancing Road, Croydon, London',
		'label': 'Lancing Road, Croydon, London'
	}, {
		'value': 'Normanton Road, Derby',
		'label': 'Normanton Road, Derby'
	}, {
		'value': 'Manchester Road, Ashton-under-Lyne',
		'label': 'Manchester Road, Ashton-under-Lyne'
	}, {
		'value': 'Northmoor Road, Longsight, Manchester',
		'label': 'Northmoor Road, Longsight, Manchester'
	}, {
		'value': 'Belgrave Middleway',
		'label': 'Belgrave Middleway'
	}, {
		'value': 'Tilford Avenue, New Addington, Croydon',
		'label': 'Tilford Avenue, New Addington, Croydon'
	}, {
		'value': 'Mill Hill towards Edgware Way',
		'label': 'Mill Hill towards Edgware Way'
	}, {
		'value': 'Dixon Street/Carlisle Street-Rotherham',
		'label': 'Dixon Street/Carlisle Street-Rotherham'
	}, {
		'value': 'Dickinson Road, Manchester',
		'label': 'Dickinson Road, Manchester'
	}, {
		'value': 'Berslem Street',
		'label': 'Berslem Street'
	}, {
		'value': 'Bridge Street, Bradford',
		'label': 'Bridge Street, Bradford'
	}, {
		'value': 'Sheffield',
		'label': 'Sheffield'
	}, {
		'value': 'Toller Lane, Bradford',
		'label': 'Toller Lane, Bradford'
	}, {
		'value': 'Chorley New Road, Bolton',
		'label': 'Chorley New Road, Bolton'
	}, {
		'value': 'Rea Street, Birmingham',
		'label': 'Rea Street, Birmingham'
	}, {
		'value': 'Station Street',
		'label': 'Station Street'
	}, {
		'value': "Morrison's Car Park in Bolton town centre",
		'label': "Morrison's Car Park in Bolton town centre"
	}, {
		'value': 'Alma Street Blackburn',
		'label': 'Alma Street Blackburn'
	}, {
		'value': 'Hareshill Road, Leeds',
		'label': 'Hareshill Road, Leeds'
	}, {
		'value': 'Chavenage Old Dairy Farm, Gloucester',
		'label': 'Chavenage Old Dairy Farm, Gloucester'
	}, {
		'value': 'Heaton Road in Bradford',
		'label': 'Heaton Road in Bradford'
	}, {
		'value': 'A12, Eastern Avenue, Ilford',
		'label': 'A12, Eastern Avenue, Ilford'
	}, {
		'value': 'Kamac Road/Luxor Road Leeds',
		'label': 'Kamac Road/Luxor Road Leeds'
	}, {
		'value': 'M4 towards London',
		'label': 'M4 towards London'
	}, {
		'value': 'Hyde Park Road',
		'label': 'Hyde Park Road'
	}, {
		'value': 'Moorgate Road/ Alma Road, Rotherham',
		'label': 'Moorgate Road/ Alma Road, Rotherham'
	}, {
		'value': 'Bolton Road, Wigan, WN4',
		'label': 'Bolton Road, Wigan, WN4'
	}, {
		'value': 'Mornington Street Keighley',
		'label': 'Mornington Street Keighley'
	}, {
		'value': 'Guerney Road in Stratford, London',
		'label': 'Guerney Road in Stratford, London'
	}, {
		'value': 'Capehill Road in Smethwick',
		'label': 'Capehill Road in Smethwick'
	}, {
		'value': 'Alderville Road, Fulham',
		'label': 'Alderville Road, Fulham'
	}, {
		'value': 'Rumford Road, Friars Gate',
		'label': 'Rumford Road, Friars Gate'
	}, {
		'value': 'Saint Enochs Road, Bradford',
		'label': 'Saint Enochs Road, Bradford'
	}, {
		'value': 'North Circular Road, London.',
		'label': 'North Circular Road, London.'
	}, {
		'value': 'Holloway Circus Roundabout in Birmingham',
		'label': 'Holloway Circus Roundabout in Birmingham'
	}, {
		'value': 'Waterloo Road/ Bridge Close, Romford',
		'label': 'Waterloo Road/ Bridge Close, Romford'
	}, {
		'value': 'A65, Leeds',
		'label': 'A65, Leeds'
	}, {
		'value': 'Coast Road, A19',
		'label': 'Coast Road, A19'
	}, {
		'value': 'Hertford Road Roundabout',
		'label': 'Hertford Road Roundabout'
	}, {
		'value': 'Junction on Vivian Road',
		'label': 'Junction on Vivian Road'
	}, {
		'value': 'Barkerend Road, Harris Street, A658',
		'label': 'Barkerend Road, Harris Street, A658'
	}, {
		'value': 'Thames \nStreet, Wallingford',
		'label': 'Thames  Street, Wallingford'
	}, {
		'value': 'Finchiey Road, London',
		'label': 'Finchiey Road, London'
	}, {
		'value': 'Gloucester road in London',
		'label': 'Gloucester road in London'
	}, {
		'value': 'Mile End Road, London',
		'label': 'Mile End Road, London'
	}, {
		'value': 'Hungary Hill in Stourbridge',
		'label': 'Hungary Hill in Stourbridge'
	}, {
		'value': 'A6110 \nRing Road in Beeston, Leeds',
		'label': 'A6110  Ring Road in Beeston, Leeds'
	}, {
		'value': 'Telford Queen Elizabeth Avenue A464',
		'label': 'Telford Queen Elizabeth Avenue A464'
	}, {
		'value': 'White Abbey Road in Bradford',
		'label': 'White Abbey Road in Bradford'
	}, {
		'value': 'Empress Avenue Roundabaut off Belgrave Road',
		'label': 'Empress Avenue Roundabaut off Belgrave Road'
	}, {
		'value': 'M61 Dehavilland Way Roundabout in Bolton',
		'label': 'M61 Dehavilland Way Roundabout in Bolton'
	}, {
		'value': 'George Street South in Salford',
		'label': 'George Street South in Salford'
	}, {
		'value': 'A40 into Oxford',
		'label': 'A40 into Oxford'
	}, {
		'value': 'Junction\nRoad, Norton, Stockton on Tees',
		'label': 'Junction Road, Norton, Stockton on Tees'
	}, {
		'value': 'Thurlow Street SE2',
		'label': 'Thurlow Street SE2'
	}, {
		'value': 'Victoria Road in London, N9',
		'label': 'Victoria Road in London, N9'
	}, {
		'value': 'Wakefield Road/Silver Street, Huddersfield',
		'label': 'Wakefield Road/Silver Street, Huddersfield'
	}, {
		'value': 'Viaduct Road in Leeds',
		'label': 'Viaduct Road in Leeds'
	}, {
		'value': 'Farley Hill, Luton',
		'label': 'Farley Hill, Luton'
	}, {
		'value': 'Wakerly Road',
		'label': 'Wakerly Road'
	}, {
		'value': 'Savile Road, Dewsbury',
		'label': 'Savile Road, Dewsbury'
	}, {
		'value': 'A527',
		'label': 'A527'
	}, {
		'value': 'Barking Road \nroundabout in East London.',
		'label': 'Barking Road  roundabout in East London.'
	}, {
		'value': 'Montgomery Road, Sheffield',
		'label': 'Montgomery Road, Sheffield'
	}, {
		'value': 'Elm Road',
		'label': 'Elm Road'
	}, {
		'value': 'Kilburn Lane, London',
		'label': 'Kilburn Lane, London'
	}, {
		'value': 'Barnsley Road, Sheffield',
		'label': 'Barnsley Road, Sheffield'
	}, {
		'value': 'Bankall St Junc, Stanley Road',
		'label': 'Bankall St Junc, Stanley Road'
	}, {
		'value': 'Woodford Road, London',
		'label': 'Woodford Road, London'
	}, {
		'value': 'Leeside Crescent, London',
		'label': 'Leeside Crescent, London'
	}, {
		'value': 'Watford way /Stirling Corner roundabout, London',
		'label': 'Watford way /Stirling Corner roundabout, London'
	}, {
		'value': "Morrison's Car\nPark, Hartfliffe, Bristol",
		'label': "Morrison's Car Park, Hartfliffe, Bristol"
	}, {
		'value': 'Birchfields Road, Manchester',
		'label': 'Birchfields Road, Manchester'
	}, {
		'value': 'Broom Lane, Manchester',
		'label': 'Broom Lane, Manchester'
	}, {
		'value': 'Forest\nHill, Stanstead Road, London',
		'label': 'Forest Hill, Stanstead Road, London'
	}, {
		'value': 'Glebe Street, Marsh, Huddersfield',
		'label': 'Glebe Street, Marsh, Huddersfield'
	}, {
		'value': 'Heights Lane, Rochdale.',
		'label': 'Heights Lane, Rochdale.'
	}, {
		'value': 'Longford Road, CV7',
		'label': 'Longford Road, CV7'
	}, {
		'value': 'A206',
		'label': 'A206'
	}, {
		'value': 'Allerton Road, Bradford',
		'label': 'Allerton Road, Bradford'
	}, {
		'value': 'H6 Childs Way, Milton Keynes',
		'label': 'H6 Childs Way, Milton Keynes'
	}, {
		'value': 'Albert Road in Bolton',
		'label': 'Albert Road in Bolton'
	}, {
		'value': 'Evershot Road, N4, London',
		'label': 'Evershot Road, N4, London'
	}, {
		'value': 'Stepson Way',
		'label': 'Stepson Way'
	}, {
		'value': '121 Kingsway, Luton',
		'label': '121 Kingsway, Luton'
	}, {
		'value': 'M54 turnoff for Cosford WV17',
		'label': 'M54 turnoff for Cosford WV17'
	}, {
		'value': 'Renfrew Close, Stourbridge',
		'label': 'Renfrew Close, Stourbridge'
	}
]

const locationReduced = [
	{
		'value': 'Aylestone Road, Leicester',
		'label': 'Aylestone Road, Leicester'
	}, {
		'value': 'Bank Bottom, Halifax',
		'label': 'Bank Bottom, Halifax'
	}, {
		'value': 'Brampton Road, Wellings',
		'label': 'Brampton Road, Wellings'
	}, {
		'value': 'Bridge Street, Swinton',
		'label': 'Bridge Street, Swinton'
	}, {
		'value': 'Chalkwell Road, Sittingbourne',
		'label': 'Chalkwell Road, Sittingbourne'
	}, {
		'value': 'Clannell Road, Northampton',
		'label': 'Clannell Road, Northampton'
	}, {
		'value': 'Crowthorne Road, Birknell',
		'label': 'Crowthorne Road, Birknell'
	}, {
		'value': 'Hartley Road, Nottingham',
		'label': 'Hartley Road, Nottingham'
	}, {
		'value': 'Hollinwood, Whixall',
		'label': 'Hollinwood, Whixall'
	}, {
		'value': 'Hyde Park Road, Leeds',
		'label': 'Hyde Park Road, Leeds'
	}, {
		'value': 'Jenkins Street, Birmingham',
		'label': 'Jenkins Street, Birmingham'
	}, {
		'value': 'Ley Street, Ilford',
		'label': 'Ley Street, Ilford'
	}, {
		'value': 'Newark Road, Lincoln',
		'label': 'Newark Road, Lincoln'
	}, {
		'value': 'Old Costessey High Street, Norwich',
		'label': 'Old Costessey High Street, Norwich'
	}, {
		'value': 'Reading Road,  Berkshire',
		'label': 'Reading Road,  Berkshire'
	}, {
		'value': 'Somerset Way, Warrington',
		'label': 'Somerset Way, Warrington'
	}, {
		'value': 'Southfield Lane, Bradford',
		'label': 'Southfield Lane, Bradford'
	}, {
		'value': 'Tiltshills Lane, Doncaster',
		'label': 'Tiltshills Lane, Doncaster'
	}, {
		'value': 'Wallington Avenue, Reading',
		'label': 'Wallington Avenue, Reading'
	}, {
		'value': 'Whalley New Road, Blackburn',
		'label': 'Whalley New Road, Blackburn'
	}
];

const locationPartOfStreet = [
	{
		'value': 'Junction',
		'label': 'Junction'
	}, {
		'value': 'Roundabout',
		'label': 'Roundabout'
	}, {
  		'value': 'Residential Road',
		'label': 'Residential Road'
	}, {
  		'value': 'Main (A) Road',
		'label': 'Main (A) Road'
	}, {
  		'value': 'Motorway (M)',
		'label': 'Motorway (M)'
	}

]

export {
	location,
	locationReduced,
    locationPartOfStreet
};
