import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const StyledMenu = withStyles({
	root: {
		// background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
		// borderRadius: 3,
		// border: 0,
		color: 'white',
		// padding: '0 30px',
		// boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
	}
})(IconButton);

function BarTool({handleDrawerOpen}) {
	const StyleLogo = {
	// width: '35%',
	height: '20px',
	marginLeft: '10%',
	paddingLeft: '8px',
	marginRight: '20',
	display: 'block',
	paddingTop: '23px'
	}
	const titleStyle1 = {
	fontWeight: 500,
	fontFamily: 'Arial',
	fontSize: 28
	}

	const datalabStyle = {
		fontFamily: 'Arial',
		fontSize: 24,
		fontWeight: 600,
		color: '#E87722'
	}

	const dataIcon = {
		width: '75px',
		// height: '10%',
		marginLeft: 'auto',
		marginRight: '10px',
		display: 'block',
		paddingTop: '0px'
	}
	const v1 ={
		borderLeft: '2px solid white',
		height: '30px',
		marginTop: '3%',
		marginRight: '1.5%',
		marginLeft: '3%'
	}

	return(
		<div style={{marginTop:0, paddingTop: 0}}>
			<div style={{display: 'flex', backgroundColor: '#00677F', height:'80px'}}>
				<div style={{display: 'flex', width: '60%', marginLeft: '9%'}}>
					<div style={{width: '15%', paddingTop: '15px'}}>
						<StyledMenu
						  color="inherit"
						  aria-label="Open drawer"
						  onClick={handleDrawerOpen}
						>
						  <MenuIcon />
						</StyledMenu>
					</div>
					<div style={{ width: '15%'}}>
						<div>
							<img src='./datalab_icon.png' style={dataIcon} alt='Logo'/>
						</div>
					</div>
					<div style={{color: 'white', width: '60%'}}>
						<h1 style={titleStyle1}>Data Lab Projects</h1>
					</div>
				</div>
				<div style={{display: 'flex', width: '40%', marginLeft: '0%', textAlign:'left'}}>
					<div style={{width: '120px', marginRight: '0%', paddingRight:'0%', marginLeft: '0%', paddingLeft: '0%'}}>
						<img src='./logo1.png' style={StyleLogo} alt='Logo'/>
					</div>
					<div style={v1}></div>
					<div>
						<h2 style={datalabStyle}>datalab</h2>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BarTool
