import React from "react"
import TextField from "@material-ui/core/TextField"

function FormComponentNotification(props) {

	const a2 = "When was the insurer notified of the claim?"
	return (
		<div>
			<form>
              <h3 className='projects-question'>{a2}</h3>
              <br/>
              <TextField
                  id="date"
                  label="Date"
                  type="date"
                  fullWidth
                  onChange={props.handleChange}
                  value={props.data.incident}
                  name="incident"
                  InputLabelProps={{
                    shrink: true
                  }}
              />
              <br/>
			</form>
		</div>
	)
}

export default FormComponentNotification
