import React from 'react'
import TextField from "@material-ui/core/TextField"
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
//import IntegrationAutosuggest from './SearchOption'
import {partnerName, neighbours} from './findPartners'

const styles ={
  card:{
    minHeight: 140
  },
  title:{
    fontSize: 16,
    textAlign: 'left',
    marginLeft: '5%'
  },
  subtitle:{
    fontSize: 14,
    textAlign: 'left',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: '5%',
    width: 150
  },
  textField: {
    //width: 400,
    textAlign: 'left',
    //marginLeft: '5%'
  },
  menu:{
    //width: 400,
    //marginLeft: '5%',
    //marginRight: 'auto',
  }
}

function FormComponentSecondFind(props){

  const question = {
    color:'#484241',
    textAlign: 'left',
    fontFamily: "Caecilia",
    fontWeight: 300,
    fontSize: 20,
    lineHeight: 1.3,
    paddingBottom: '1%',
  }

  const {classes} = props

  return(
    <div>
      <div className='projects-container-text'>
        <br/>
        <h3 className='projects-question'>Partner: </h3>
        <TextField
          id = "partnerName"
          select
          value = {props.data.partnerName}
          className = {classes.textField}
          onChange = {props.handleChange('partnerName')}
          SelectProps = {{
            MenuProps: {
              className: classes.menu,
            },
          }}
          margin = "normal"
          fullWidth
        >
          {partnerName.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <br />
        <br />
        <h3 className='projects-question'>Number of Neighbours: </h3>
        <TextField
          id='neighbours'
          select
          value = {props.data.neighbours}
          className = {classes.textField}
          onChange = {props.handleChange('neighbours')}
          SelectProps = {{
            MenuProps: {
              className: classes.menu,
            },
          }}
          margin = "normal"
          fullWidth
        >
          {neighbours.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  )
}

export default withStyles(styles)(FormComponentSecondFind)
