import React from "react"
import FormComponentFirst from "./FormComponentFirst"
import FormComponentSecond from "./FormComponentSecond"
import FormComponentThird from "./FormComponentThird"
import FormComponentFourth from "./FormComponentFourth"

class GetStepContentComponent extends React.Component{

  constructor(props){
    super(props)
  }

  render(){
    const stepIndex = this.props.step
    console.log(stepIndex)
    //const value1 = "test_value"
    //const id1 = "id1"
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <FormComponentFirst
            />
          </div>
        );
      case 1:
        return (
          <div>
            <FormComponentSecond
              handleChange={this.props.handleChange}
              handleChangeText={this.props.handleChangeText}
              data={this.props.data}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FormComponentThird
              handleChange={this.props.handleChange}
              data={this.props.data}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <FormComponentFourth
              handleChange={this.props.handleChange}
              data={this.props.data}
            />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  }
}

export default GetStepContentComponent
