import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const StyledButton = withStyles({
	contained: {
		// background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
		// borderRadius: 3,
		// border: 0,
		backgroundColor: '#00677F',
		// padding: '0 30px',
		// boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		"&:hover": {
        //you want this to be the same as the backgroundColor above
        backgroundColor: 'rgba(0, 103, 127, 0.7)'
    }
	},
	focusVisible: {
		backgroundColor: '#00677F',
	}
	// focusVisible: {
	// 	backgroundColor: 'white',
	// }
})(Button);

export default StyledButton