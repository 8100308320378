import React from 'react'
import TextCards from './TextCards'

function FormComponentThirdExtraction(props){

  const notext = 'Please fill the fields.'
  const nodata = 'No sensitive information was found.'

  const {email, date, currency, cities, name} = props.data.entitiesText

  return(
    <div>
      { props.data.entitiesText === 'None' ?
      <p className='projects-error'>{notext}</p> :
      (email.length === 0 && date.length === 0 && currency.length === 0 &&
        cities.length === 0 && name.length === 0) ?
      <p className='projects-question'>{nodata}</p> :
      <div className="databreach-container-text">
        <br/>
          <TextCards
            email = {props.data.entitiesText.email}
            date = {props.data.entitiesText.date}
            currency = {props.data.entitiesText.currency}
            cities = {props.data.entitiesText.cities}
            name = {props.data.entitiesText.name}
          />
      </div>
      }
      <br/>
    </div>
  )
}

export default FormComponentThirdExtraction
