import React from "react"
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel';

function FormComponentPrecedent(props) {

	const a1 = "Is there a term of the policy that makes compliance with claims notification a condition precedent to liability?"

	return (
		<div>
			<form>
				<h3 className='projects-question'>{a1}</h3>
				<br/>
				<RadioGroup
					aria-label="Please answer"
					name="isPrecedent"
					value={props.data.isPrecedent}
					onChange={props.handleChange}
				>
					<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes"/>
					<FormControlLabel value="no" control={<Radio color="primary"/>} label="No"/>
				</RadioGroup>
				<br/>
			</form>
		</div>
	)
}

export default FormComponentPrecedent
