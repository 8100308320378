import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import ButtonAppBar from './ButtonAppBar'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import Search from '@material-ui/icons/Search';
import People from '@material-ui/icons/People';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import Description from '@material-ui/icons/Description';
import {Link} from 'react-router-dom'
import BarTool from "../BarTool";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  logo:{
    width: '9%',
    height: '9%',
    minWidth: 147.622,
    minHeight: 26.823,
    marginLeft: '38%',
  },
  title:{
    verticalAlign: 'middle',
  },
  maintitle:{
    display: 'flex',
  },
  grow: {
    flexGrow: 2,
  },
});

class PersistentDrawerLeftFraud extends React.Component {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  /*
  <AppBar
    position="fixed"
    className={classNames(classes.appBar)}
  >
    <Toolbar disableGutters={!open}>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        onClick={this.handleDrawerOpen}
        className={classNames(classes.menuButton, open && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" color="inherit" noWrap>
        Persistent drawer
      </Typography>
    </Toolbar>
  </AppBar>
  */

  render() {
    const { classes, theme } = this.props;
    const { open } = this.state;

    return (
      <div>
        {/*<CssBaseline />*/}
        {/*<AppBar*/}
        {/*  position="fixed"*/}
        {/*  className={classNames(classes.appBar)}*/}
        {/*>*/}
        {/*  <Toolbar >*/}
        {/*    <IconButton*/}
        {/*      color="inherit"*/}
        {/*      aria-label="Open drawer"*/}
        {/*      onClick={this.handleDrawerOpen}*/}
        {/*      className={classNames(classes.menuButton, open )}*/}
        {/*    >*/}
        {/*      <MenuIcon />*/}
        {/*    </IconButton>*/}
        {/*    <Typography variant="h6" color="inherit" noWrap className={classes.grow}>*/}
        {/*      <div className={classes.maintitle}>*/}
        {/*        <div className={classes.title}>*/}
        {/*          Fraud*/}
        {/*        </div>*/}
        {/*        <img src='./logo1.png' className={classes.logo} alt='Logo'/>*/}
        {/*      </div>*/}
        {/*    </Typography>*/}
        {/*    <Button color="inherit">*/}
        {/*      <Avatar>GB</Avatar>*/}
        {/*    </Button>*/}
        {/*  </Toolbar>*/}
        {/*</AppBar>*/}
        <BarTool
			handleDrawerOpen={this.handleDrawerOpen}
		/>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {['Home', 'Directions','Data Breach', 'Reserving', 'Coverage', 'Find'].map((text, index) => (
              <ListItem button key={text} component={Link} to={"/"+text.toLowerCase().replace(/\s/g, "")}>
                <ListItemIcon>
                  {index === 0 ? <Home />
                  : index === 1 ? <Search />
                  : index === 2 ? <Description />
                  : index === 3 ? <MonetizationOn />
                  : index === 4 ? <Settings />
                  : index === 5 ? <People />
                  : null}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </div>
    );
  }
}

PersistentDrawerLeftFraud.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PersistentDrawerLeftFraud);
