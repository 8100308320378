import React from 'react'
import '../App.css'
import {contact1, contact2} from '../descriptions'

function ContactUs(){
	return(
		<div className="contact-us">
			<div className="home-about-text">
				<h1>Contact us</h1>
				<h2>{contact1}</h2>
				<h2>{contact2}</h2>
			</div>
		</div>

	)
}

export default ContactUs