import React from 'react'
import HorizontalLabelPositionBelowStepperExtraction from './HorizontalStepperExtraction'

const StepperCustom = {
  textAlign: 'center',
  marginTop: "4%"
}

const Extraction = () => (
  <div>
    <br />
    <div style={StepperCustom}>
      <HorizontalLabelPositionBelowStepperExtraction />
    </div>
  </div>
)

export default Extraction
