const claimantSolicitors = [
  {
    'value': 'Russell Worth',
    'label': 'Russell Worth'
  }, {
    'value': 'Resolution Law',
    'label': 'Resolution Law'
  }, {
    'value': 'Minster Law',
    'label': 'Minster Law'
  }, {
    'value': 'Sintons LLP',
    'label': 'Sintons LLP'
  }, {
    'value': 'Trade Union Legal LLP',
    'label': 'Trade Union Legal LLP'
  }, {
    'value': 'True Personal Injury Solicitors',
    'label': 'True Personal Injury Solicitors'
  }, {
    'value': 'Emsleys',
    'label': 'Emsleys'
  }, {
    'value': 'Thompsons',
    'label': 'Thompsons'
  }, {
    'value': 'Fentons',
    'label': 'Fentons'
  }, {
    'value': 'Slater & Gordon (UK) LLP',
    'label': 'Slater & Gordon (UK) LLP'
  }, {
    'value': 'Simpson Millar LLP',
    'label': 'Simpson Millar LLP'
  }, {
    'value': 'Express Solicitors',
    'label': 'Express Solicitors'
  }, {
    'value': 'Principia Law',
    'label': 'Principia Law'
  }, {
    'value': 'Stone Rowe Brewer',
    'label': 'Stone Rowe Brewer'
  }, {
    'value': 'Taylor & Emmet',
    'label': 'Taylor & Emmet'
  }, {
    'value': '2020 Legal Limited',
    'label': '2020 Legal Limited'
  }, {
    'value': 'Litigant in Person',
    'label': 'Litigant in Person'
  }, {
    'value': 'Watkins & Gunn',
    'label': 'Watkins & Gunn'
  }, {
    'value': 'Lester Aldridge LLP',
    'label': 'Lester Aldridge LLP'
  }, {
    'value': 'Warner Goodman LLP',
    'label': 'Warner Goodman LLP'
  }, {
    'value': 'NUT Solicitors',
    'label': 'NUT Solicitors'
  }, {
    'value': 'Cordell & Company',
    'label': 'Cordell & Company'
  }, {
    'value': 'Irwin Mitchell LLP',
    'label': 'Irwin Mitchell LLP'
  }, {
    'value': 'Active Legal',
    'label': 'Active Legal'
  }, {
    'value': 'Bartletts',
    'label': 'Bartletts'
  }, {
    'value': 'sovereign Solicitors',
    'label': 'sovereign Solicitors'
  }, {
    'value': 'Carter & Carter',
    'label': 'Carter & Carter'
  }, {
    'value': 'Best Solicitors',
    'label': 'Best Solicitors'
  }, {
    'value': 'DLG Legal Services',
    'label': 'DLG Legal Services'
  }, {
    'value': 'SGI Legal LLP',
    'label': 'SGI Legal LLP'
  }, {
    'value': 'Tinsdills Solicitors',
    'label': 'Tinsdills Solicitors'
  }, {
    'value': 'O H Parsons & Partners',
    'label': 'O H Parsons & Partners'
  }, {
    'value': 'Not yet known',
    'label': 'Not yet known'
  }, {
    'value': 'Lyons Davidson',
    'label': 'Lyons Davidson'
  }, {
    'value': 'Bakers',
    'label': 'Bakers'
  }, {
    'value': 'Michael W Halsall',
    'label': 'Michael W Halsall'
  }, {
    'value': 'Amelans',
    'label': 'Amelans'
  }, {
    'value': 'Horwich Farrelly',
    'label': 'Horwich Farrelly'
  }, {
    'value': 'Attwaters Jameson Hill',
    'label': 'Attwaters Jameson Hill'
  }, {
    'value': 'Dorians',
    'label': 'Dorians'
  }, {
    'value': 'Carpenters',
    'label': 'Carpenters'
  }, {
    'value': 'Graysons',
    'label': 'Graysons'
  }, {
    'value': 'Macks',
    'label': 'Macks'
  }, {
    'value': 'Thompson Smith & Puxon',
    'label': 'Thompson Smith & Puxon'
  }, {
    'value': 'Forster Dean LLP',
    'label': 'Forster Dean LLP'
  }, {
    'value': 'Amanda Cunliffe Solicitors',
    'label': 'Amanda Cunliffe Solicitors'
  }, {
    'value': 'Craig Gee & Co Solicitors',
    'label': 'Craig Gee & Co Solicitors'
  }, {
    'value': 'Clear Law',
    'label': 'Clear Law'
  }, {
    'value': 'Clarke Barnes',
    'label': 'Clarke Barnes'
  }, {
    'value': 'Aegis Legal',
    'label': 'Aegis Legal'
  }, {
    'value': 'NewLaw Solicitors LLP',
    'label': 'NewLaw Solicitors LLP'
  }, {
    'value': 'North Solicitors',
    'label': 'North Solicitors'
  }, {
    'value': 'Fosters',
    'label': 'Fosters'
  }, {
    'value': 'Powell Spencer & Partners',
    'label': 'Powell Spencer & Partners'
  }, {
    'value': 'Hodge Jones & Allen',
    'label': 'Hodge Jones & Allen'
  }, {
    'value': 'Romain Coleman',
    'label': 'Romain Coleman'
  }, {
    'value': 'Camps Solicitors',
    'label': 'Camps Solicitors'
  }, {
    'value': 'Lamb Brooks',
    'label': 'Lamb Brooks'
  }, {
    'value': 'K H Injury Lawyers',
    'label': 'K H Injury Lawyers'
  }, {
    'value': 'Brighouse Wolff',
    'label': 'Brighouse Wolff'
  }, {
    'value': 'Aequitas Legal Solutions Limited',
    'label': 'Aequitas Legal Solutions Limited'
  }, {
    'value': 'Khakhar & Co',
    'label': 'Khakhar & Co'
  }, {
    'value': 'LPS Solicitors',
    'label': 'LPS Solicitors'
  }, {
    'value': 'Scott Rees & Co',
    'label': 'Scott Rees & Co'
  }, {
    'value': 'Ralli',
    'label': 'Ralli'
  }, {
    'value': 'Union Line',
    'label': 'Union Line'
  }, {
    'value': 'Ashton KCJ',
    'label': 'Ashton KCJ'
  }, {
    'value': 'Real Law Solicitors',
    'label': 'Real Law Solicitors'
  }, {
    'value': 'Wheelers',
    'label': 'Wheelers'
  }, {
    'value': 'Heptonstalls LLP',
    'label': 'Heptonstalls LLP'
  }, {
    'value': 'Hampson Hughes',
    'label': 'Hampson Hughes'
  }, {
    'value': 'Barker Austin',
    'label': 'Barker Austin'
  }, {
    'value': 'Armstrongs',
    'label': 'Armstrongs'
  }, {
    'value': 'Rollingsons',
    'label': 'Rollingsons'
  }, {
    'value': 'Silverbeck Rymer',
    'label': 'Silverbeck Rymer'
  }, {
    'value': 'Ime Law Ltd',
    'label': 'Ime Law Ltd'
  }, {
    'value': 'Sharp Young and Pearce LLP',
    'label': 'Sharp Young and Pearce LLP'
  }, {
    'value': 'Shakespeare Martineau LLP',
    'label': 'Shakespeare Martineau LLP'
  }, {
    'value': 'Spicketts Battrick',
    'label': 'Spicketts Battrick'
  }, {
    'value': 'Gordon Dean Solicitors',
    'label': 'Gordon Dean Solicitors'
  }, {
    'value': 'Harding Evans LLP',
    'label': 'Harding Evans LLP'
  }, {
    'value': 'Lupton Fawcett',
    'label': 'Lupton Fawcett'
  }, {
    'value': 'Access Legal',
    'label': 'Access Legal'
  }, {
    'value': 'Roberts Jackson Solicitors',
    'label': 'Roberts Jackson Solicitors'
  }, {
    'value': 'Blake Morgan Solicitors',
    'label': 'Blake Morgan Solicitors'
  }, {
    'value': 'Howard Kennedy',
    'label': 'Howard Kennedy'
  }, {
    'value': 'Michael Lewin',
    'label': 'Michael Lewin'
  }, {
    'value': 'Sills & Betteridge',
    'label': 'Sills & Betteridge'
  }, {
    'value': 'Horwich Cohen Coghlan',
    'label': 'Horwich Cohen Coghlan'
  }, {
    'value': 'Jigsaw Law',
    'label': 'Jigsaw Law'
  }, {
    'value': 'Tollers LLP',
    'label': 'Tollers LLP'
  }, {
    'value': 'Pattinson & Brewer',
    'label': 'Pattinson & Brewer'
  }, {
    'value': 'Quality Solicitors',
    'label': 'Quality Solicitors'
  }, {
    'value': 'Weightmans',
    'label': 'Weightmans'
  }, {
    'value': 'Russell & Russell',
    'label': 'Russell & Russell'
  }, {
    'value': 'Grindeys',
    'label': 'Grindeys'
  }, {
    'value': 'JMW Solicitors',
    'label': 'JMW Solicitors'
  }, {
    'value': 'David Stinson & Co',
    'label': 'David Stinson & Co'
  }, {
    'value': 'Your Lawyers',
    'label': 'Your Lawyers'
  }, {
    'value': 'Winn Solicitors',
    'label': 'Winn Solicitors'
  }, {
    'value': 'Stephensons Solicitors LLP',
    'label': 'Stephensons Solicitors LLP'
  }, {
    'value': 'Maxwell Hodge',
    'label': 'Maxwell Hodge'
  }, {
    'value': 'Rubicon Law',
    'label': 'Rubicon Law'
  }, {
    'value': 'Claim Today Solicitors',
    'label': 'Claim Today Solicitors'
  }, {
    'value': 'Prince Evans',
    'label': 'Prince Evans'
  }, {
    'value': 'Reynards Solicitors',
    'label': 'Reynards Solicitors'
  }, {
    'value': 'Furley Page LLP',
    'label': 'Furley Page LLP'
  }, {
    'value': 'CFG Law',
    'label': 'CFG Law'
  }, {
    'value': 'Pabla & Pabla',
    'label': 'Pabla & Pabla'
  }, {
    'value': 'Ross Aldridge',
    'label': 'Ross Aldridge'
  }, {
    'value': 'Canter Levin & Berg',
    'label': 'Canter Levin & Berg'
  }, {
    'value': 'The Roland Partnership',
    'label': 'The Roland Partnership'
  }, {
    'value': 'Harris Fowler',
    'label': 'Harris Fowler'
  }, {
    'value': 'Levenes',
    'label': 'Levenes'
  }, {
    'value': '1 Law Solicitors',
    'label': '1 Law Solicitors'
  }, {
    'value': 'Gavins',
    'label': 'Gavins'
  }, {
    'value': 'Mullis & Peake',
    'label': 'Mullis & Peake'
  }, {
    'value': 'Coles Miller',
    'label': 'Coles Miller'
  }, {
    'value': 'Graham Coffey & Co',
    'label': 'Graham Coffey & Co'
  }, {
    'value': 'Hansells',
    'label': 'Hansells'
  }, {
    'value': 'EAD Solicitors',
    'label': 'EAD Solicitors'
  }, {
    'value': 'Cleaver Thompson',
    'label': 'Cleaver Thompson'
  }, {
    'value': 'Mergul Law',
    'label': 'Mergul Law'
  }, {
    'value': 'Keeble Hawson',
    'label': 'Keeble Hawson'
  }, {
    'value': 'Quindell Legal Services',
    'label': 'Quindell Legal Services'
  }, {
    'value': 'Ashwoods Solicitors',
    'label': 'Ashwoods Solicitors'
  }, {
    'value': 'GLP Solicitors',
    'label': 'GLP Solicitors'
  }, {
    'value': 'John Fowlers LLP',
    'label': 'John Fowlers LLP'
  }, {
    'value': 'Paul Rooney Partnership',
    'label': 'Paul Rooney Partnership'
  }, {
    'value': 'McGrath Litigation Partnership',
    'label': 'McGrath Litigation Partnership'
  }, {
    'value': 'Morrish & Co',
    'label': 'Morrish & Co'
  }, {
    'value': 'Hudgell Solicitors',
    'label': 'Hudgell Solicitors'
  }, {
    'value': 'DAC Beachcroft LLP',
    'label': 'DAC Beachcroft LLP'
  }, {
    'value': 'Hockfield & Co',
    'label': 'Hockfield & Co'
  }, {
    'value': 'Brachers',
    'label': 'Brachers'
  }, {
    'value': 'Paul Crowley & Co',
    'label': 'Paul Crowley & Co'
  }, {
    'value': 'HLW Keeble Hawson',
    'label': 'HLW Keeble Hawson'
  }, {
    'value': 'Gorvins',
    'label': 'Gorvins'
  }, {
    'value': 'Lampkin & Co',
    'label': 'Lampkin & Co'
  }, {
    'value': 'Wimbeldon Solicitors',
    'label': 'Wimbeldon Solicitors'
  }, {
    'value': 'Irvings',
    'label': 'Irvings'
  }, {
    'value': 'Kennedys',
    'label': 'Kennedys'
  }, {
    'value': 'James Murray',
    'label': 'James Murray'
  }, {
    'value': 'Ward & Rider',
    'label': 'Ward & Rider'
  }, {
    'value': 'Ormerods',
    'label': 'Ormerods'
  }, {
    'value': 'Freeclaim Solicitors',
    'label': 'Freeclaim Solicitors'
  }, {
    'value': 'IBB Solicitors',
    'label': 'IBB Solicitors'
  }, {
    'value': 'Maddocks Clarke',
    'label': 'Maddocks Clarke'
  }, {
    'value': 'Walkers Union',
    'label': 'Walkers Union'
  }, {
    'value': 'BTMK Solicitors LLP',
    'label': 'BTMK Solicitors LLP'
  }, {
    'value': 'Zest Legal',
    'label': 'Zest Legal'
  }, {
    'value': 'Ward Hadaway',
    'label': 'Ward Hadaway'
  }, {
    'value': 'Kinas Solicitors',
    'label': 'Kinas Solicitors'
  }, {
    'value': 'Gotelee',
    'label': 'Gotelee'
  }, {
    'value': 'Simpsons',
    'label': 'Simpsons'
  }, {
    'value': 'Martin Kaye',
    'label': 'Martin Kaye'
  }, {
    'value': 'Smith Jones',
    'label': 'Smith Jones'
  }, {
    'value': 'Marsons',
    'label': 'Marsons'
  }, {
    'value': 'Asons Solicitors',
    'label': 'Asons Solicitors'
  }, {
    'value': 'Goldsmith Williams',
    'label': 'Goldsmith Williams'
  }, {
    'value': 'Holmes & Hills',
    'label': 'Holmes & Hills'
  }, {
    'value': 'Ardent Law',
    'label': 'Ardent Law'
  }, {
    'value': 'FBC Solicitors',
    'label': 'FBC Solicitors'
  }, {
    'value': 'Rushton Hinchy',
    'label': 'Rushton Hinchy'
  }, {
    'value': 'Mooney Everett',
    'label': 'Mooney Everett'
  }, {
    'value': 'Tait Farrier Graham',
    'label': 'Tait Farrier Graham'
  }, {
    'value': 'Nesbit Law Group Ltd',
    'label': 'Nesbit Law Group Ltd'
  }, {
    'value': 'Freeman Harris Solicitors',
    'label': 'Freeman Harris Solicitors'
  }, {
    'value': 'Eversheds',
    'label': 'Eversheds'
  }, {
    'value': 'Marlow Braide',
    'label': 'Marlow Braide'
  }, {
    'value': 'MD Litigation',
    'label': 'MD Litigation'
  }, {
    'value': 'Handley Law Limited',
    'label': 'Handley Law Limited'
  }, {
    'value': 'P G Solicitors',
    'label': 'P G Solicitors'
  }, {
    'value': 'Roythornes LLP',
    'label': 'Roythornes LLP'
  }, {
    'value': 'Hill Dickinson',
    'label': 'Hill Dickinson'
  }, {
    'value': 'Beacon Law',
    'label': 'Beacon Law'
  }, {
    'value': 'Lyons Solicitors',
    'label': 'Lyons Solicitors'
  }, {
    'value': 'Linder Myers',
    'label': 'Linder Myers'
  }, {
    'value': 'Anthony Gold',
    'label': 'Anthony Gold'
  }, {
    'value': 'Setfords Solicitors',
    'label': 'Setfords Solicitors'
  }, {
    'value': 'Dunne & Gray',
    'label': 'Dunne & Gray'
  }, {
    'value': 'Gordons',
    'label': 'Gordons'
  }, {
    'value': 'GT Law',
    'label': 'GT Law'
  }, {
    'value': 'MRH Solicitors',
    'label': 'MRH Solicitors'
  }, {
    'value': 'Chris Kallis Solicitors',
    'label': 'Chris Kallis Solicitors'
  }, {
    'value': 'Owen White Catlin',
    'label': 'Owen White Catlin'
  }, {
    'value': 'Solomon Solicitors',
    'label': 'Solomon Solicitors'
  }, {
    'value': '2020 Legal',
    'label': '2020 Legal'
  }, {
    'value': 'John Ford Solicitors',
    'label': 'John Ford Solicitors'
  }, {
    'value': 'Plexus Law',
    'label': 'Plexus Law'
  }, {
    'value': 'Napthens',
    'label': 'Napthens'
  }, {
    'value': 'Rogers & Norton',
    'label': 'Rogers & Norton'
  }, {
    'value': 'Charles Russell',
    'label': 'Charles Russell'
  }, {
    'value': 'Fletchers',
    'label': 'Fletchers'
  }, {
    'value': 'Andrew & Andrew LLP',
    'label': 'Andrew & Andrew LLP'
  }, {
    'value': 'Scott Duff & Co',
    'label': 'Scott Duff & Co'
  }, {
    'value': 'DAS Law',
    'label': 'DAS Law'
  }, {
    'value': 'Pollards',
    'label': 'Pollards'
  }, {
    'value': 'Lamb Law',
    'label': 'Lamb Law'
  }, {
    'value': 'Proddow Mackay',
    'label': 'Proddow Mackay'
  }, {
    'value': 'Mellor Hargreaves',
    'label': 'Mellor Hargreaves'
  }, {
    'value': 'RundleWalker',
    'label': 'RundleWalker'
  }, {
    'value': 'Cordner Lewis',
    'label': 'Cordner Lewis'
  }, {
    'value': 'BGR Bloomer',
    'label': 'BGR Bloomer'
  }, {
    'value': 'Kidd Rapinet',
    'label': 'Kidd Rapinet'
  }, {
    'value': 'Universa Law',
    'label': 'Universa Law'
  }, {
    'value': 'Wilkin Chapman Solicitors',
    'label': 'Wilkin Chapman Solicitors'
  }, {
    'value': 'Browell Smith & Co',
    'label': 'Browell Smith & Co'
  }, {
    'value': 'Fish & Fish Solicitors',
    'label': 'Fish & Fish Solicitors'
  }, {
    'value': 'Kilic & Kilic Solicitors',
    'label': 'Kilic & Kilic Solicitors'
  }, {
    'value': 'New Law Solicitors',
    'label': 'New Law Solicitors'
  }, {
    'value': 'Leigh Day & Co',
    'label': 'Leigh Day & Co'
  }, {
    'value': 'Cohen Cramer',
    'label': 'Cohen Cramer'
  }, {
    'value': 'Green Wright Chalton Annis',
    'label': 'Green Wright Chalton Annis'
  }, {
    'value': 'Curzon Green',
    'label': 'Curzon Green'
  }, {
    'value': 'Simpsons Sissons & Brooke',
    'label': 'Simpsons Sissons & Brooke'
  }, {
    'value': 'Duncan Lewis & Co',
    'label': 'Duncan Lewis & Co'
  }, {
    'value': 'Neil Hudgell',
    'label': 'Neil Hudgell'
  }, {
    'value': 'Michael Barker Solicitors',
    'label': 'Michael Barker Solicitors'
  }, {
    'value': 'Moore & Blatch',
    'label': 'Moore & Blatch'
  }, {
    'value': 'SEN Legal',
    'label': 'SEN Legal'
  }, {
    'value': 'Clearwater Solicitors',
    'label': 'Clearwater Solicitors'
  }, {
    'value': 'Thompson & Co',
    'label': 'Thompson & Co'
  }, {
    'value': 'A F Brooks & Company',
    'label': 'A F Brooks & Company'
  }, {
    'value': 'Timms',
    'label': 'Timms'
  }, {
    'value': 'Baker Booth & Eastwood Limited',
    'label': 'Baker Booth & Eastwood Limited'
  }, {
    'value': 'Imran Khan and Partners',
    'label': 'Imran Khan and Partners'
  }, {
    'value': 'Auto Claims UK Ltd',
    'label': 'Auto Claims UK Ltd'
  }, {
    'value': 'Comer Crawley',
    'label': 'Comer Crawley'
  }, {
    'value': 'Nexus Solicitors',
    'label': 'Nexus Solicitors'
  }, {
    'value': 'JC & A Solicitors',
    'label': 'JC & A Solicitors'
  }, {
    'value': 'Dutton Gregory',
    'label': 'Dutton Gregory'
  }, {
    'value': 'Michael Jefferies',
    'label': 'Michael Jefferies'
  }, {
    'value': 'Hannah Gold',
    'label': 'Hannah Gold'
  }, {
    'value': 'Lyons Wilson',
    'label': 'Lyons Wilson'
  }, {
    'value': 'Walker Smith Way',
    'label': 'Walker Smith Way'
  }, {
    'value': 'The Clarke Partnership',
    'label': 'The Clarke Partnership'
  }, {
    'value': 'Hilary Meredith',
    'label': 'Hilary Meredith'
  }, {
    'value': 'Lucas & Co',
    'label': 'Lucas & Co'
  }, {
    'value': 'Dominic Thompson & Co',
    'label': 'Dominic Thompson & Co'
  }, {
    'value': 'Russell Cooke',
    'label': 'Russell Cooke'
  }, {
    'value': 'Girlings',
    'label': 'Girlings'
  }, {
    'value': 'SA Law',
    'label': 'SA Law'
  }, {
    'value': 'DMH Stallard',
    'label': 'DMH Stallard'
  }, {
    'value': 'Atha & Co',
    'label': 'Atha & Co'
  }, {
    'value': 'McKeag & Co',
    'label': 'McKeag & Co'
  }, {
    'value': 'The Personal Injury Practice',
    'label': 'The Personal Injury Practice'
  }, {
    'value': 'Beers LLP',
    'label': 'Beers LLP'
  }, {
    'value': 'Croftons',
    'label': 'Croftons'
  }, {
    'value': 'Bower & Bailey',
    'label': 'Bower & Bailey'
  }, {
    'value': 'Williamsons',
    'label': 'Williamsons'
  }, {
    'value': 'Sparling Benham & Brough',
    'label': 'Sparling Benham & Brough'
  }, {
    'value': 'BAS Solicitors',
    'label': 'BAS Solicitors'
  }, {
    'value': 'Ashfields',
    'label': 'Ashfields'
  }, {
    'value': 'Bird & Lovibond',
    'label': 'Bird & Lovibond'
  }, {
    'value': 'Augustines',
    'label': 'Augustines'
  }, {
    'value': 'Ascot Lawyers',
    'label': 'Ascot Lawyers'
  }, {
    'value': 'Woolsey Morris & Kennedy',
    'label': 'Woolsey Morris & Kennedy'
  }, {
    'value': 'Burnett Barker',
    'label': 'Burnett Barker'
  }, {
    'value': 'Austins',
    'label': 'Austins'
  }, {
    'value': 'Armstrong Luty',
    'label': 'Armstrong Luty'
  }, {
    'value': 'Goughs',
    'label': 'Goughs'
  }, {
    'value': 'Bolt Burdon Kemp',
    'label': 'Bolt Burdon Kemp'
  }, {
    'value': 'Thorneycroft Solicitors Ltd',
    'label': 'Thorneycroft Solicitors Ltd'
  }, {
    'value': 'Prolegal Limited',
    'label': 'Prolegal Limited'
  }, {
    'value': 'Beecham Peacock',
    'label': 'Beecham Peacock'
  }, {
    'value': 'Hattons',
    'label': 'Hattons'
  }, {
    'value': 'Higgs & Sons',
    'label': 'Higgs & Sons'
  }, {
    'value': 'Platt & Fishwick',
    'label': 'Platt & Fishwick'
  }, {
    'value': 'Hayward Baker',
    'label': 'Hayward Baker'
  }, {
    'value': 'Applebys',
    'label': 'Applebys'
  }, {
    'value': 'Berrymans Lace Mawer',
    'label': 'Berrymans Lace Mawer'
  }, {
    'value': 'Geldards LLP',
    'label': 'Geldards LLP'
  }, {
    'value': 'Van Straten Solicitors',
    'label': 'Van Straten Solicitors'
  }, {
    'value': 'Moore Blatch Resolve',
    'label': 'Moore Blatch Resolve'
  }, {
    'value': 'Overburys',
    'label': 'Overburys'
  }, {
    'value': 'Beesley & Company Solicitors',
    'label': 'Beesley & Company Solicitors'
  }, {
    'value': 'Charles Gregory',
    'label': 'Charles Gregory'
  }, {
    'value': 'Knowles Benning',
    'label': 'Knowles Benning'
  }, {
    'value': 'MJP Personal Injury Ltd',
    'label': 'MJP Personal Injury Ltd'
  }, {
    'value': 'Bridge McFarland',
    'label': 'Bridge McFarland'
  }, {
    'value': 'The Rose Partnership Solicitors LLP',
    'label': 'The Rose Partnership Solicitors LLP'
  }, {
    'value': 'Birchall Blackburn',
    'label': 'Birchall Blackburn'
  }, {
    'value': 'Hill & Abbott',
    'label': 'Hill & Abbott'
  }, {
    'value': 'Ringrose Law',
    'label': 'Ringrose Law'
  }, {
    'value': 'JCP Solicitors',
    'label': 'JCP Solicitors'
  }, {
    'value': 'Poole Alcock LLP',
    'label': 'Poole Alcock LLP'
  }, {
    'value': 'Thackray  Williams',
    'label': 'Thackray  Williams'
  }, {
    'value': 'Admiral Law',
    'label': 'Admiral Law'
  }, {
    'value': 'Edwards Hoyle',
    'label': 'Edwards Hoyle'
  }, {
    'value': 'Seth Law',
    'label': 'Seth Law'
  }, {
    'value': 'Peasegoods',
    'label': 'Peasegoods'
  }, {
    'value': 'ODT Solicitors LLP',
    'label': 'ODT Solicitors LLP'
  }, {
    'value': 'Douglas-Jones Mercer',
    'label': 'Douglas-Jones Mercer'
  }, {
    'value': 'Birkett Long',
    'label': 'Birkett Long'
  }, {
    'value': 'Barrie Y Jones & Co Solicitors',
    'label': 'Barrie Y Jones & Co Solicitors'
  }, {
    'value': 'Nelsons',
    'label': 'Nelsons'
  }, {
    'value': 'Pryers',
    'label': 'Pryers'
  }, {
    'value': 'Gary Williams Solicitors',
    'label': 'Gary Williams Solicitors'
  }, {
    'value': 'Tranters Freeclaim',
    'label': 'Tranters Freeclaim'
  }, {
    'value': 'Townshends LLP',
    'label': 'Townshends LLP'
  }, {
    'value': 'Lawrence Hamblin',
    'label': 'Lawrence Hamblin'
  }, {
    'value': 'Bott & Co',
    'label': 'Bott & Co'
  }, {
    'value': 'Clarke Willmott',
    'label': 'Clarke Willmott'
  }, {
    'value': 'David Hodes & Company',
    'label': 'David Hodes & Company'
  }, {
    'value': 'Seth Lovis & Co',
    'label': 'Seth Lovis & Co'
  }, {
    'value': 'Peter Richbell & Co',
    'label': 'Peter Richbell & Co'
  }, {
    'value': 'ADF Law',
    'label': 'ADF Law'
  }, {
    'value': 'Chattertons',
    'label': 'Chattertons'
  }, {
    'value': 'Penningtons',
    'label': 'Penningtons'
  }, {
    'value': 'Brearleys',
    'label': 'Brearleys'
  }, {
    'value': 'JMP Solicitors',
    'label': 'JMP Solicitors'
  }, {
    'value': 'Shakespeares Legal LLP',
    'label': 'Shakespeares Legal LLP'
  }, {
    'value': 'Bonnetts',
    'label': 'Bonnetts'
  }, {
    'value': 'Judge & Priestley',
    'label': 'Judge & Priestley'
  }, {
    'value': 'The Giles Hunter Partnership',
    'label': 'The Giles Hunter Partnership'
  }, {
    'value': 'Walter Barr',
    'label': 'Walter Barr'
  }, {
    'value': 'Boyes Turner',
    'label': 'Boyes Turner'
  }, {
    'value': 'Fisher Jones Greenwood LLP',
    'label': 'Fisher Jones Greenwood LLP'
  }, {
    'value': 'Healys LLP',
    'label': 'Healys LLP'
  }, {
    'value': 'Bradin Trubshaw',
    'label': 'Bradin Trubshaw'
  }, {
    'value': 'Lavin Copitch',
    'label': 'Lavin Copitch'
  }, {
    'value': 'Versus Law Limited',
    'label': 'Versus Law Limited'
  }, {
    'value': 'Bond Pearce',
    'label': 'Bond Pearce'
  }, {
    'value': 'Gray Hooper Holt',
    'label': 'Gray Hooper Holt'
  }, {
    'value': 'NGA Solicitors',
    'label': 'NGA Solicitors'
  }, {
    'value': 'Barkers',
    'label': 'Barkers'
  }, {
    'value': 'Metcalfes',
    'label': 'Metcalfes'
  }, {
    'value': 'K G Solicitors Limited',
    'label': 'K G Solicitors Limited'
  }, {
    'value': 'Farley Dwek LLP',
    'label': 'Farley Dwek LLP'
  }, {
    'value': 'Woodford Stauffer',
    'label': 'Woodford Stauffer'
  }, {
    'value': 'Bentley',
    'label': 'Bentley'
  }, {
    'value': 'Deakin & Co Solicitors',
    'label': 'Deakin & Co Solicitors'
  }, {
    'value': 'Hatch Brenner LLP',
    'label': 'Hatch Brenner LLP'
  }, {
    'value': 'Meadows Ryan LLP',
    'label': 'Meadows Ryan LLP'
  }, {
    'value': 'Cooper Wilkin Chapman',
    'label': 'Cooper Wilkin Chapman'
  }, {
    'value': 'Yes Personal Injury Lawyers',
    'label': 'Yes Personal Injury Lawyers'
  }, {
    'value': 'Burroughs Day',
    'label': 'Burroughs Day'
  }, {
    'value': 'CTS Claim Today',
    'label': 'CTS Claim Today'
  }, {
    'value': 'Clark Willis',
    'label': 'Clark Willis'
  }, {
    'value': 'Harvey Roberts Solicitors',
    'label': 'Harvey Roberts Solicitors'
  }, {
    'value': 'TMJ Legal Services',
    'label': 'TMJ Legal Services'
  }, {
    'value': 'SGM Solicitors',
    'label': 'SGM Solicitors'
  }, {
    'value': 'Deighton Guedalla',
    'label': 'Deighton Guedalla'
  }, {
    'value': 'SC Law',
    'label': 'SC Law'
  }, {
    'value': 'Rawal & Co',
    'label': 'Rawal & Co'
  }, {
    'value': 'sheldon Davidson',
    'label': 'sheldon Davidson'
  }, {
    'value': 'Jefferies',
    'label': 'Jefferies'
  }, {
    'value': 'Traymans',
    'label': 'Traymans'
  }, {
    'value': 'Howe & Co',
    'label': 'Howe & Co'
  }, {
    'value': 'RMNJ',
    'label': 'RMNJ'
  }, {
    'value': 'C&S Solicitors',
    'label': 'C&S Solicitors'
  }, {
    'value': 'Bruce Lance & Co',
    'label': 'Bruce Lance & Co'
  }, {
    'value': 'Blaser Mills',
    'label': 'Blaser Mills'
  }, {
    'value': 'Dickinson Parker Hill',
    'label': 'Dickinson Parker Hill'
  }, {
    'value': 'Shelter',
    'label': 'Shelter'
  }, {
    'value': 'Hough Halton & Soal',
    'label': 'Hough Halton & Soal'
  }, {
    'value': 'Zermansky & Partners',
    'label': 'Zermansky & Partners'
  }, {
    'value': 'Sternberg Reed',
    'label': 'Sternberg Reed'
  }, {
    'value': 'Kitsons',
    'label': 'Kitsons'
  }, {
    'value': 'Southfields Solicitors',
    'label': 'Southfields Solicitors'
  }, {
    'value': 'MTA Solicitors',
    'label': 'MTA Solicitors'
  }, {
    'value': 'Gorman Hamilton Solicitors',
    'label': 'Gorman Hamilton Solicitors'
  }, {
    'value': 'Park Woodfine',
    'label': 'Park Woodfine'
  }, {
    'value': 'Taylor Vinters',
    'label': 'Taylor Vinters'
  }, {
    'value': 'DAS Legal Expenses Ins Co Ltd',
    'label': 'DAS Legal Expenses Ins Co Ltd'
  }, {
    'value': 'Anthony Collins Solicitors',
    'label': 'Anthony Collins Solicitors'
  }, {
    'value': 'Pannone LLP',
    'label': 'Pannone LLP'
  }, {
    'value': 'Guile Nicholas',
    'label': 'Guile Nicholas'
  }, {
    'value': 'DWT Legal',
    'label': 'DWT Legal'
  }, {
    'value': 'AMV Law',
    'label': 'AMV Law'
  }, {
    'value': 'Iliffes Booth Bennett',
    'label': 'Iliffes Booth Bennett'
  }, {
    'value': 'DWF LLP',
    'label': 'DWF LLP'
  }, {
    'value': 'Claim Time',
    'label': 'Claim Time'
  }, {
    'value': 'Petrou Solicitors',
    'label': 'Petrou Solicitors'
  }, {
    'value': 'Mercury Legal',
    'label': 'Mercury Legal'
  }, {
    'value': 'McKenzie Richards',
    'label': 'McKenzie Richards'
  }, {
    'value': 'Chandlers',
    'label': 'Chandlers'
  }, {
    'value': 'Storrar Cowdry',
    'label': 'Storrar Cowdry'
  }, {
    'value': 'Saracens Solicitors',
    'label': 'Saracens Solicitors'
  }, {
    'value': 'Cozens-Hardy LLP',
    'label': 'Cozens-Hardy LLP'
  }, {
    'value': 'Delta Legal',
    'label': 'Delta Legal'
  }, {
    'value': 'Evans Derry Binnion',
    'label': 'Evans Derry Binnion'
  }, {
    'value': 'Co-operative Legal Services',
    'label': 'Co-operative Legal Services'
  }, {
    'value': 'Walker Preston',
    'label': 'Walker Preston'
  }, {
    'value': 'Wilkinson Woodward Norcliffe',
    'label': 'Wilkinson Woodward Norcliffe'
  }, {
    'value': 'Bradbury Roberts & Raby',
    'label': 'Bradbury Roberts & Raby'
  }, {
    'value': 'Savas & Savage',
    'label': 'Savas & Savage'
  }, {
    'value': 'Onyems & Partners',
    'label': 'Onyems & Partners'
  }, {
    'value': 'Oliver & Co',
    'label': 'Oliver & Co'
  }, {
    'value': 'C W Law',
    'label': 'C W Law'
  }, {
    'value': 'Harold Stock & Co',
    'label': 'Harold Stock & Co'
  }, {
    'value': 'Advance Legal',
    'label': 'Advance Legal'
  }, {
    'value': 'Eaton Smith',
    'label': 'Eaton Smith'
  }, {
    'value': 'Gans & Co Solicitors LLP',
    'label': 'Gans & Co Solicitors LLP'
  }, {
    'value': 'Michael Hall Solicitors',
    'label': 'Michael Hall Solicitors'
  }, {
    'value': 'AYB Law',
    'label': 'AYB Law'
  }, {
    'value': 'Royds LLP',
    'label': 'Royds LLP'
  }, {
    'value': 'Edwards Davies',
    'label': 'Edwards Davies'
  }, {
    'value': 'J C & A Solicitors',
    'label': 'J C & A Solicitors'
  }, {
    'value': 'Amwells Solicitors',
    'label': 'Amwells Solicitors'
  }, {
    'value': 'Ison Harrison & Co',
    'label': 'Ison Harrison & Co'
  }, {
    'value': 'Taylor Bracewell',
    'label': 'Taylor Bracewell'
  }, {
    'value': 'Strafford Law Limited',
    'label': 'Strafford Law Limited'
  }, {
    'value': 'Ersan & Co',
    'label': 'Ersan & Co'
  }, {
    'value': 'Mitchell & Co',
    'label': 'Mitchell & Co'
  }, {
    'value': 'LL Barrowcliff',
    'label': 'LL Barrowcliff'
  }, {
    'value': 'Secure Law',
    'label': 'Secure Law'
  }, {
    'value': 'Dominic goward',
    'label': 'Dominic goward'
  }, {
    'value': 'ATM Law',
    'label': 'ATM Law'
  }, {
    'value': 'Excel Law',
    'label': 'Excel Law'
  }, {
    'value': 'R J Gill & Co',
    'label': 'R J Gill & Co'
  }, {
    'value': 'Clp Lawyers',
    'label': 'Clp Lawyers'
  }, {
    'value': 'Francis Alexander',
    'label': 'Francis Alexander'
  }, {
    'value': 'Dylan Nair Solicitors Ltd',
    'label': 'Dylan Nair Solicitors Ltd'
  }, {
    'value': 'Waring & Co',
    'label': 'Waring & Co'
  }, {
    'value': 'Dexter Montague & Partners',
    'label': 'Dexter Montague & Partners'
  }, {
    'value': "Liddy's",
    'label': "Liddy's"
  }, {
    'value': 'Swift Solicitors',
    'label': 'Swift Solicitors'
  }, {
    'value': 'Rapid Response',
    'label': 'Rapid Response'
  }, {
    'value': 'Bromiley Holcroft Ltd',
    'label': 'Bromiley Holcroft Ltd'
  }, {
    'value': 'Lloyd Green',
    'label': 'Lloyd Green'
  }, {
    'value': 'Broadgate Legal',
    'label': 'Broadgate Legal'
  }, {
    'value': 'Peter Brearley & Co Solicitors',
    'label': 'Peter Brearley & Co Solicitors'
  }, {
    'value': 'London Solicitors LLP',
    'label': 'London Solicitors LLP'
  }, {
    'value': 'Clifford Poole & Co',
    'label': 'Clifford Poole & Co'
  }, {
    'value': 'Chan Neill',
    'label': 'Chan Neill'
  }, {
    'value': 'Scaiff LLP',
    'label': 'Scaiff LLP'
  }, {
    'value': 'M R Law',
    'label': 'M R Law'
  }, {
    'value': 'GSD Law',
    'label': 'GSD Law'
  }, {
    'value': 'The Specter Partnership',
    'label': 'The Specter Partnership'
  }, {
    'value': 'Hughes Walker',
    'label': 'Hughes Walker'
  }, {
    'value': 'Iceblue Legal',
    'label': 'Iceblue Legal'
  }, {
    'value': 'Wilkinson & Butler',
    'label': 'Wilkinson & Butler'
  }, {
    'value': 'Simpkins & Co',
    'label': 'Simpkins & Co'
  }, {
    'value': 'W M Furness & Son',
    'label': 'W M Furness & Son'
  }, {
    'value': 'Wixted & Company Limited',
    'label': 'Wixted & Company Limited'
  }, {
    'value': 'The Connexion Partnership',
    'label': 'The Connexion Partnership'
  }, {
    'value': 'Kenneth Elliott & Rowe',
    'label': 'Kenneth Elliott & Rowe'
  }, {
    'value': 'Flint Bishop',
    'label': 'Flint Bishop'
  }, {
    'value': 'Churchgate Legal LLP',
    'label': 'Churchgate Legal LLP'
  }, {
    'value': 'The Compensation Lawyers',
    'label': 'The Compensation Lawyers'
  }, {
    'value': 'Dean Thomas & Co',
    'label': 'Dean Thomas & Co'
  }, {
    'value': 'Manners Pimblett',
    'label': 'Manners Pimblett'
  }, {
    'value': 'Bright & Sons',
    'label': 'Bright & Sons'
  }, {
    'value': 'Antony Hodari & Co',
    'label': 'Antony Hodari & Co'
  }, {
    'value': 'Cogent',
    'label': 'Cogent'
  }, {
    'value': 'Abrahams',
    'label': 'Abrahams'
  }, {
    'value': 'Margary + Miller Solicitors',
    'label': 'Margary + Miller Solicitors'
  }, {
    'value': 'Howell-Jones & Partnership',
    'label': 'Howell-Jones & Partnership'
  }, {
    'value': 'Slater Heelis',
    'label': 'Slater Heelis'
  }, {
    'value': 'David W Harris & Co',
    'label': 'David W Harris & Co'
  }, {
    'value': 'Peter Horada & Co',
    'label': 'Peter Horada & Co'
  }, {
    'value': 'Graham Dawson & Co',
    'label': 'Graham Dawson & Co'
  }, {
    'value': 'Drummonds',
    'label': 'Drummonds'
  }, {
    'value': 'Slee Blackwell',
    'label': 'Slee Blackwell'
  }, {
    'value': 'Reward Litigation Solicitors',
    'label': 'Reward Litigation Solicitors'
  }, {
    'value': 'Actons',
    'label': 'Actons'
  }, {
    'value': 'Quality Solicitors SLP',
    'label': 'Quality Solicitors SLP'
  }, {
    'value': 'Maliks & Khan Solicitors',
    'label': 'Maliks & Khan Solicitors'
  }, {
    'value': 'Seatons',
    'label': 'Seatons'
  }, {
    'value': 'Hinchliffes',
    'label': 'Hinchliffes'
  }, {
    'value': 'Duncan Gibbons Solicitors',
    'label': 'Duncan Gibbons Solicitors'
  }, {
    'value': 'Harvey Ingram LLP',
    'label': 'Harvey Ingram LLP'
  }, {
    'value': 'Pinto Potts',
    'label': 'Pinto Potts'
  }, {
    'value': 'Copley Clark & Bennett',
    'label': 'Copley Clark & Bennett'
  }, {
    'value': 'Knights',
    'label': 'Knights'
  }, {
    'value': 'N J Legal',
    'label': 'N J Legal'
  }, {
    'value': 'White Dalton Partnership',
    'label': 'White Dalton Partnership'
  }, {
    'value': 'Loosemores',
    'label': 'Loosemores'
  }, {
    'value': 'BM Blaser Mills',
    'label': 'BM Blaser Mills'
  }, {
    'value': 'Davis Blank Furniss',
    'label': 'Davis Blank Furniss'
  }, {
    'value': 'Goldens',
    'label': 'Goldens'
  }, {
    'value': 'Coles Solicitors',
    'label': 'Coles Solicitors'
  }, {
    'value': 'Dean Solicitors',
    'label': 'Dean Solicitors'
  }, {
    'value': 'Webbers',
    'label': 'Webbers'
  }, {
    'value': 'Steele Raymond LLP',
    'label': 'Steele Raymond LLP'
  }, {
    'value': 'Birketts LLP',
    'label': 'Birketts LLP'
  }, {
    'value': "Yorkshire's Injury Lawyers",
    'label': "Yorkshire's Injury Lawyers"
  }, {
    'value': 'Field Fisher Waterhouse',
    'label': 'Field Fisher Waterhouse'
  }, {
    'value': 'Latimer Lee',
    'label': 'Latimer Lee'
  }, {
    'value': 'Blakemores',
    'label': 'Blakemores'
  }, {
    'value': 'Shepherd Harris & Co',
    'label': 'Shepherd Harris & Co'
  }, {
    'value': 'Widdows Mason',
    'label': 'Widdows Mason'
  }, {
    'value': 'Leech & Co',
    'label': 'Leech & Co'
  }, {
    'value': 'Wragge & Co',
    'label': 'Wragge & Co'
  }, {
    'value': 'E Rex Makin & Co',
    'label': 'E Rex Makin & Co'
  }, {
    'value': 'Graham Bash & Co',
    'label': 'Graham Bash & Co'
  }, {
    'value': 'Albinson Napier & Co',
    'label': 'Albinson Napier & Co'
  }, {
    'value': 'Caseys Solicitors Ltd',
    'label': 'Caseys Solicitors Ltd'
  }, {
    'value': 'JSP Solicitors',
    'label': 'JSP Solicitors'
  }, {
    'value': 'Walton Mills & Co',
    'label': 'Walton Mills & Co'
  }, {
    'value': 'Morris Orman Hearle',
    'label': 'Morris Orman Hearle'
  }, {
    'value': 'Tranter Cleere & Co',
    'label': 'Tranter Cleere & Co'
  }, {
    'value': 'Fort & Co',
    'label': 'Fort & Co'
  }, {
    'value': 'Cogent Law',
    'label': 'Cogent Law'
  }, {
    'value': 'SLP',
    'label': 'SLP'
  }, {
    'value': 'SBM Solicitors LLP',
    'label': 'SBM Solicitors LLP'
  }, {
    'value': 'Lansbury Worthington',
    'label': 'Lansbury Worthington'
  }, {
    'value': 'Beecham Fisher Ridley',
    'label': 'Beecham Fisher Ridley'
  }, {
    'value': 'Awdry Bailey & Douglas',
    'label': 'Awdry Bailey & Douglas'
  }, {
    'value': 'J D Spicer & Co',
    'label': 'J D Spicer & Co'
  }, {
    'value': 'Leo Abse & Cohen',
    'label': 'Leo Abse & Cohen'
  }, {
    'value': 'involegal',
    'label': 'involegal'
  }, {
    'value': 'Davies & Company',
    'label': 'Davies & Company'
  }, {
    'value': 'Dowse & Co',
    'label': 'Dowse & Co'
  }, {
    'value': 'Wosskow Brown Solicitors LLP',
    'label': 'Wosskow Brown Solicitors LLP'
  }, {
    'value': 'Edwards Duthie',
    'label': 'Edwards Duthie'
  }, {
    'value': 'Hugh James',
    'label': 'Hugh James'
  }, {
    'value': 'Lindsays Solicitors',
    'label': 'Lindsays Solicitors'
  }, {
    'value': 'Greenwoods',
    'label': 'Greenwoods'
  }, {
    'value': 'Rowlands Field Cunningham LLP',
    'label': 'Rowlands Field Cunningham LLP'
  }, {
    'value': 'Tees',
    'label': 'Tees'
  }, {
    'value': 'Stephen Wood',
    'label': 'Stephen Wood'
  }, {
    'value': 'Veitch Penny',
    'label': 'Veitch Penny'
  }, {
    'value': 'Connell Associates',
    'label': 'Connell Associates'
  }, {
    'value': 'Adams & Remers LLP',
    'label': 'Adams & Remers LLP'
  }, {
    'value': 'Lloyd Donnelly',
    'label': 'Lloyd Donnelly'
  }, {
    'value': 'Flanagans',
    'label': 'Flanagans'
  }, {
    'value': 'TPC Solicitors',
    'label': 'TPC Solicitors'
  }, {
    'value': 'Connexion Partnership',
    'label': 'Connexion Partnership'
  }, {
    'value': 'Shoosmiths',
    'label': 'Shoosmiths'
  }, {
    'value': 'R Sedgley & Co',
    'label': 'R Sedgley & Co'
  }, {
    'value': 'Oliver Fisher',
    'label': 'Oliver Fisher'
  }, {
    'value': 'Prettys',
    'label': 'Prettys'
  }, {
    'value': 'Rapid Solicitors',
    'label': 'Rapid Solicitors'
  }, {
    'value': 'The Isaacs Practice',
    'label': 'The Isaacs Practice'
  }, {
    'value': 'Johnson Law',
    'label': 'Johnson Law'
  }, {
    'value': 'Osbornes',
    'label': 'Osbornes'
  }, {
    'value': 'Allen Barfields',
    'label': 'Allen Barfields'
  }, {
    'value': 'Buckles',
    'label': 'Buckles'
  }, {
    'value': 'Gregory Abrams Davidson',
    'label': 'Gregory Abrams Davidson'
  }, {
    'value': 'Coupe Bradbury',
    'label': 'Coupe Bradbury'
  }, {
    'value': 'George Ide Phillips',
    'label': 'George Ide Phillips'
  }, {
    'value': 'Philip H Cuerden',
    'label': 'Philip H Cuerden'
  }, {
    'value': 'BSMS',
    'label': 'BSMS'
  }, {
    'value': 'RSA Solicitors',
    'label': 'RSA Solicitors'
  }, {
    'value': 'Blake Lapthorn',
    'label': 'Blake Lapthorn'
  }, {
    'value': 'Beeley & Co',
    'label': 'Beeley & Co'
  }, {
    'value': 'Chadwick Lawrence',
    'label': 'Chadwick Lawrence'
  }, {
    'value': 'Jacksons',
    'label': 'Jacksons'
  }, {
    'value': 'Godfrey Morgan',
    'label': 'Godfrey Morgan'
  }, {
    'value': 'Coyne Learmonth',
    'label': 'Coyne Learmonth'
  }, {
    'value': 'Wiseman Lee',
    'label': 'Wiseman Lee'
  }, {
    'value': 'Lucas McMullan Jacobs',
    'label': 'Lucas McMullan Jacobs'
  }, {
    'value': 'Brindley Twist Tafft & James',
    'label': 'Brindley Twist Tafft & James'
  }, {
    'value': 'Finers Stephens Innocent',
    'label': 'Finers Stephens Innocent'
  }, {
    'value': 'Severn Law Ltd',
    'label': 'Severn Law Ltd'
  }, {
    'value': 'Hickman Rose',
    'label': 'Hickman Rose'
  }, {
    'value': 'BT Legal Dept',
    'label': 'BT Legal Dept'
  }, {
    'value': 'Barker Booth and Eastwood',
    'label': 'Barker Booth and Eastwood'
  }, {
    'value': 'Gordon Lutton',
    'label': 'Gordon Lutton'
  }, {
    'value': 'Lamport Bassitt',
    'label': 'Lamport Bassitt'
  }, {
    'value': 'Mason & Co',
    'label': 'Mason & Co'
  }, {
    'value': 'D P Roberts Hughes & Denye',
    'label': 'D P Roberts Hughes & Denye'
  }, {
    'value': 'Chambers Solicitors',
    'label': 'Chambers Solicitors'
  }, {
    'value': 'Blackburn & Co',
    'label': 'Blackburn & Co'
  }, {
    'value': 'Cornish Forfar & Allen',
    'label': 'Cornish Forfar & Allen'
  }, {
    'value': 'Thornleys',
    'label': 'Thornleys'
  }, {
    'value': 'The Ellen Court Partnership',
    'label': 'The Ellen Court Partnership'
  }, {
    'value': 'Saul Marine & Company',
    'label': 'Saul Marine & Company'
  }, {
    'value': 'Edward Harte & Co',
    'label': 'Edward Harte & Co'
  }
]


const claimantSolicitorsReduced = [
  {
    'value': 'Bakers',
    'label': 'Bakers'
  }, {
    'value': 'Express Solicitors',
    'label': 'Express Solicitors'
  }, {
    'value': 'Fentons',
    'label': 'Fentons'
  }, {
    'value': 'Hodge Jones & Allen',
    'label': 'Hodge Jones & Allen'
  }, {
    'value': 'Irwin Mitchell LLP',
    'label': 'Irwin Mitchell LLP'
  },{
    'value': 'Levenes',
    'label': 'Levenes'
  },{
    'value': 'Litigant in Person',
    'label': 'Litigant in Person'
  },{
    'value': 'Lyons Davidson',
    'label': 'Lyons Davidson'
  },{
    'value': 'O H Parsons & Partners',
    'label': 'O H Parsons & Partners'
  },{
    'value': 'Pannone LLP',
    'label': 'Pannone LLP'
  },{
    'value': 'Pattinson & Brewer',
    'label': 'Pattinson & Brewer'
  },{
    'value': 'Plexus Law',
    'label': 'Plexus Law'
  },{
    'value': 'Rowley Ashworth',
    'label': 'Rowley Ashworth'
  },{
    'value': 'Scott Rees & Co',
    'label': 'Scott Rees & Co'
  },{
    'value': 'Simpson Millar LLP',
    'label': 'Simpson Millar LLP'
  },{
    'value': 'Slater & Gordon (UK) LLP',
    'label': 'Slater & Gordon (UK) LLP'
  },{
    'value': 'Stone Rowe Brewer',
    'label': 'Stone Rowe Brewer'
  },{
    'value': 'Thompsons',
    'label': 'Thompsons'
  },{
    'value': 'Tollers LLP',
    'label': 'Tollers LLP'
  },{
    'value': 'Wixted & Company Limited',
    'label': 'Wixted & Company Limited'
  }
]


export {claimantSolicitors, claimantSolicitorsReduced};
