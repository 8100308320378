import React from "react"
import FormComponentFirstExtraction from "./FormComponentFirstExtraction"
import FormComponentSecondExtraction from "./FormComponentSecondExtraction"
import FormComponentThirdExtraction from "./FormComponentThirdExtraction"

class GetStepContentComponentExtraction extends React.Component{

  constructor(props){
    super(props)
  }
  /*
  constructor(props){
    super(props)
    this.state = {
      inputText: '',
      email: false,
      date: false,
      currency: false,
      cities: false,
      name: false,
      isTextExtracted: false,
      entitiesText: {},
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeText = this.handleChangeText.bind(this)
  }

  handleSubmit(event){
    event.preventDefault()
    fetch("/extract",{
      method:"POST",
      headers:{
        'Accept':'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'data_options': {
          'text': this.state.inputText,
          'email': this.state.email,
          'date': this.state.date,
          'currency': this.state.currency,
          'cities': this.state.cities,
          'name': this.state.name
        }
      })
    })
      .then(response => response.json())
      .then(data => {
          this.setState({
            entitiesText: data,
            isTextExtracted: true
          })
        })
  }

  handleChange = name => event =>{
    this.setState({ [name]: event.target.checked })
  }

  handleChangeText(event){
    const {name, value} = event.target
    this.setState({[name]: value})
  }
  */

  render(){
    const stepIndex = this.props.step
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <FormComponentFirstExtraction />
          </div>
        );
      case 1:
        return (
          <div>
            <FormComponentSecondExtraction
              handleChange={this.props.handleChange}
              handleChangeText={this.props.handleChangeText}
              handleSubmit={this.props.handleSubmit}
              data={this.props.data}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FormComponentThirdExtraction
              data={this.props.data}
            />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  }
}

export default GetStepContentComponentExtraction
