import React from 'react'
import SkillsPlot from './SkillsPlot'
import {withStyles} from '@material-ui/core/styles'

const styles = {
	card: {
		minHeight: 140
	},
	title: {
		fontSize: 16,
		textAlign: 'left'
	},
	button: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto'
	}
}


function FormComponentThirdFind(props) {

	const headinghr = {
		backgroundColor: '#cccccc',
		border: 'none',
		height: 1
	}

	const textStyle = {
		fontFamily: 'Nanum Gothic',
		textAlign: 'left',
		fontSize: 16,
		fontWeight: 'normal',
		color: '#67676A',
		paddingTop: '1.5%',
		lineHeight: 1.3
	}

	const notext = 'Please fill the fields.'

	return (
		<div>
			<div className='projects-container-text'>
				<br/>
				{props.data.result === 'None' ?
					<p className='projects-error'>{notext}</p> :
					<div>
						<h3 className='projects-heading'>Result:</h3>
						<hr style={headinghr}/>
						<br/>
						<div style={{display: 'flex'}}>
							<div style={{width: '50%'}}>
								<h4 className='projects-question'>Partner</h4>
								<ol>{props.data.result.map((item, i) => <li style={textStyle} key={i}>{item}</li>)}</ol>
							</div>
							<div style={{width: '50%'}}>
								<h4 className='projects-question'>Office</h4>
								<ol>{props.data.offices.map((item, i) => <li style={textStyle}
																			 key={i}>{item}</li>)}</ol>
							</div>
						</div>
						<br/>
					</div>}
				<br/>
				<br/>
				{props.data.result === 'None' ?
					null
					: <SkillsPlot
						result={props.data.result}
						neighbours={props.data.neighbours}
						tableSkills={props.data.tableSkills}
					/>}
				<br/>
			</div>
			<br/>
			<br/>
		</div>
	)
}

export default withStyles(styles)(FormComponentThirdFind)
