const litigated = [
  {
    value: 'Y',
    label: 'Yes'
  }, {
    value: 'N',
    label: 'No'
  }
]

const personalInjury = [
  {
    value: 'Y',
    label: 'Yes'
  }, {
    value: 'N',
    label: 'No'
  }
]

const claimantGender = [
  {
    value: 'F',
    label: 'Female'
  }, {
    value: 'M',
    label: 'Male'
  }
]

const claimantVehicle = [
  {
    'value': 'Mercedes',
    'label': 'Mercedes'
  }, {
    'value': 'Ford Focus',
    'label': 'Ford Focus'
  }, {
    'value': 'Hyundai Trajet',
    'label': 'Hyundai Trajet'
  }, {
    'value': 'Vauxhall Sinatra',
    'label': 'Vauxhall Sinatra'
  }, {
    'value': 'Fiat Punto',
    'label': 'Fiat Punto'
  }, {
    'value': 'Toyota Estima',
    'label': 'Toyota Estima'
  }, {
    'value': 'Vauxhall Vectra',
    'label': 'Vauxhall Vectra'
  }, {
    'value': 'Vauxhall Astra',
    'label': 'Vauxhall Astra'
  }, {
    'value': 'Toyota Avensis',
    'label': 'Toyota Avensis'
  }, {
    'value': 'Seat Alhambra',
    'label': 'Seat Alhambra'
  }, {
    'value': 'Renault Laguna',
    'label': 'Renault Laguna'
  }, {
    'value': 'Audi A4',
    'label': 'Audi A4'
  }, {
    'value': 'Renault Clio',
    'label': 'Renault Clio'
  }, {
    'value': 'Audi A3',
    'label': 'Audi A3'
  }, {
    'value': 'Peugeot 307',
    'label': 'Peugeot 307'
  }, {
    'value': 'Mini',
    'label': 'Mini'
  }, {
    'value': 'Volkswagen Passat',
    'label': 'Volkswagen Passat'
  }, {
    'value': 'Vauxhall Corsa',
    'label': 'Vauxhall Corsa'
  }, {
    'value': 'Renault SWB',
    'label': 'Renault SWB'
  }, {
    'value': 'BMW',
    'label': 'BMW'
  }, {
    'value': 'Citroen Saxo',
    'label': 'Citroen Saxo'
  }, {
    'value': 'Seat Ibiza',
    'label': 'Seat Ibiza'
  }, {
    'value': 'BMW 335d',
    'label': 'BMW 335d'
  }, {
    'value': 'Skoda Octavia',
    'label': 'Skoda Octavia'
  }, {
    'value': 'Mercedes-Benz E220',
    'label': 'Mercedes-Benz E220'
  }, {
    'value': 'Mercedes S320',
    'label': 'Mercedes S320'
  }, {
    'value': 'Skoda Felicia',
    'label': 'Skoda Felicia'
  }, {
    'value': 'Nissan Almera',
    'label': 'Nissan Almera'
  }, {
    'value': 'Proton Wira',
    'label': 'Proton Wira'
  }, {
    'value': 'Audi Q7',
    'label': 'Audi Q7'
  }, {
    'value': 'Vauxhall Zafira',
    'label': 'Vauxhall Zafira'
  }, {
    'value': 'Renault Espace',
    'label': 'Renault Espace'
  }, {
    'value': 'MG ZS',
    'label': 'MG ZS'
  }, {
    'value': 'Ford Fiesta',
    'label': 'Ford Fiesta'
  }, {
    'value': 'Peugeot 206',
    'label': 'Peugeot 206'
  }, {
    'value': 'Peugeot Expert',
    'label': 'Peugeot Expert'
  }, {
    'value': 'Skoda Comfort',
    'label': 'Skoda Comfort'
  }, {
    'value': 'Subaru Impreza',
    'label': 'Subaru Impreza'
  }, {
    'value': 'BMW 520d',
    'label': 'BMW 520d'
  }, {
    'value': 'Mercedes C220',
    'label': 'Mercedes C220'
  }, {
    'value': 'Audi',
    'label': 'Audi'
  }, {
    'value': 'Toyota Yaris',
    'label': 'Toyota Yaris'
  }, {
    'value': 'Volkswagen',
    'label': 'Volkswagen'
  }, {
    'value': 'Peugeot 306',
    'label': 'Peugeot 306'
  }, {
    'value': 'BMW 318i',
    'label': 'BMW 318i'
  }, {
    'value': 'Toyota Carina',
    'label': 'Toyota Carina'
  }, {
    'value': 'Volkswagen Golf',
    'label': 'Volkswagen Golf'
  }, {
    'value': 'MG ZT',
    'label': 'MG ZT'
  }, {
    'value': 'Mercedes E220',
    'label': 'Mercedes E220'
  }, {
    'value': 'Audi TT',
    'label': 'Audi TT'
  }, {
    'value': 'VW',
    'label': 'VW'
  }, {
    'value': 'Audi A6',
    'label': 'Audi A6'
  }, {
    'value': 'Citroen C4',
    'label': 'Citroen C4'
  }, {
    'value': 'Citroen Xsara Picasso',
    'label': 'Citroen Xsara Picasso'
  }, {
    'value': 'Rover 45',
    'label': 'Rover 45'
  }, {
    'value': 'Nissan Micra',
    'label': 'Nissan Micra'
  }, {
    'value': 'BMW 1 Series',
    'label': 'BMW 1 Series'
  }, {
    'value': 'BMW One Series',
    'label': 'BMW One Series'
  }, {
    'value': 'BMW 528i',
    'label': 'BMW 528i'
  }, {
    'value': 'BMW 330i',
    'label': 'BMW 330i'
  }, {
    'value': 'Volkswagen Sharan',
    'label': 'Volkswagen Sharan'
  }, {
    'value': 'Mazda 5',
    'label': 'Mazda 5'
  }, {
    'value': 'Honda Accord',
    'label': 'Honda Accord'
  }, {
    'value': 'London Cab',
    'label': 'London Cab'
  }, {
    'value': 'Toyota Prius',
    'label': 'Toyota Prius'
  }, {
    'value': 'Peugeot 406',
    'label': 'Peugeot 406'
  }, {
    'value': 'Mercedes A160',
    'label': 'Mercedes A160'
  }, {
    'value': 'Renault Traffic',
    'label': 'Renault Traffic'
  }, {
    'value': 'Ford Mondeo',
    'label': 'Ford Mondeo'
  }, {
    'value': 'Mercedes E270',
    'label': 'Mercedes E270'
  }, {
    'value': 'BMW X6',
    'label': 'BMW X6'
  }, {
    'value': 'BMW 730D',
    'label': 'BMW 730D'
  }, {
    'value': 'Toyota Hi Ace',
    'label': 'Toyota Hi Ace'
  }, {
    'value': 'Citroen C3',
    'label': 'Citroen C3'
  }, {
    'value': 'Ford Galaxy',
    'label': 'Ford Galaxy'
  }, {
    'value': 'Range Rover',
    'label': 'Range Rover'
  }, {
    'value': 'Rnage Rover',
    'label': 'Rnage Rover'
  }, {
    'value': 'Y677 CLA',
    'label': 'Y677 CLA'
  }, {
    'value': 'MG ZR',
    'label': 'MG ZR'
  }, {
    'value': 'BMW 1-Series',
    'label': 'BMW 1-Series'
  }, {
    'value': 'Volvo S80',
    'label': 'Volvo S80'
  }, {
    'value': 'Mazda 323',
    'label': 'Mazda 323'
  }, {
    'value': 'BMW 1161',
    'label': 'BMW 1161'
  }, {
    'value': 'Kia Sportage',
    'label': 'Kia Sportage'
  }, {
    'value': 'Volvo S60',
    'label': 'Volvo S60'
  }, {
    'value': 'Mini One',
    'label': 'Mini One'
  }, {
    'value': 'Vauxhal;l Vectra',
    'label': 'Vauxhal;l Vectra'
  }, {
    'value': 'Saab',
    'label': 'Saab'
  }, {
    'value': 'Toyota Avensis Verso',
    'label': 'Toyota Avensis Verso'
  }, {
    'value': 'VW Sharan',
    'label': 'VW Sharan'
  }, {
    'value': 'Volkswagen Passatt',
    'label': 'Volkswagen Passatt'
  }, {
    'value': 'Toyota Verso',
    'label': 'Toyota Verso'
  }, {
    'value': 'BMW X5',
    'label': 'BMW X5'
  }, {
    'value': 'Ford Focus Zetec',
    'label': 'Ford Focus Zetec'
  }, {
    'value': 'Chevrolet Kalos',
    'label': 'Chevrolet Kalos'
  }, {
    'value': 'Vauxhall Manvano',
    'label': 'Vauxhall Manvano'
  }, {
    'value': 'Vauhall Manvno',
    'label': 'Vauhall Manvno'
  }, {
    'value': 'Vauhall Manvano',
    'label': 'Vauhall Manvano'
  }, {
    'value': 'Renault Leguna',
    'label': 'Renault Leguna'
  }, {
    'value': 'LEXUS IS200',
    'label': 'LEXUS IS200'
  }, {
    'value': 'Renault Megane',
    'label': 'Renault Megane'
  }, {
    'value': 'VW Bora',
    'label': 'VW Bora'
  }, {
    'value': 'Volkswagen Bora',
    'label': 'Volkswagen Bora'
  }, {
    'value': 'Peugeot 207',
    'label': 'Peugeot 207'
  }, {
    'value': 'Aixam A751',
    'label': 'Aixam A751'
  }, {
    'value': 'Renault Kangoo',
    'label': 'Renault Kangoo'
  }, {
    'value': 'Vauxhall Passat',
    'label': 'Vauxhall Passat'
  }, {
    'value': 'VW Jetta',
    'label': 'VW Jetta'
  }, {
    'value': 'Mercedes E300',
    'label': 'Mercedes E300'
  }, {
    'value': 'Honda Civic',
    'label': 'Honda Civic'
  }, {
    'value': 'VW Passat',
    'label': 'VW Passat'
  }, {
    'value': 'Toyota Corolla',
    'label': 'Toyota Corolla'
  }, {
    'value': 'Volkswagen Polo',
    'label': 'Volkswagen Polo'
  }, {
    'value': 'Volvo',
    'label': 'Volvo'
  }, {
    'value': 'BMW 118d',
    'label': 'BMW 118d'
  }, {
    'value': 'CK08 GDV',
    'label': 'CK08 GDV'
  }, {
    'value': 'BMW 320i',
    'label': 'BMW 320i'
  }, {
    'value': 'Peugeot  E7 Expert (Euro Cab)',
    'label': 'Peugeot  E7 Expert (Euro Cab)'
  }, {
    'value': 'Daewoo Matiz',
    'label': 'Daewoo Matiz'
  }, {
    'value': 'Fiat Doblo',
    'label': 'Fiat Doblo'
  }, {
    'value': 'Vauxhall Combo',
    'label': 'Vauxhall Combo'
  }, {
    'value': 'PEUGEOT',
    'label': 'PEUGEOT'
  }, {
    'value': 'Audi A3`',
    'label': 'Audi A3`'
  }, {
    'value': 'Mazda',
    'label': 'Mazda'
  }, {
    'value': 'VE Passat',
    'label': 'VE Passat'
  }, {
    'value': 'Seat Toledo',
    'label': 'Seat Toledo'
  }, {
    'value': 'Renault Mmegane',
    'label': 'Renault Mmegane'
  }, {
    'value': 'Daihatsu',
    'label': 'Daihatsu'
  }, {
    'value': 'Mazda 6',
    'label': 'Mazda 6'
  }, {
    'value': 'Fiat Stilo',
    'label': 'Fiat Stilo'
  }, {
    'value': 'mazda 6',
    'label': 'mazda 6'
  }, {
    'value': 'Audi A2',
    'label': 'Audi A2'
  }, {
    'value': 'Mercedes CLS 350',
    'label': 'Mercedes CLS 350'
  }, {
    'value': 'Citroen Xsara',
    'label': 'Citroen Xsara'
  }, {
    'value': 'Mitsubihi Lancer',
    'label': 'Mitsubihi Lancer'
  }, {
    'value': 'Mitsubishi Lancer',
    'label': 'Mitsubishi Lancer'
  }, {
    'value': 'Mitsubshi Lancer',
    'label': 'Mitsubshi Lancer'
  }, {
    'value': 'Jaguar X Type',
    'label': 'Jaguar X Type'
  }, {
    'value': 'Hyundai I30',
    'label': 'Hyundai I30'
  }, {
    'value': 'BMW 320',
    'label': 'BMW 320'
  }, {
    'value': 'Toyota Corrolla',
    'label': 'Toyota Corrolla'
  }, {
    'value': 'Nissan Micra Sport',
    'label': 'Nissan Micra Sport'
  }, {
    'value': 'Mercedes C220 Sport',
    'label': 'Mercedes C220 Sport'
  }, {
    'value': 'Peugeot 407',
    'label': 'Peugeot 407'
  }, {
    'value': 'Nissan Primera',
    'label': 'Nissan Primera'
  }, {
    'value': 'VW Golf',
    'label': 'VW Golf'
  }, {
    'value': 'Hyundi Getz',
    'label': 'Hyundi Getz'
  }, {
    'value': 'Ford Ranger',
    'label': 'Ford Ranger'
  }, {
    'value': 'Jeep Ranger',
    'label': 'Jeep Ranger'
  }, {
    'value': 'Volve S60',
    'label': 'Volve S60'
  }, {
    'value': 'Mitsuibishi Grandis',
    'label': 'Mitsuibishi Grandis'
  }, {
    'value': 'Mitsubishi Grandis',
    'label': 'Mitsubishi Grandis'
  }, {
    'value': 'Vauhall Astra',
    'label': 'Vauhall Astra'
  }, {
    'value': 'Vauxhall Insignia',
    'label': 'Vauxhall Insignia'
  }, {
    'value': 'Citroen C5',
    'label': 'Citroen C5'
  }, {
    'value': 'Ford Focus ST',
    'label': 'Ford Focus ST'
  }, {
    'value': 'Ford Ka',
    'label': 'Ford Ka'
  }, {
    'value': 'Rover',
    'label': 'Rover'
  }, {
    'value': 'Lexus IS200',
    'label': 'Lexus IS200'
  }, {
    'value': 'Mercedes-Benz A140',
    'label': 'Mercedes-Benz A140'
  }, {
    'value': 'Chrysler Voyager',
    'label': 'Chrysler Voyager'
  }, {
    'value': 'Land Rover Discovery',
    'label': 'Land Rover Discovery'
  }, {
    'value': 'Land Rovier Discovery',
    'label': 'Land Rovier Discovery'
  }, {
    'value': 'Mecedes-Benz CLS320',
    'label': 'Mecedes-Benz CLS320'
  }, {
    'value': 'Mercedes-Benz CLS320',
    'label': 'Mercedes-Benz CLS320'
  }, {
    'value': 'Peugeot',
    'label': 'Peugeot'
  }, {
    'value': 'Vauxhall Signum',
    'label': 'Vauxhall Signum'
  }, {
    'value': 'Ford Transit',
    'label': 'Ford Transit'
  }, {
    'value': 'LDV Convoy',
    'label': 'LDV Convoy'
  }, {
    'value': 'Skoda Fabia',
    'label': 'Skoda Fabia'
  }, {
    'value': 'Honda Jazz',
    'label': 'Honda Jazz'
  }, {
    'value': 'Nissan Qashqai',
    'label': 'Nissan Qashqai'
  }, {
    'value': 'Vauxhall',
    'label': 'Vauxhall'
  }, {
    'value': 'Ford Fiesta Zetec',
    'label': 'Ford Fiesta Zetec'
  }, {
    'value': 'Hyundai',
    'label': 'Hyundai'
  }, {
    'value': 'Seat Leon',
    'label': 'Seat Leon'
  }, {
    'value': 'N',
    'label': 'N'
  }, {
    'value': 'Citreon',
    'label': 'Citreon'
  }, {
    'value': 'Citroen',
    'label': 'Citroen'
  }, {
    'value': 'Vauzhall Vectra',
    'label': 'Vauzhall Vectra'
  }, {
    'value': 'BMW 318ES',
    'label': 'BMW 318ES'
  }, {
    'value': 'BMW 525D',
    'label': 'BMW 525D'
  }, {
    'value': 'Jaguar S-Type',
    'label': 'Jaguar S-Type'
  }, {
    'value': 'Mercedes CLK 320 Elegance Auto Coupe',
    'label': 'Mercedes CLK 320 Elegance Auto Coupe'
  }, {
    'value': 'Seat',
    'label': 'Seat'
  }, {
    'value': 'Bus',
    'label': 'Bus'
  }, {
    'value': 'Mercedes Vito',
    'label': 'Mercedes Vito'
  }, {
    'value': 'Ford S-Max Zetec',
    'label': 'Ford S-Max Zetec'
  }, {
    'value': 'Volkswagen Jetta',
    'label': 'Volkswagen Jetta'
  }, {
    'value': 'VW Polo',
    'label': 'VW Polo'
  }, {
    'value': 'Skoda Octavia S Tdi CR',
    'label': 'Skoda Octavia S Tdi CR'
  }, {
    'value': 'Suzuki Vitara JX 4 U Hard-TOP',
    'label': 'Suzuki Vitara JX 4 U Hard-TOP'
  }, {
    'value': 'Toyota Auris',
    'label': 'Toyota Auris'
  }, {
    'value': 'Violkswagen Passat',
    'label': 'Violkswagen Passat'
  }, {
    'value': 'Toyota Celcia',
    'label': 'Toyota Celcia'
  }, {
    'value': 'Peugeot 407 SE HDi',
    'label': 'Peugeot 407 SE HDi'
  }, {
    'value': 'S-Type Jaguar',
    'label': 'S-Type Jaguar'
  }, {
    'value': 'FORD MONDEO',
    'label': 'FORD MONDEO'
  }, {
    'value': 'BMW 525',
    'label': 'BMW 525'
  }, {
    'value': 'Peugeot 206CC',
    'label': 'Peugeot 206CC'
  }, {
    'value': 'Mercedes Benz',
    'label': 'Mercedes Benz'
  }, {
    'value': 'Mercedes-Benz B180 CDi',
    'label': 'Mercedes-Benz B180 CDi'
  }, {
    'value': 'Ford S Max',
    'label': 'Ford S Max'
  }, {
    'value': 'Ford s Max',
    'label': 'Ford s Max'
  }, {
    'value': 'Porsche 911 Turbo',
    'label': 'Porsche 911 Turbo'
  }, {
    'value': 'Volvo S40',
    'label': 'Volvo S40'
  }, {
    'value': 'City Rover',
    'label': 'City Rover'
  }, {
    'value': 'Renault Scenic',
    'label': 'Renault Scenic'
  }, {
    'value': 'Chevrolet',
    'label': 'Chevrolet'
  }, {
    'value': 'Suzuki Swift',
    'label': 'Suzuki Swift'
  }, {
    'value': 'Mercedes Vito 110 CD',
    'label': 'Mercedes Vito 110 CD'
  }, {
    'value': 'Fiat 500',
    'label': 'Fiat 500'
  }, {
    'value': 'BMW 530',
    'label': 'BMW 530'
  }, {
    'value': 'Lexus Rx300',
    'label': 'Lexus Rx300'
  }, {
    'value': 'Lexus',
    'label': 'Lexus'
  }, {
    'value': 'BMW 316d ES',
    'label': 'BMW 316d ES'
  }, {
    'value': 'Toyota',
    'label': 'Toyota'
  }, {
    'value': 'Vauzhall Zafira',
    'label': 'Vauzhall Zafira'
  }, {
    'value': 'Mercedes C180 Elegance',
    'label': 'Mercedes C180 Elegance'
  }, {
    'value': 'Ford Puma',
    'label': 'Ford Puma'
  }, {
    'value': 'Mercedes C180',
    'label': 'Mercedes C180'
  }, {
    'value': 'Jeep Voyager Grand LX',
    'label': 'Jeep Voyager Grand LX'
  }, {
    'value': 'Jeep Voyager Grabd LX',
    'label': 'Jeep Voyager Grabd LX'
  }, {
    'value': 'Renault Megane Expression',
    'label': 'Renault Megane Expression'
  }, {
    'value': 'Volvo V70',
    'label': 'Volvo V70'
  }, {
    'value': 'BMW 323I',
    'label': 'BMW 323I'
  }, {
    'value': 'Vauzhall Corsa',
    'label': 'Vauzhall Corsa'
  }, {
    'value': 'Mercedes-Benz C220',
    'label': 'Mercedes-Benz C220'
  }, {
    'value': 'Toyota RAV-4',
    'label': 'Toyota RAV-4'
  }, {
    'value': 'Vauxhall Zafira Life CDTI Auto',
    'label': 'Vauxhall Zafira Life CDTI Auto'
  }, {
    'value': 'BMW 535D',
    'label': 'BMW 535D'
  }, {
    'value': 'Red Subaru Imprezza',
    'label': 'Red Subaru Imprezza'
  }, {
    'value': 'Mercedes C180K',
    'label': 'Mercedes C180K'
  }, {
    'value': 'TX1 Taxi',
    'label': 'TX1 Taxi'
  }, {
    'value': 'Mercedes Vito 111',
    'label': 'Mercedes Vito 111'
  }, {
    'value': 'Mazda 3 TS Diesel',
    'label': 'Mazda 3 TS Diesel'
  }, {
    'value': 'Audi A5',
    'label': 'Audi A5'
  }, {
    'value': 'London Taxi',
    'label': 'London Taxi'
  }, {
    'value': 'BMW 318',
    'label': 'BMW 318'
  }, {
    'value': 'Ford Mondeo Ghia',
    'label': 'Ford Mondeo Ghia'
  }, {
    'value': 'Peugeot Partner 850S HDI 90 Panel Van',
    'label': 'Peugeot Partner 850S HDI 90 Panel Van'
  }, {
    'value': 'Fiat Marea TD75',
    'label': 'Fiat Marea TD75'
  }, {
    'value': 'Fiar Marea TD75',
    'label': 'Fiar Marea TD75'
  }, {
    'value': 'BMW 335i SE',
    'label': 'BMW 335i SE'
  }, {
    'value': 'Nissan Note',
    'label': 'Nissan Note'
  }, {
    'value': 'Rover Streetwise',
    'label': 'Rover Streetwise'
  }, {
    'value': 'Saab 9-3',
    'label': 'Saab 9-3'
  }, {
    'value': 'Mercedes CLS350',
    'label': 'Mercedes CLS350'
  }, {
    'value': 'BMW 330CI Sport Coupe',
    'label': 'BMW 330CI Sport Coupe'
  }, {
    'value': 'BMW530d',
    'label': 'BMW530d'
  }, {
    'value': 'Mini Cooper',
    'label': 'Mini Cooper'
  }, {
    'value': 'Mercedes C Class',
    'label': 'Mercedes C Class'
  }, {
    'value': 'Mercedes Class C',
    'label': 'Mercedes Class C'
  }, {
    'value': 'BMW 1 series',
    'label': 'BMW 1 series'
  }, {
    'value': 'Ford Modeo',
    'label': 'Ford Modeo'
  }, {
    'value': 'Renauly Dynamique',
    'label': 'Renauly Dynamique'
  }, {
    'value': 'Renault Dynamique',
    'label': 'Renault Dynamique'
  }, {
    'value': 'Mitsubishi Colt',
    'label': 'Mitsubishi Colt'
  }, {
    'value': 'Toyota Aygo',
    'label': 'Toyota Aygo'
  }, {
    'value': 'BMW 5 Series',
    'label': 'BMW 5 Series'
  }, {
    'value': 'Toyota Rav-4',
    'label': 'Toyota Rav-4'
  }, {
    'value': 'BMW M3',
    'label': 'BMW M3'
  }, {
    'value': 'Accord',
    'label': 'Accord'
  }, {
    'value': 'Soda Octavia',
    'label': 'Soda Octavia'
  }, {
    'value': 'BMW 318I SE',
    'label': 'BMW 318I SE'
  }, {
    'value': '2004 Nissan Micra S Auto 1240cc',
    'label': '2004 Nissan Micra S Auto 1240cc'
  }, {
    'value': 'seat',
    'label': 'seat'
  }, {
    'value': 'Hyundai Atoz',
    'label': 'Hyundai Atoz'
  }, {
    'value': 'Mercedes C200',
    'label': 'Mercedes C200'
  }, {
    'value': 'Ford KA',
    'label': 'Ford KA'
  }, {
    'value': 'Nissan Navara',
    'label': 'Nissan Navara'
  }, {
    'value': 'Honda Jazz Se',
    'label': 'Honda Jazz Se'
  }, {
    'value': 'Kia Picanto',
    'label': 'Kia Picanto'
  }, {
    'value': 'Mercedes E20',
    'label': 'Mercedes E20'
  }, {
    'value': 'Toyota Previa',
    'label': 'Toyota Previa'
  }, {
    'value': 'Lexus Rx Estate',
    'label': 'Lexus Rx Estate'
  }, {
    'value': 'Fiat Scudo',
    'label': 'Fiat Scudo'
  }, {
    'value': 'Motorcar',
    'label': 'Motorcar'
  }, {
    'value': 'London Taxi  TXII',
    'label': 'London Taxi  TXII'
  }, {
    'value': 'Toyotal Previa',
    'label': 'Toyotal Previa'
  }, {
    'value': 'Volkswagen Golf Gt Tdi',
    'label': 'Volkswagen Golf Gt Tdi'
  }, {
    'value': 'BMW 3 Series',
    'label': 'BMW 3 Series'
  }, {
    'value': 'Volkswagen Golf S 2002',
    'label': 'Volkswagen Golf S 2002'
  }, {
    'value': 'MG ZR 105',
    'label': 'MG ZR 105'
  }, {
    'value': 'Black cab',
    'label': 'Black cab'
  }, {
    'value': 'Rover 75',
    'label': 'Rover 75'
  }, {
    'value': 'Honda',
    'label': 'Honda'
  }, {
    'value': 'Alpha Romero',
    'label': 'Alpha Romero'
  }, {
    'value': 'BMW 32QD',
    'label': 'BMW 32QD'
  }, {
    'value': 'Exaggeration',
    'label': 'Exaggeration'
  }, {
    'value': 'Vauxhall Vivaro',
    'label': 'Vauxhall Vivaro'
  }, {
    'value': 'Honda Stream',
    'label': 'Honda Stream'
  }, {
    'value': 'Mercedes Benz S350',
    'label': 'Mercedes Benz S350'
  }, {
    'value': 'Peugeot 308',
    'label': 'Peugeot 308'
  }
]

const insuredVehicle = [
  {
    'value': 'Vauxhall Frontera Sport',
    'label': 'Vauxhall Frontera Sport'
  }, {
    'value': 'N',
    'label': 'N'
  }, {
    'value': 'Hyundai Trajet',
    'label': 'Hyundai Trajet'
  }, {
    'value': 'Peugeot 206',
    'label': 'Peugeot 206'
  }, {
    'value': 'Toyota Rav 4',
    'label': 'Toyota Rav 4'
  }, {
    'value': 'Ford Focus',
    'label': 'Ford Focus'
  }, {
    'value': 'Toyota Avensis',
    'label': 'Toyota Avensis'
  }, {
    'value': 'Ford Fiesta',
    'label': 'Ford Fiesta'
  }, {
    'value': 'Fiat Bravo',
    'label': 'Fiat Bravo'
  }, {
    'value': 'Renault Clio',
    'label': 'Renault Clio'
  }, {
    'value': 'BMW 330',
    'label': 'BMW 330'
  }, {
    'value': 'Peugeot',
    'label': 'Peugeot'
  }, {
    'value': 'Rover 25',
    'label': 'Rover 25'
  }, {
    'value': 'T877FRU',
    'label': 'T877FRU'
  }, {
    'value': 'Renault',
    'label': 'Renault'
  }, {
    'value': '-',
    'label': '-'
  }, {
    'value': 'Fiat Punto',
    'label': 'Fiat Punto'
  }, {
    'value': 'Toyota Corolla',
    'label': 'Toyota Corolla'
  }, {
    'value': 'Saab 93',
    'label': 'Saab 93'
  }, {
    'value': 'VW Golf',
    'label': 'VW Golf'
  }, {
    'value': 'V368 JNP',
    'label': 'V368 JNP'
  }, {
    'value': 'Skoda Fabia',
    'label': 'Skoda Fabia'
  }, {
    'value': 'Rover 211',
    'label': 'Rover 211'
  }, {
    'value': 'Vauxhall Astra',
    'label': 'Vauxhall Astra'
  }, {
    'value': 'Vauxhall Corsa',
    'label': 'Vauxhall Corsa'
  }, {
    'value': 'VAUXHALL FRONTERA',
    'label': 'VAUXHALL FRONTERA'
  }, {
    'value': 'VW JETTA',
    'label': 'VW JETTA'
  }, {
    'value': 'Nissan Micra',
    'label': 'Nissan Micra'
  }, {
    'value': 'BMW',
    'label': 'BMW'
  }, {
    'value': 'BMW 320d',
    'label': 'BMW 320d'
  }, {
    'value': 'Peugeot Biper',
    'label': 'Peugeot Biper'
  }, {
    'value': 'CITROEN',
    'label': 'CITROEN'
  }, {
    'value': 'HONDA ACCORD',
    'label': 'HONDA ACCORD'
  }, {
    'value': 'VAUXHALL ASTRA',
    'label': 'VAUXHALL ASTRA'
  }, {
    'value': 'Peugeot 107',
    'label': 'Peugeot 107'
  }, {
    'value': 'Honda Civic',
    'label': 'Honda Civic'
  }, {
    'value': 'BMW X5',
    'label': 'BMW X5'
  }, {
    'value': 'Vauxhall Astra Arctic 16v',
    'label': 'Vauxhall Astra Arctic 16v'
  }, {
    'value': 'Vauxhall',
    'label': 'Vauxhall'
  }, {
    'value': 'VW SHARAN',
    'label': 'VW SHARAN'
  }, {
    'value': 'Nissan Coupe',
    'label': 'Nissan Coupe'
  }, {
    'value': 'Lexus',
    'label': 'Lexus'
  }, {
    'value': 'Mercedes C200',
    'label': 'Mercedes C200'
  }, {
    'value': 'Peugeot 307',
    'label': 'Peugeot 307'
  }, {
    'value': 'Fiat Stilo',
    'label': 'Fiat Stilo'
  }, {
    'value': 'Toyota Yaris',
    'label': 'Toyota Yaris'
  }, {
    'value': 'Volvo',
    'label': 'Volvo'
  }, {
    'value': 'Mercedes',
    'label': 'Mercedes'
  }, {
    'value': 'CITREON',
    'label': 'CITREON'
  }, {
    'value': 'FIAT 500',
    'label': 'FIAT 500'
  }, {
    'value': 'Peugeot 308',
    'label': 'Peugeot 308'
  }, {
    'value': 'Citreon Xsara',
    'label': 'Citreon Xsara'
  }, {
    'value': 'Peugeot 407',
    'label': 'Peugeot 407'
  }, {
    'value': 'Mazda 626 Se Auto',
    'label': 'Mazda 626 Se Auto'
  }, {
    'value': 'Toyota Corrola',
    'label': 'Toyota Corrola'
  }, {
    'value': 'Mini',
    'label': 'Mini'
  }, {
    'value': 'Jeep Grand Cherokee',
    'label': 'Jeep Grand Cherokee'
  }, {
    'value': 'Jeep Wrangler',
    'label': 'Jeep Wrangler'
  }, {
    'value': 'Honda Jazz',
    'label': 'Honda Jazz'
  }, {
    'value': 'BMW 1 Series',
    'label': 'BMW 1 Series'
  }, {
    'value': 'Renault Megane',
    'label': 'Renault Megane'
  }, {
    'value': 'Ford KA',
    'label': 'Ford KA'
  }, {
    'value': 'FORD',
    'label': 'FORD'
  }, {
    'value': 'Seat Alhambra',
    'label': 'Seat Alhambra'
  }, {
    'value': 'Mitsubishi Warrior',
    'label': 'Mitsubishi Warrior'
  }, {
    'value': 'VW',
    'label': 'VW'
  }, {
    'value': 'Citroen Saxo Forte',
    'label': 'Citroen Saxo Forte'
  }, {
    'value': 'Rover',
    'label': 'Rover'
  }, {
    'value': 'Chrysler Voyager',
    'label': 'Chrysler Voyager'
  }, {
    'value': 'Saab 95',
    'label': 'Saab 95'
  }, {
    'value': 'VW Polo',
    'label': 'VW Polo'
  }, {
    'value': 'Audi',
    'label': 'Audi'
  }, {
    'value': 'Alfa Romeo',
    'label': 'Alfa Romeo'
  }, {
    'value': 'Ford Mondeo',
    'label': 'Ford Mondeo'
  }, {
    'value': 'Nissan Qashqai',
    'label': 'Nissan Qashqai'
  }, {
    'value': 'BMW 318ISE',
    'label': 'BMW 318ISE'
  }, {
    'value': 'Toyota Aygo',
    'label': 'Toyota Aygo'
  }, {
    'value': 'Porsche Boxster',
    'label': 'Porsche Boxster'
  }, {
    'value': 'Volvo V70 SE',
    'label': 'Volvo V70 SE'
  }, {
    'value': 'Bus',
    'label': 'Bus'
  }, {
    'value': 'Volvo S60',
    'label': 'Volvo S60'
  }, {
    'value': 'Vauxhall Vectra',
    'label': 'Vauxhall Vectra'
  }, {
    'value': 'Fiat 500 Lounge',
    'label': 'Fiat 500 Lounge'
  }, {
    'value': 'Mini Cooper',
    'label': 'Mini Cooper'
  }, {
    'value': 'Subuaru Impreza',
    'label': 'Subuaru Impreza'
  }, {
    'value': 'Citroen Xsara',
    'label': 'Citroen Xsara'
  }, {
    'value': 'Volkswagen Polo',
    'label': 'Volkswagen Polo'
  }, {
    'value': 'Audi A4',
    'label': 'Audi A4'
  }, {
    'value': 'Toyota Celica Coupe',
    'label': 'Toyota Celica Coupe'
  }, {
    'value': 'VW Passat',
    'label': 'VW Passat'
  }, {
    'value': 'Audi A3',
    'label': 'Audi A3'
  }, {
    'value': 'Peugeot 207',
    'label': 'Peugeot 207'
  }, {
    'value': 'Hyundai Getz CDX',
    'label': 'Hyundai Getz CDX'
  }, {
    'value': 'Mercedes Clk 320 Avantgarde',
    'label': 'Mercedes Clk 320 Avantgarde'
  }, {
    'value': 'Vauxhall Zafira',
    'label': 'Vauxhall Zafira'
  }, {
    'value': 'Nissan Almera',
    'label': 'Nissan Almera'
  }, {
    'value': 'Kia Rio',
    'label': 'Kia Rio'
  }, {
    'value': 'Daihastsu Sirion',
    'label': 'Daihastsu Sirion'
  }, {
    'value': 'Seat Ibiza',
    'label': 'Seat Ibiza'
  }, {
    'value': 'Citroen C1',
    'label': 'Citroen C1'
  }, {
    'value': 'Ford',
    'label': 'Ford'
  }, {
    'value': 'Seat Leon',
    'label': 'Seat Leon'
  }, {
    'value': 'Vauxhall Corsa Club 16V',
    'label': 'Vauxhall Corsa Club 16V'
  }, {
    'value': 'Citroen Saxo',
    'label': 'Citroen Saxo'
  }, {
    'value': 'Mercedes-benz Slk250',
    'label': 'Mercedes-benz Slk250'
  }, {
    'value': 'BMW 318I SE',
    'label': 'BMW 318I SE'
  }, {
    'value': 'Blue Renault Clio',
    'label': 'Blue Renault Clio'
  }, {
    'value': '2004 Nissan Micra S Auto 1240cc',
    'label': '2004 Nissan Micra S Auto 1240cc'
  }, {
    'value': 'Peugeot 308 \n',
    'label': 'Peugeot 308 \n'
  }, {
    'value': 'Seat Toledo',
    'label': 'Seat Toledo'
  }, {
    'value': 'Mercedes C200 KOMP',
    'label': 'Mercedes C200 KOMP'
  }, {
    'value': 'Honda Jazz Se',
    'label': 'Honda Jazz Se'
  }, {
    'value': 'Peugeot 206 Style',
    'label': 'Peugeot 206 Style'
  }, {
    'value': 'BMW 320D',
    'label': 'BMW 320D'
  }, {
    'value': 'Lexus Rx Estate',
    'label': 'Lexus Rx Estate'
  }, {
    'value': 'Ford Focus Studio TD',
    'label': 'Ford Focus Studio TD'
  }, {
    'value': 'Motorcar',
    'label': 'Motorcar'
  }, {
    'value': '2014 Peugeot 107 Allure',
    'label': '2014 Peugeot 107 Allure'
  }, {
    'value': 'Mini Cooper S',
    'label': 'Mini Cooper S'
  }, {
    'value': 'Toyota RAV-4 XT4 VVTI',
    'label': 'Toyota RAV-4 XT4 VVTI'
  }, {
    'value': 'Mazda',
    'label': 'Mazda'
  }, {
    'value': 'Audi A4 Sport TDI Cabrio',
    'label': 'Audi A4 Sport TDI Cabrio'
  }, {
    'value': 'Mazda RX8 Coupe',
    'label': 'Mazda RX8 Coupe'
  }, {
    'value': 'Land Rover \nDiscovery',
    'label': 'Land Rover \nDiscovery'
  }, {
    'value': 'Ford Ranger TD 4WD (109)',
    'label': 'Ford Ranger TD 4WD (109)'
  }, {
    'value': 'Suzuki Grand Vitara',
    'label': 'Suzuki Grand Vitara'
  }, {
    'value': 'Volkswagen Passat',
    'label': 'Volkswagen Passat'
  }, {
    'value': 'Volkswagen Golf',
    'label': 'Volkswagen Golf'
  }, {
    'value': 'Honda',
    'label': 'Honda'
  }, {
    'value': 'Nissan Micra S',
    'label': 'Nissan Micra S'
  }, {
    'value': 'Volkswagen Polo SE Bluemotion',
    'label': 'Volkswagen Polo SE Bluemotion'
  }, {
    'value': 'Honda Prelude',
    'label': 'Honda Prelude'
  }, {
    'value': 'Seat Toledo SE',
    'label': 'Seat Toledo SE'
  }, {
    'value': 'Audi q3',
    'label': 'Audi q3'
  }, {
    'value': 'Nissan Terrano',
    'label': 'Nissan Terrano'
  }
]

const claimantOccupation = [
  {
    'value': 'Not Recorded',
    'label': 'Not Recorded'
  }, {
    'value': 'Apprentice Plasterer',
    'label': 'Apprentice Plasterer'
  }, {
    'value': 'Unemployed',
    'label': 'Unemployed'
  }, {
    'value': 'Apprentice Brick Layer',
    'label': 'Apprentice Brick Layer'
  }, {
    'value': 'Delivery Driver',
    'label': 'Delivery Driver'
  }, {
    'value': 'Warehouse Assistant',
    'label': 'Warehouse Assistant'
  }, {
    'value': 'Courier Driver',
    'label': 'Courier Driver'
  }, {
    'value': 'Self-employed',
    'label': 'Self-employed'
  }, {
    'value': 'Housewife',
    'label': 'Housewife'
  }, {
    'value': 'Taxi Driver',
    'label': 'Taxi Driver'
  }, {
    'value': 'Take Away Shop Manager',
    'label': 'Take Away Shop Manager'
  }, {
    'value': 'Hair Dresser/Beautician',
    'label': 'Hair Dresser/Beautician'
  }, {
    'value': 'Riding Instructor',
    'label': 'Riding Instructor'
  }, {
    'value': 'Hair Dresser',
    'label': 'Hair Dresser'
  }, {
    'value': 'Factory Worker',
    'label': 'Factory Worker'
  }, {
    'value': 'Assistant Manager',
    'label': 'Assistant Manager'
  }, {
    'value': 'Plasterer',
    'label': 'Plasterer'
  }, {
    'value': 'Office Worker',
    'label': 'Office Worker'
  }, {
    'value': 'Student',
    'label': 'Student'
  }, {
    'value': 'Promotional Work',
    'label': 'Promotional Work'
  }, {
    'value': 'Music Producer',
    'label': 'Music Producer'
  }, {
    'value': 'Long Term Sick',
    'label': 'Long Term Sick'
  }, {
    'value': 'Pest Control Technician',
    'label': 'Pest Control Technician'
  }, {
    'value': 'Customer Services Manager',
    'label': 'Customer Services Manager'
  }, {
    'value': 'Driver',
    'label': 'Driver'
  }, {
    'value': 'Self Employed',
    'label': 'Self Employed'
  }, {
    'value': 'London Black Cab Driver',
    'label': 'London Black Cab Driver'
  }, {
    'value': 'Self Employed Taxi Driver',
    'label': 'Self Employed Taxi Driver'
  }, {
    'value': 'Sales Rep',
    'label': 'Sales Rep'
  }, {
    'value': 'Sales Assistant',
    'label': 'Sales Assistant'
  }, {
    'value': 'Plumber',
    'label': 'Plumber'
  }, {
    'value': 'Construction',
    'label': 'Construction'
  }, {
    'value': 'HR Manager',
    'label': 'HR Manager'
  }, {
    'value': 'TBC',
    'label': 'TBC'
  }, {
    'value': 'Cleaner',
    'label': 'Cleaner'
  }, {
    'value': 'Director',
    'label': 'Director'
  }, {
    'value': 'Chef',
    'label': 'Chef'
  }, {
    'value': 'Childrens Centre Worker',
    'label': 'Childrens Centre Worker'
  }, {
    'value': 'Security Officer',
    'label': 'Security Officer'
  }, {
    'value': 'Retired',
    'label': 'Retired'
  }, {
    'value': 'Butcher',
    'label': 'Butcher'
  }, {
    'value': 'Showroom',
    'label': 'Showroom'
  }, {
    'value': 'Shop Assistant',
    'label': 'Shop Assistant'
  }, {
    'value': 'Client Accountant',
    'label': 'Client Accountant'
  }, {
    'value': 'Nail Technician',
    'label': 'Nail Technician'
  }, {
    'value': 'Minor',
    'label': 'Minor'
  }, {
    'value': 'Nursery Nurse',
    'label': 'Nursery Nurse'
  }, {
    'value': 'Child',
    'label': 'Child'
  }, {
    'value': 'Social Worker',
    'label': 'Social Worker'
  }, {
    'value': 'Events Organiser',
    'label': 'Events Organiser'
  }, {
    'value': 'Apprentice Motor Mechanic',
    'label': 'Apprentice Motor Mechanic'
  }, {
    'value': 'Secretary',
    'label': 'Secretary'
  }, {
    'value': 'Manager',
    'label': 'Manager'
  }, {
    'value': 'Car Sales Man',
    'label': 'Car Sales Man'
  }, {
    'value': 'Customer Advisor',
    'label': 'Customer Advisor'
  }, {
    'value': 'Youth Worker',
    'label': 'Youth Worker'
  }, {
    'value': 'Builder',
    'label': 'Builder'
  }, {
    'value': 'Employed',
    'label': 'Employed'
  }, {
    'value': 'Minicab Driver',
    'label': 'Minicab Driver'
  }, {
    'value': 'Line Operative',
    'label': 'Line Operative'
  }, {
    'value': 'Cab Driver',
    'label': 'Cab Driver'
  }, {
    'value': 'Property Investor',
    'label': 'Property Investor'
  }, {
    'value': 'House Cleaner',
    'label': 'House Cleaner'
  }, {
    'value': 'Carpenter',
    'label': 'Carpenter'
  }, {
    'value': 'Hotel Porter',
    'label': 'Hotel Porter'
  }, {
    'value': 'Warehouse Operative',
    'label': 'Warehouse Operative'
  }, {
    'value': 'Tailor',
    'label': 'Tailor'
  }, {
    'value': 'Security Guard',
    'label': 'Security Guard'
  }, {
    'value': 'Sales',
    'label': 'Sales'
  }, {
    'value': 'Housekeeper',
    'label': 'Housekeeper'
  }, {
    'value': 'Barmaid',
    'label': 'Barmaid'
  }, {
    'value': 'Kitchen Porter',
    'label': 'Kitchen Porter'
  }, {
    'value': 'Post Office Engineer',
    'label': 'Post Office Engineer'
  }, {
    'value': 'Valeting',
    'label': 'Valeting'
  }, {
    'value': 'Car Valeter',
    'label': 'Car Valeter'
  }, {
    'value': 'PCO Driver',
    'label': 'PCO Driver'
  }, {
    'value': 'Driving Instructor',
    'label': 'Driving Instructor'
  }, {
    'value': 'Administrator',
    'label': 'Administrator'
  }, {
    'value': 'Construction Worker',
    'label': 'Construction Worker'
  }, {
    'value': 'Factory Stock Controller',
    'label': 'Factory Stock Controller'
  }, {
    'value': 'Painter & Decorator',
    'label': 'Painter & Decorator'
  }, {
    'value': 'Mechanic',
    'label': 'Mechanic'
  }, {
    'value': 'Counsellor',
    'label': 'Counsellor'
  }, {
    'value': 'Factory Driver',
    'label': 'Factory Driver'
  }, {
    'value': 'Lunchtime Supervisor / Cleaner',
    'label': 'Lunchtime Supervisor / Cleaner'
  }, {
    'value': 'Hairdresser',
    'label': 'Hairdresser'
  }, {
    'value': 'Solicitor',
    'label': 'Solicitor'
  }, {
    'value': 'Duty Manager',
    'label': 'Duty Manager'
  }, {
    'value': 'Bus Operator',
    'label': 'Bus Operator'
  }, {
    'value': 'Packer',
    'label': 'Packer'
  }, {
    'value': 'Beautician',
    'label': 'Beautician'
  }, {
    'value': 'Customer Service',
    'label': 'Customer Service'
  }, {
    'value': 'Bus Driver',
    'label': 'Bus Driver'
  }, {
    'value': 'Shop Keeper',
    'label': 'Shop Keeper'
  }, {
    'value': 'Company Director',
    'label': 'Company Director'
  }, {
    'value': 'Engineer',
    'label': 'Engineer'
  }, {
    'value': 'Nanny',
    'label': 'Nanny'
  }, {
    'value': 'Office Admin',
    'label': 'Office Admin'
  }, {
    'value': 'Painter',
    'label': 'Painter'
  }, {
    'value': 'Security',
    'label': 'Security'
  }, {
    'value': 'Factory Operator',
    'label': 'Factory Operator'
  }, {
    'value': 'Catering Part Time',
    'label': 'Catering Part Time'
  }, {
    'value': 'Market Worker',
    'label': 'Market Worker'
  }, {
    'value': 'Catering',
    'label': 'Catering'
  }, {
    'value': 'IT Engineer',
    'label': 'IT Engineer'
  }, {
    'value': 'Computer Analyst',
    'label': 'Computer Analyst'
  }, {
    'value': 'Takeaway Worker',
    'label': 'Takeaway Worker'
  }, {
    'value': 'Homemaker',
    'label': 'Homemaker'
  }, {
    'value': 'Part Time Waitress',
    'label': 'Part Time Waitress'
  }, {
    'value': 'Business Owner',
    'label': 'Business Owner'
  }, {
    'value': 'Take Away Receptionist',
    'label': 'Take Away Receptionist'
  }, {
    'value': 'Private Hire Driver',
    'label': 'Private Hire Driver'
  }, {
    'value': 'Teacher',
    'label': 'Teacher'
  }, {
    'value': 'Retail Manager',
    'label': 'Retail Manager'
  }, {
    'value': 'Retail',
    'label': 'Retail'
  }, {
    'value': 'Cabinet Maker',
    'label': 'Cabinet Maker'
  }, {
    'value': 'Private Hire Taxi Driver',
    'label': 'Private Hire Taxi Driver'
  }, {
    'value': 'Retail Assistant',
    'label': 'Retail Assistant'
  }, {
    'value': 'Estate Agent',
    'label': 'Estate Agent'
  }, {
    'value': 'Pizza Chef',
    'label': 'Pizza Chef'
  }, {
    'value': 'Shop Owner',
    'label': 'Shop Owner'
  }, {
    'value': 'Car Dealer and Servicing',
    'label': 'Car Dealer and Servicing'
  }, {
    'value': 'Gardener',
    'label': 'Gardener'
  }, {
    'value': 'Admin Assistant',
    'label': 'Admin Assistant'
  }, {
    'value': 'Facotry Worker',
    'label': 'Facotry Worker'
  }, {
    'value': 'Police Constable',
    'label': 'Police Constable'
  }, {
    'value': 'Asda Manager',
    'label': 'Asda Manager'
  }, {
    'value': 'Stable Groom',
    'label': 'Stable Groom'
  }, {
    'value': 'Carer',
    'label': 'Carer'
  }, {
    'value': 'Sign Fitter',
    'label': 'Sign Fitter'
  }, {
    'value': 'Courier',
    'label': 'Courier'
  }, {
    'value': 'Customer Service Advisor',
    'label': 'Customer Service Advisor'
  }, {
    'value': 'Mini Cab Driver',
    'label': 'Mini Cab Driver'
  }, {
    'value': 'Sales Person',
    'label': 'Sales Person'
  }, {
    'value': 'Window Maker',
    'label': 'Window Maker'
  }, {
    'value': 'Housing Manager',
    'label': 'Housing Manager'
  }, {
    'value': 'Prigramme Executive',
    'label': 'Prigramme Executive'
  }, {
    'value': 'Warehouse Worker',
    'label': 'Warehouse Worker'
  }, {
    'value': 'Mail Sorter at Royal Mail',
    'label': 'Mail Sorter at Royal Mail'
  }, {
    'value': 'Account Manager',
    'label': 'Account Manager'
  }, {
    'value': 'Windscreen Fitter',
    'label': 'Windscreen Fitter'
  }, {
    'value': 'Airport Operator',
    'label': 'Airport Operator'
  }, {
    'value': 'Health Care Assistant',
    'label': 'Health Care Assistant'
  }, {
    'value': 'Accounts Assistant',
    'label': 'Accounts Assistant'
  }, {
    'value': 'Study Support Mentor',
    'label': 'Study Support Mentor'
  }, {
    'value': 'Window Cleaner',
    'label': 'Window Cleaner'
  }, {
    'value': 'Nurse',
    'label': 'Nurse'
  }, {
    'value': 'Store Assistant Manager',
    'label': 'Store Assistant Manager'
  }, {
    'value': 'Restaurant Owner',
    'label': 'Restaurant Owner'
  }, {
    'value': 'Care Worker',
    'label': 'Care Worker'
  }, {
    'value': 'Admin',
    'label': 'Admin'
  }, {
    'value': 'Ground Worker',
    'label': 'Ground Worker'
  }, {
    'value': 'Warehouse Operator',
    'label': 'Warehouse Operator'
  }, {
    'value': 'App Mechanic',
    'label': 'App Mechanic'
  }, {
    'value': 'Operations Manager',
    'label': 'Operations Manager'
  }, {
    'value': 'Picker',
    'label': 'Picker'
  }, {
    'value': 'Self-Employed/Market Trader',
    'label': 'Self-Employed/Market Trader'
  }, {
    'value': 'Canvesser',
    'label': 'Canvesser'
  }, {
    'value': 'Full Time Education',
    'label': 'Full Time Education'
  }, {
    'value': 'Market Trader/ Self-Employed',
    'label': 'Market Trader/ Self-Employed'
  }, {
    'value': 'Job Seeker',
    'label': 'Job Seeker'
  }, {
    'value': 'Car Wash',
    'label': 'Car Wash'
  }, {
    'value': 'Collective Advisor',
    'label': 'Collective Advisor'
  }, {
    'value': 'Translater',
    'label': 'Translater'
  }, {
    'value': 'Caterer',
    'label': 'Caterer'
  }, {
    'value': 'Make-Up Artist',
    'label': 'Make-Up Artist'
  }, {
    'value': 'Jeweller',
    'label': 'Jeweller'
  }, {
    'value': 'Upholsterer',
    'label': 'Upholsterer'
  }, {
    'value': 'Computer Engineer',
    'label': 'Computer Engineer'
  }, {
    'value': 'Car Painter',
    'label': 'Car Painter'
  }, {
    'value': 'Quality Controller',
    'label': 'Quality Controller'
  }, {
    'value': 'IT Technician',
    'label': 'IT Technician'
  }, {
    'value': 'Care Assistance',
    'label': 'Care Assistance'
  }, {
    'value': 'Carer For Her Mother',
    'label': 'Carer For Her Mother'
  }, {
    'value': 'Supermarket Worker',
    'label': 'Supermarket Worker'
  }, {
    'value': 'Human Resource Assistant',
    'label': 'Human Resource Assistant'
  }, {
    'value': 'Property Manager',
    'label': 'Property Manager'
  }, {
    'value': 'Receptionist',
    'label': 'Receptionist'
  }, {
    'value': 'Enforcement Officer',
    'label': 'Enforcement Officer'
  }, {
    'value': 'Mini-Cab Driver',
    'label': 'Mini-Cab Driver'
  }, {
    'value': 'Waiter',
    'label': 'Waiter'
  }, {
    'value': 'Lorry Driver',
    'label': 'Lorry Driver'
  }, {
    'value': 'Technician',
    'label': 'Technician'
  }, {
    'value': 'Customer Service Assistant',
    'label': 'Customer Service Assistant'
  }, {
    'value': 'Unknown',
    'label': 'Unknown'
  }, {
    'value': 'Food Delivery Driver',
    'label': 'Food Delivery Driver'
  }, {
    'value': 'Cinema Supervisor',
    'label': 'Cinema Supervisor'
  }, {
    'value': 'Panel Beater',
    'label': 'Panel Beater'
  }, {
    'value': 'Electrician',
    'label': 'Electrician'
  }, {
    'value': 'Researcher',
    'label': 'Researcher'
  }, {
    'value': 'Sixth Form Student',
    'label': 'Sixth Form Student'
  }, {
    'value': 'Music Production',
    'label': 'Music Production'
  }, {
    'value': 'Data Analysist',
    'label': 'Data Analysist'
  }, {
    'value': 'Pharmacy Technician',
    'label': 'Pharmacy Technician'
  }, {
    'value': 'Practice Manager at Dental',
    'label': 'Practice Manager at Dental'
  }
]

const vehicleReduced = [
  {
    'value': 'Audi',
    'label': 'Audi'
  }, {
    'value': 'BMW',
    'label': 'BMW'
  }, {
    'value': 'Ford',
    'label': 'Ford'
  }, {
    'value': 'Honda',
    'label': 'Honda'
  }, {
    'value': 'Jaguar',
    'label': 'Jaguar'
  }, {
    'value': 'Landrover',
    'label': 'Land Rover'
  }, {
    'value': 'Lexus',
    'label': 'Lexus'
  }, {
    'value': 'Mercedes',
    'label': 'Mercedes'
  }, {
    'value': 'MINI',
    'label': 'MINI'
  }, {
    'value': 'Porsche',
    'label': 'Porsche'
  }, {
    'value': 'Toyota',
    'label': 'Toyota'
  }, {
    'value': 'Volkswagen',
    'label': 'Volkswagen'
  }, {
    'value': 'Volvo',
    'label': 'Volvo'
  }
]

const claimantOccupationReduced = [
  {
    'value': 'Administrative/Secretary',
    'label': 'Administrative/Secretary'
  }, {
    'value': 'Animal Care and Control',
    'label': 'Animal Care and Control'
  }, {
    'value': 'Arts/Literatures',
    'label': 'Arts/Literature'
  }, {
    'value': 'Building Trade Worker',
    'label': 'Building Trade Worker'
  }, {
    'value': 'Business Services',
    'label': 'Business Services'
  }, {
    'value': 'Business Services/Administration Managers',
    'label': 'Business Services/Administration Manager'
  }, {
    'value': 'Education',
    'label': 'Education'
  }, {
    'value': 'Engineer',
    'label': 'Engineer'
  }, {
    'value': 'Event Organiser/Manager',
    'label': 'Event Organiser/Manager'
  }, {
    'value': 'Food Preparation/Hospitality',
    'label': 'Food Preparation/Hospitality'
  }, {
    'value': 'Health Services',
    'label': 'Health Services'
  }, {
    'value': 'Homemaking',
    'label': 'Homemaking'
  }, {
    'value': 'Hospitality/Retail Manager',
    'label': 'Hospitality/Retail Manager'
  }, {
    'value': 'Information Technology ',
    'label': 'Information Technology '
  }, {
    'value': 'Legal Services',
    'label': 'Legal Services'
  }, {
    'value': 'Managing Director/Chief Executive',
    'label': 'Managing Director/Chief Executive'
  }, {
    'value': 'Manufacturing Workers',
    'label': 'Manufacturing Worker'
  }, {
    'value': 'Marketing/Development Manager',
    'label': 'Marketing/Development Manager'
  }, {
    'value': 'Minor',
    'label': 'Minor'
  }, {
    'value': 'Pensioner',
    'label': 'Pensioner'
  }, {
    'value': 'Police/Armed Forces',
    'label': 'Police/Armed Forces'
  }, {
    'value': 'Professional Services ',
    'label': 'Professional Services '
  }, {
    'value': 'Protective Services',
    'label': 'Protective Services'
  }, {
    'value': 'Self Employed',
    'label': 'Self Employed'
  }, {
    'value': 'Service/Sales Workers',
    'label': 'Service/Sales Worker'
  }, {
    'value': 'Sick Leave',
    'label': 'Sick Leave'
  }, {
    'value': 'Skilled Trade',
    'label': 'Skilled Trade'
  }, {
    'value': 'Sports/Fitness ',
    'label': 'Sports/Fitness '
  }, {
    'value': 'Student',
    'label': 'Student'
  }, {
    'value': 'Transportation Services',
    'label': 'Transportation Services'
  }, {
    'value': 'Unemployed',
    'label': 'Unemployed'
  }, {
    'value': 'Unknown',
    'label': 'Unknown'
  }, {
    'value': 'Vehicle Trade Worker',
    'label': 'Vehicle Trade Worker'
  }, {
    'value': 'Welfare',
    'label': 'Welfare'
  }
]

const claimantStatus = [
  {
    'value': 'Lower Middle Class',
    'label': 'Lower Middle Class'
  }, {
    'value': 'Middle Class',
    'label': 'Middle Class'
  }, {
    'value': 'Non Working',
    'label': 'Non Working'
  }, {
    'value': 'Skill Working Class',
    'label': 'Skill Working Class'
  }, {
    'value': 'Upper Middle Class',
    'label': 'Upper Middle Class'
  }, {
    'value': 'Working Class',
    'label': 'Working Class'
  }, {
    'value': 'NOT IN GOV STAT',
    'label': 'Not recorded'
  }
]

export {
  litigated,
  personalInjury,
  claimantGender,
  claimantOccupation,
  claimantOccupationReduced,
  claimantVehicle,
  insuredVehicle,
  vehicleReduced,
  claimantStatus
};
