import React from "react"
import {
    find1,
    find2,
    find3,
    find4,
    find5,
    find6
} from '../descriptions'

function FormComponentFirstFind() {

    const titleFind = "Find a Friend";
    const styleSpan = {
        display: 'inline-block',
        fontWeight: 'bold'
    }


    return (
        <div>
            <div className='projects-container-text'>
                <br/>
                <h2 className="projects-container-introduction-subtitle">{titleFind}</h2>
                <div style={{textAlign: 'justify'}}>
                    <p className='projects-container-introduction-text'>{find1}</p>
                    <p className='projects-container-introduction-text'>{find2}</p>
                    <p className='projects-container-introduction-text'>{find3}</p>
                    <p className='projects-container-introduction-text'>{find4}</p>
                    <p className='projects-container-introduction-text'>{find5}</p>
                    <p className='projects-container-introduction-text'>{find6}</p>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default FormComponentFirstFind
