import React from 'react'
import '../App.css'
import {about1, about2, about2a, about2b, about2c, about3, about4, about5, about6} from '../descriptions'

function About(){
	return(
		<div className="about">
			<div className="home-about-text">
				<h1>About the DataLab</h1>
				<h2>{about1}</h2>
				<h2>{about2}</h2>
				<ul>
					<li>{about2a}</li>
					<li>{about2b}</li>
					<li>{about2c}</li>
				</ul>
				<h2>{about3}</h2>
				<h2>{about4}</h2>
				<h2>{about5}</h2>
				<h2>{about6}</h2>
				<br/>
			</div>
		</div>

	)
}

export default About