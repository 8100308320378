import React from "react"
import {
	directions1,
	directions1a,
	directions1b,
	directions1c,
	directions1d,
	directions2,
	directionsSpan2,
	directions2a,
	directions2b,
	directions2c,
	directions2d,
	directions3,
	directions4,
	directions5,
	directions6,
	directions7,
} from '../descriptions'
import HoverText from '../HoverText'
import {
	MachineLearningKeyword,
	NlpKeyword
} from '../glossary'


function FormComponentFirstDirections(props) {

	const titleDirections = "Directions Reader"

	const subtitle = {
		color: '#484241',
		textAlign: 'center',
		fontWeight: 300,
		fontSize: '30px',
		lineHeight: 1.3,
		paddingBottom: '2%'
	}

	const styleSpan = {
		display: 'inline-block',
		fontWeight: 'bold'
	}

	return (
		<div>
			<div className='projects-container-text'>
				<br/>
				<h2 style={subtitle}>{titleDirections}</h2>
				<div style={{textAlign: 'justify'}}>
					<p className="projects-container-introduction-text">{directions1}</p>
					<ul className='projects-container-introduction-text'>
						<li>{directions1a}</li>
						<li>{directions1b}</li>
						<li>{directions1c}</li>
						<li>{directions1d}<strong>Categories</strong>)</li>
					</ul>
					<span className="projects-container-introduction-text">
						{directions2}
						<span className='span-keywords'>
						<HoverText
							keyword='NLP'
							text={NlpKeyword}
						/>
					  </span>{directionsSpan2}
					</span>
					<ul className='projects-container-introduction-text'>
						<li>{directions2a}</li>
						<li>{directions2b}</li>
						<li>{directions2c}</li>
						<li>{directions2d}</li>
					</ul>
					<p className="projects-container-introduction-text">{directions3}</p>
					<p className="projects-container-introduction-text">{directions4}</p>
					<p className="projects-container-introduction-text">{directions5}</p>
					<p className="projects-container-introduction-text">{directions6}</p>
					<p className="projects-container-introduction-text">{directions7}</p>
				</div>
				<div>
					<p style={{fontFamily: "Caecilia", fontWeight: "bold"}}>Example order</p>
					<img style={{width: "60%"}} src={"./directionorderexample.PNG"} alt="Example direction reader"/>
				</div>
			</div>
			<br/>
			<br/>
		</div>
	)
}

export default FormComponentFirstDirections
