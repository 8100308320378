import React from 'react'
import TextField from "@material-ui/core/TextField"
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {
	examples,
} from './DirectionsData'

const styles = {
	card: {
		minHeight: 140
	},
	button: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		fontFamily: "Caecilia",
	},
	textField: {
		textAlign: 'left'
	},
	menu: {
		width: 400,
	}
}

function FormComponentSecondDirections(props) {

	const {classes} = props

	const instruction =
		'Please select an example direction from the drop-down list below, or input your own example direction, ' +
		'to see an example of how Directions Reader works in practice'

	return (
		<div>
			<div className='projects-container-text'>
				<br/>
				<h3 className='projects-question'>{instruction}</h3>
				<br/>
				<p className='projects-question'>Examples:</p>
				<TextField
					id="examples"
					select
					value={props.data.orderWording}
					className={classes.textField}
					onChange={props.handleChange('orderWording')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{examples.map(option => (
						<MenuItem key={option.value} value={option.value}>
							<Typography variant="inherit" noWrap>
								{option.label}
							</Typography>
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<TextField
					id="outlined-multiline-flexible"
					label="Direction Wording"
					multiline
					rowsMax="4"
					value={props.data.orderWording}
					onChange={props.handleChange('orderWording')}
					margin="normal"
					name="orderWording"
					variant="outlined"
					fullWidth
				/>
				<br/>
			</div>
			<br/>
			<br/>
			<br/>
			<br/>
		</div>
	)
}

export default withStyles(styles)(FormComponentSecondDirections)
