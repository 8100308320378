import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  reserving_result
} from '../descriptions'

const styles ={
  card:{
    minHeight: 140
  },
  title:{
    fontSize: 25,
    textAlign: 'left'
  },
  button:{
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}

function FormComponentThirdReserving(props){

  const headinghr = {
    backgroundColor: '#cccccc',
    border: 'none',
    height:1
  }

  const notext = 'Please fill the fields.'

  return(
    <div>
      <div className='projects-container-text'>
        <br/>
        { props.data.totalCost === 'None' ?
        <p className='projects-error'>{notext}</p> :
        <div>
          <h3 className='projects-heading'>Suggested Reserve:</h3>
          <hr style={headinghr}/>
          <h4 className='projects-question'>£ {props.data.totalCost}</h4>
          <br />
          <p className='projects-question'>{reserving_result}</p>
        </div>}
      </div>
      <br />
    </div>
  )
}

export default withStyles(styles)(FormComponentThirdReserving)
