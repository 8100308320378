import React from 'react'
import CheckboxesExtraction from './CheckboxesExtraction'
import TextField from "@material-ui/core/TextField"
import {withStyles} from '@material-ui/core/styles'
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {examples_data} from './databreach_data'

const styles = {
	card: {
		minHeight: 140
	},
	title: {
		fontSize: 16,
		textAlign: 'left'
	},
	subtitle: {
		fontSize: 14,
		textAlign: 'left'
	},
	textField: {
		textAlign: 'left'
	},
	button: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	menu: {
		width: 400,
	}
}

function FormComponentSecondExtraction(props) {

	const {classes} = props

	const step1 =
		"Select an example below to see in each example what PII would be compromised in a data breach."

	const step2 =
		'Select the PII types you are interested in:'


	return (
		<div className='projects-container-text'>
			<div>
				<br/>
				<h3 className='projects-question'>{step1}</h3>
				<br/>
				<p className='projects-question'>Examples:</p>
				<TextField
					id="examples"
					select
					value={props.data.inputText}
					className={classes.textField}
					onChange={props.handleChangeText('inputText')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{examples_data.map(option => (
						<MenuItem key={option.value} value={option.value}>
							<Typography variant="inherit" noWrap>
								{option.label}
							</Typography>
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<TextField
					id="outlined-multiline-flexible"
					multiline
					rowsMax="20"
					value={props.data.inputText}
					onChange={props.handleChangeText('inputText')}
					margin="normal"
					name="inputText"
					variant="outlined"
					fullWidth
				/>
				<br/>
				<br/>
			</div>
			<br/>
			<div>
				<br/>
				<br/>
				<h3 className='projects-question'>{step2}</h3>
				<CheckboxesExtraction
					handleChange={props.handleChange}
					data={props.data}
				/>
			</div>
			<br/>
			<br/>
			<br/>
		</div>
	)
}

export default withStyles(styles)(FormComponentSecondExtraction)
