import React from "react"
import {
    fraud1,
    fraud2,
    fraud2a,
    fraud2b,
    fraud2c,
    fraud3,
    fraud4,
    fraud4a,
    fraud4b,
    fraud5,
    fraud6,
    fraud7
} from '../descriptions'

function FormComponentFirstFraud() {

    const titleFraud = "Fraud Validation";

    return (
        <div>
            <div className='projects-container-text'>
                <br/>
                <h2 className="projects-container-introduction-subtitle">{titleFraud}</h2>
                <div style={{textAlign: 'justify'}}>
                    <p className='projects-container-introduction-text'>{fraud1}</p>
                    <p className='projects-container-introduction-text'>{fraud2}</p>
                    <ul className='projects-container-introduction-text'>
						<li>{fraud2a}</li>
						<li>{fraud2b}</li>
						<li>{fraud2c}</li>
					</ul>
                    <p className='projects-container-introduction-text'>{fraud3}</p>
                    <p className='projects-container-introduction-text'>{fraud4}</p>
                    <ul className='projects-container-introduction-text'>
						<li>{fraud4a}</li>
						<li>{fraud4b}</li>
					</ul>
                    <p className='projects-container-introduction-text'>{fraud5}</p>
                    <p className='projects-container-introduction-text'>{fraud6}</p>
                    <p className='projects-container-introduction-text'>{fraud7}</p>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default FormComponentFirstFraud
