import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'

const styles = {
	card: {
		height: 240,
		width: 260,
		marginBottom: '5%',
        overflow: 'auto'
	},
	title: {
		fontSize: 18
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	entity: {
		fontSize: 18
	}
}

class TextCards extends React.Component {

	/*
	constructor(props){
	  super(props)
	}
	*/

	render() {
		const {classes, email, date, currency, cities, name} = this.props

		const Styles2 = {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-around',
			marginBottom: '3%',
			width: '90%',
			height: '90%',
			marginLeft: 'auto',
			marginRight: 'auto'
		}


		const textStyle = {
			fontFamily: 'Nanum Gothic',
			textAlign: 'center',
			fontSize: 16,
			fontWeight: 'normal',
			color: '#67676A',
			paddingTop: '1.5%',
			lineHeight: 1.3
		}

		//const bull = <span className={classes.bullet}>•</span>
		const nofields =
			"You did not select to extract this type of PII"

		return (
			<div>
				<div style={Styles2}>
					<Card className={classes.card}>
						<CardContent>
							<Typography className={classes.title} color="textSecondary" gutterBottom>
								Emails
							</Typography>
							{
								email.length === 0 ?
									<h4 style={textStyle}>{nofields}</h4> :
									email.map((item, i) => <li style={textStyle} key={i}>{item}</li>)
							}
						</CardContent>
					</Card>
					<Card className={classes.card}>
						<CardContent>
							<Typography className={classes.title} color="textSecondary" gutterBottom>
								Dates
							</Typography>
							{
								date.length === 0 ?
									<h4 style={textStyle}>{nofields}</h4> :
									date.map((item, i) => <li style={textStyle} key={i}>{item}</li>)
							}
						</CardContent>
					</Card>
					<Card className={classes.card}>
						<CardContent>
							<Typography className={classes.title} color="textSecondary" gutterBottom>
								Financials
							</Typography>
							{
								currency.length === 0 ?
									<h4 style={textStyle}>{nofields}</h4> :
									currency.map((item, i) => <li style={textStyle} key={i}>{item}</li>)
							}
						</CardContent>
					</Card>
				</div>
				<div style={Styles2}>
						<Card className={classes.card}>
							<CardContent>
								<Typography className={classes.title} color="textSecondary" gutterBottom>
									Locations
								</Typography>
								{
									cities.length === 0 ?
										<h4 style={textStyle}>{nofields}</h4> :
										cities.map((item, i) => <li style={textStyle} key={i}>{item}</li>)
								}
							</CardContent>
						</Card>
						<Card className={classes.card}>
							<CardContent>
								<Typography className={classes.title} color="textSecondary" gutterBottom>
									Names
								</Typography>
								{
									name.length === 0 ?
										<h4 style={textStyle}>{nofields}</h4> :
										name.map((item, i) => <li style={textStyle} key={i}>{item}</li>)
								}
							</CardContent>
						</Card>
				</div>
				<br/>
			</div>
		)
	}

}

export default withStyles(styles)(TextCards)
