import React from "react"
import FormComponentFirstReserving from "./FormComponentFirstReserving"
import FormComponentSecondReserving from "./FormComponentSecondReserving"
import FormComponentThirdReserving from "./FormComponentThirdReserving"

class GetStepContentComponentReserving extends React.Component{

  constructor(props){
    super(props)
  }
  render(){
    const stepIndex = this.props.step
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <FormComponentFirstReserving />
          </div>
        );
      case 1:
        return (
          <div>
            <FormComponentSecondReserving
              handleChange={this.props.handleChange}
              handleChangeText={this.props.handleChangeText}
              handleSubmit={this.props.handleSubmit}
              handleChangeSolicitors={this.props.handleChangeSolicitors}
              data={this.props.data}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FormComponentThirdReserving
              data={this.props.data}
            />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  }
}

export default GetStepContentComponentReserving
