import React from "react"
import FormComponentFirstFind from "./FormComponentFirstFind"
import FormComponentSecondFind from "./FormComponentSecondFind"
import FormComponentThirdFind from "./FormComponentThirdFind"

class GetStepContentComponentFind extends React.Component{

  constructor(props){
    super(props)
  }

  render(){
    const stepIndex = this.props.step
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <FormComponentFirstFind />
          </div>
        );
      case 1:
        return (
          <div>
            <FormComponentSecondFind
              handleChange={this.props.handleChange}
              handleChangeText={this.props.handleChangeText}
              handleSubmit={this.props.handleSubmit}
              data={this.props.data}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FormComponentThirdFind
              data={this.props.data}
            />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  }
}

export default GetStepContentComponentFind
