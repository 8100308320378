import React from 'react'
import TextField from "@material-ui/core/TextField"
import {withStyles} from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem'
import {
	injury,
	severity,
	businessLine,
	instructionType,
	track,
	matterRate,
	clMfu,
	matterLocation,
} from './reservingData'

import {reserving_intro} from '../descriptions'

import {claimantSolicitorsReduced} from './reservingSolicitors'

const styles = {
	card: {
		minHeight: 140
	},
	title: {
		fontSize: 16,
		textAlign: 'left',
		marginLeft: '5%'
	},
	subtitle: {
		fontSize: 14,
		textAlign: 'left',
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: '5%',
		width: 150
	},
	textField: {
		//width: 400,
		textAlign: 'left',
		//marginLeft: '5%'
	},
	menu: {
		//width: 400,
		//marginLeft: '5%',
		//marginRight: 'auto',
	}
}

function FormComponentSecondReserving(props) {

	const squareStyle = {
		display: 'inline-block',
		margin: '0 5px',
		transform: 'scale(0.8)',
		color: '#00677F'
	}

	const {classes} = props
	const square = <span style={squareStyle}>■</span>

	return (
		<div>
			<div className='projects-container-text'>
				<br/>
				<h3 className='projects-question'>{reserving_intro}</h3>
				<br/>
				<h3 className='projects-heading'>{square}Our involvement</h3>
				<br/>
				<h3 className='projects-question'>Clyde & Co office:</h3>
				<TextField
					id="standard-select-location"
					select
					value={props.data.matterLocation}
					className={classes.textField}
					onChange={props.handleChange('matterLocation')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{matterLocation.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>When were we instructed?</h3>
				<TextField
					id="date-receipt-instruction"
					type="date"
					onChange={props.handleChangeText}
					value={props.data.dateReceiptInstructions}
					name="dateReceiptInstructions"
					InputLabelProps={{
						shrink: true
					}}
					//className = {classes.textField}
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What is the nature of our instruction?</h3>
				<TextField
					id="standard-select-instructionType"
					select
					value={props.data.instructionType}
					className={classes.textField}
					onChange={props.handleChange('instructionType')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{instructionType.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>On what basis are we paid?</h3>
				<TextField
					id="standard-select-matterrate"
					select
					value={props.data.matterRate}
					className={classes.textField}
					onChange={props.handleChange('matterRate')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{matterRate.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What is our insured client?</h3>
				<TextField
					id="standard-select-clmfu"
					select
					value={props.data.clMfu}
					className={classes.textField}
					onChange={props.handleChange('clMfu')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{clMfu.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-heading'>{square}About the injury</h3>
				<br/>
				<h3 className='projects-question'>When did the incident occur?</h3>
				<TextField
					id="date-incident"
					type="date"
					onChange={props.handleChangeText}
					value={props.data.dateIncident}
					name="dateIncident"
					InputLabelProps={{
						shrink: true
					}}
					className={classes.textField}
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What type of injury was caused?</h3>
				<TextField
					id="standard-select-injuryParts"
					select
					value={props.data.injury}
					className={classes.textField}
					onChange={props.handleChange('injury')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{injury.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>Severity of injury:</h3>
				<TextField
					id="standard-select-injuryNature"
					select
					value={props.data.severity}
					className={classes.textField}
					onChange={props.handleChange('severity')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{severity.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-heading'>{square}About the claim</h3>
				<br/>
				<h3 className='projects-question'>What is the type of claim?</h3>
				<TextField
					id="standard-select-businessLine"
					select
					value={props.data.businessLine}
					className={classes.textField}
					onChange={props.handleChange('businessLine')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{businessLine.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What court track has the claim been assigned to?</h3>
				<TextField
					id="standard-select-track"
					select
					value={props.data.track}
					className={classes.textField}
					onChange={props.handleChange('track')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{track.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>What is the amount the claimant expects to recover? (£1,000 - £10,000)</h3>
				<TextField
					id='reserve'
					value={props.data.reserve}
					onChange={props.handleChange('reserve')}
					//type = "number"
					className={classes.textField}
					margin='normal'
					InputProps={{
						startAdornment: <InputAdornment position="start">£</InputAdornment>,
					}}
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>How many experts have we appointed (either solely or jointly)?</h3>
				<TextField
					id='experts'
					value={props.data.experts}
					onChange={props.handleChange('experts')}
					type="text"
					className={classes.textField}
					InputLabelProps={{
						shrink: true
					}}
					margin='normal'
					fullWidth
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>How many parties are involved in the claim (i.e. claimants and defendants including
					our client)? Minimum 2 </h3>
				<TextField
					id='solicitors'
					value={props.data.solicitors}
					onChange={props.handleChangeSolicitors('solicitors')}
					type="text"
					className={classes.textField}
					InputLabelProps={{
						shrink: true
					}}
					margin='normal'
					fullWidth
				/>
				<br/>
				<br/>
				<h3 className='projects-question'>Who are the claimant's solicitors?</h3>
				<TextField
					id="standard-select-claimant"
					select
					value={props.data.claimantSolicitors}
					className={classes.textField}
					onChange={props.handleChange('claimantSolicitors')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{claimantSolicitorsReduced.map(option => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
			</div>
			<br/>
			<br/>
			<br/>
			<br/>
		</div>
	)
}

export default withStyles(styles)(FormComponentSecondReserving)
