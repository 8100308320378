const description =
	"This website has been set up to demonstrate some of the opportunities in which machine learning " +
	"can add value to Clyde & Co. We have taken some of the projects we have been working on in the Lab and turned them into " +
	"demonstrations* you can play with.  This will enable you to see machine learning in action and, we hope, spur you to " +
	"think about how machine learning could benefit your practice."

const contact1 = "For any enquiry please write to:"

const contact2 = "data.lab@clydeco.com"

const homeP1 =
	"The Data Lab is the vehicle through which we are developing ways to help the firm extract value from its data."

const homeP2 =
	"We bring together the firm's legal knowledge and the Lab's machine learning and data analytics expertise to:"

const homeP2a =
	"Improve our legal services and the way we deliver them"

const homeP2b =
	"Help develop new legal products or services"

const homeP3 =
	"We also assist in preparing our legal practices to take commercial advantage of their data and technology-driven automation."

const homeP4 =
	"The Lab has created www.clydeco.ai to demonstrate some of the opportunities in which machine learning and data analytics can add value to Clyde & Co and its clients."

const homeHeading =
	"Delivering insights and value from our data";

const about1 =
	"The Data Lab is part of Clyde & Co’s innovation agenda and is key to enabling the firm to realise the opportunities created by data and Ai. The Lab is a three-way collaboration between our legal departments, our IT department and our external academic partner, University College London."

const about2 =
	"The Lab is managed by:"

const about2a =
	"Mark Wing (Partner)"

const about2b =
	"Nadine Bairle (Global Head of Legal Technology)"

const about2c =
	"Austen King (Global Head of Data & Analytics)"

const about3 =
	"The Lab is made up of machine learning engineers, Gonzalo Barrientos and Michal Lis, and trainee solicitor, Georgia Amos."

const about4 =
	"The Lab is further supported by a number of individuals from our IT department."

const about5 =
	"Contact us"

const about6 =
	"If you would like to contact or collaborate with Data Lab please send your enquiries to: " +
	"data.lab@clydeco.com"

const databreach1 =
	"Data Breach PII Identifier (the "

const databreach1a =
	") is designed to help our lawyers efficiently recognise personally identifiable information ("

const databreach1b =
	") compromised in a data breach. Current eDiscovery software solutions identify the types of documents compromised but do not determine the nature of the data which has been breached. The Identifier aims to overcome this."

const databreach2 =
	"The Identifier uses a combination of different searching algorithms to identify PII which follows a particular pattern such as: "

const databreach2a =
	"Dates"

const databreach2b =
	"Names"

const databreach2c =
	"Email addresses"

const databreach2d =
	"Location data"

const databreach2e =
	"Financial data"

const databreach3 =
	"It then categorises the PII to provide our lawyers via a dashboard with an overview of the PII in the compromised data set."

const databreach4 =
	"When a client experiences a data breach, our lawyers need to quickly inform them (and potentially regulators) of its nature and extent. Often the sheer quantity of data breached, and the lack of sufficient software solutions, creates challenges for our legal teams who have to rely on manual processes. The purpose of the Identifier is to:"

const databreach4a =
	"Help our lawyers meet the short timescales for reporting expected by clients and regulators in the event of a breach"

const databreach4b =
	"Provide our lawyers with an overview of relevant data immediately to enable them to focus their review appropriately"

const databreach4c =
	"Equip our cyber practices with a unique and advanced technology solution to help them win more work"

const databreach5 =
	"If you think the concept behind the Identifier could be of assistance in your practice, please contact the Data Lab."

const databreach6 =
	"This project was run in collaboration with Helen Bourne (Partner) and John Moran (Partner) who were the legal leads."

const databreach_example =
	"31 May 2019\n\n" +
	"Dear Brian Sparks, \n\n" +
	"I am writing to seek your help in resolving a problem that I am experiencing at work. It is a problem that is causing me some concern and that I have been " +
	"unable to solve without bringing to your attention. I hope in doing so we can deal with the issue quickly and amicably.\n\n" +
	"In December, before the office Christmas party, my boss said that she would have to review my working arrangements in the new year. I currently work from " +
	"home on Fridays as I find commuting into Liverpool very tiring and it costs me £10 every time I travel. Up to now, she has been very supportive of me, but " +
	"her attitude to me has changed since I told her I was pregnant. She has been criticising my work in ways she never did before, making derogatory comments " +
	"about me to some of my colleagues and they have started saying that I don't pull my weight and am never in the office. In fact, when I work from home, I " +
	"get through more work than I do in the office. My boss has acknowledged this in the past. I was very worried but I hoped things would get better.\n\n" +
	"However, on 2 January, my boss said that I would not be able to work from home until I went on maternity leave. When I asked why, she just said she needed " +
	"staff in the office. I said I would try, but I had been finding it hard before I was pregnant, and it was only likely to get harder as my pregnancy " +
	"advanced. She said if I couldn't work in the office, I should look at other options. I asked what she meant, and she said I was free to go elsewhere.\n\n" +
	"I raised this matter informally, but haven't been satisfied with the outcome. I tried talking to my boss but she refused to talk to me about this and " +
	"said she had said all she had to say on the matter.\n\n" +
	"I was very upset about this as I have been in this job for over 10 years and have not had any problems in the past. I enjoy my work and cannot understand " +
	"why her attitude to me has changed. I was so worried and upset that I have had to go to my GP and have been signed off sick.\n\n" +
	"I would welcome the chance to talk this through with you at a convenient time and place. I would like to be accompanied to the meeting by Josephine Sinden. " +
	"Please feel free to contact me at j.caine@gmail.com to arrange a meeting.\n\n " +
	"\n" +
	"Yours sincerely,\n\n" +
	"Jade Caine"

const databreach_conclusion =
	"This project is ongoing. There are a number of different elements which we need to address for the data breach team, and this is just one example of how" +
	" data can be extracted and categorised. This tool can be used to categorise the content of any other legal work, turning unstructured data into structured" +
	" data. If, after testing this tool, you have an idea of how the technology can be implemented in other areas, please do let us know."

const directions1 = 'Directions Reader classifies certain directions issued by the court in a case management order. It has been trained to recognise four categories of directions commonly issued by English courts:'

const directions1a = 'Disclosure'

const directions1b = 'Exchange of witness statements'

const directions1c = 'Exchange of expert reports'

const directions1d = 'Filing of expert joint statements (the '

const directions2 =
	"Directions Reader uses natural language processing ("

const directionsSpan2 =
	") to:"

const directions2a =
	"\"Read\" a court order and recognise directions/their associated dates"

const directions2b =
	'Correlate word patterns in those directions with the Categories. For example: the most correlated words for "Witness" include "32" (being a reference to Civil Procedure Rule Part 32), "signed", "serve", "fact", "witness" and "statements"; for "Joint Statement" it is "statement", "rule", "reasons" and "35"'

const directions2c =
	'Based on those patterns, allocate a probability as to whether a direction in the order falls in one of the Categories'

const directions2d =
	'Assign such a direction to the Category with the highest probability'

const directions3 =
	"Directions Reader correctly classifies 100% of the directions it has been trained on (ie it allocates all such directions to the correct Category)."

const directions4 =
	"Automation of this part of the litigation process could deliver increased legal team efficiency and the potential for increased matter profitability, particularly on fixed fee cases."

const directions5 =
	"Many legal documents contain common language and contain sentences which use a certain structure. " +
	"The issuing of orders by a court is merely one example. For example, NLP is used by software providers " +
	"to categorise common contractual terms (current on-market examples include contract review tools Luminance and Kira)."

const directions6 =
	"If you think Directions Reader may be of assistance in your practice, or if you have a similar idea, please contact the Data Lab."

const directions7 =
	"This project was run in collaboration with Mark Wing (Partner) who was the legal lead."

const directions_conclusion =
	"Now that you have used seen the demonstration in action, we urge you to think about how this might be used in your practice area. The directions reader" +
	" could be applied to work that involves simple words and repetitive terms, for example analysing a real estate contract to identify lease durations, or " +
	"extracting specific elements from a piece of regulatory text."

const reserving1 =
	"Reserving Estimator is designed to assist claims handlers predict reserves more accurately. " +
	"Accurate reserving is largely dependent on an individual's skill and experience in dealing with similar claims. " +
	"However, people commonly have a bias towards over-reserving. Reserving Estimator aims to remove that bias."

const reserving2 =
	"Reserving Estimator uses predictive analytics to:"

const reserving2a =
	"Take the information available at the outset of a personal injury case"

const reserving2b =
	"Consider the features of the claim which contribute to the potential costs at its conclusion, " +
	"including the costs of litigation"

const reserving2c =
	"Analyse patterns and correlations in the data"

const reserving2d =
	"Set an accurate reserve"

const reserving3 =
	"It draws on data available across a broad number of cases."

const reserving4=
	"Reserving Estimator performs better than the claims handler when its performance is measured across a book of claims. The claims handler performs better than Reserving Estimator when s/he is looking at individual claims. To develop a superior result by Reserving Estimator on both metrics, some further work on the algorithm is required."

const reserving5=
	"Accurate reserving is a core requirement for insurers and a performance metric against which they measure and instruct their lawyers (particularly on volume books of business). A number of our insurer clients measure our ability to accurately reserve the cases we manage and consider this when deciding whether to retain us. Reserving Estimator could be used to complement and enhance our claims handlers’ skill and experience."

const reserving6=
	"Predictive analytics is a valuable machine learning method which is being commonly used by other law firms, legal companies and LegalTech providers. Examples include solutions which make predictions about the outcome of a judgment or a lawyer's performance in the courtroom."

const reserving7=
	"If you think Reserving Estimator may be of assistance in your practice, or if you have a similar idea, please contact the Data Lab."

const reserving8=
	"This project was run in collaboration with Tony Cawley (Partner) who was the legal lead."

const reserving_intro =
	"You are asked to provide a reserve on a personal injury claim occurring in England for one of your insurer clients. Please input some details of the case."

const reserving_result =
	"Reserving Estimator is trained to consider features of the claim which contribute to the potential costs at its conclusion, including the costs of litigation."

const reserving_conclusion1 =
	"This project is ongoing as we try to improve the performance of the algorithm to ensure greater accuracy in setting reserves. When the algorithm is production level" +
	" ready, we can start integrating it into our case management system, allowing the machine to extract information on a case-by-case basis and relaying this information" +
	" back to the lawyer."

const reserving_conclusion2 =
	"The reserving tool could be used in other practice areas where predictions are based on previous knowledge or experiences, and there is a large data set that the machine can rely on."

const fraud1 =
	"Fraud Validation predicts whether a motor insurance claim flagged by an insurer as likely to be fraudulent can be proven to be fraudulent based on information known by the insurer at notification of the claim. It classifies claims into ones with a high probability of fraud likely to be proved and ones with a low probability. "

const fraud2 =
	"Fraud Validation uses predictive analytics to make a prediction based on its assessment of different factors such as:"

const fraud2a =
	"Incident location "

const fraud2b =
	"Claimant occupation"

const fraud2c =
	"Personal injury involvement"

const fraud3 =
	"Fraud Validation achieves 85% success at correctly classifying a claim as fraud likely to be proved. This is a high degree of accuracy."

const fraud4 =
	"Fraud is a significant problem for insurers both in terms of identifying and dealing with it. Proving fraud on a claim is costly. Whether Fraud Validation would lead to a saving for insurers depends on whether the cost of paying the claims which Fraud Validation wrongly characterises as not fraudulent is offset by the saving in not investigating the claims which Fraud Validation correctly characterises as not fraudulent. From the body of claims we used to build Fraud Validation:"

const fraud4a =
	"Average cost of claim without Fraud Validation = £13,100"

const fraud4b =
	"Average cost of claim with Fraud Validation = £10,100"

const fraud5 =
	"While there are a number of effective fraud prediction systems on the market which identify potentially fraudulent claims within a book of business, our legal specialism which we have embedded in Fraud Validation and which sets it apart, is in proving that a claim is indeed fraudulent."

const fraud6 =
	"If you think the concept behind Fraud Validation could be of assistance in your practice, please contact the Data Lab."

const fraud7 =
	"This project was run in collaboration with Damian Rourke (Partner) who was the legal lead."

const fraud_intro =
	"An insurer client has been notified of a claim in relation to a motor vehicle accident. The insurer believes that the claim is likely fraudulent. You have been asked to give your view on how likely it is that the claim can be proven to be fraudulent. Please input some details of the case below."

const fraud_conclusion =
	"Since the machine is able to achieve a high degree of accuracy with a relatively small amount of data, the view of the Lab is that this technology has a similar benefit in other practice " +
	"areas such as predicting the outcome of a case."

const coverage1 =
	"Coverage Advisor reads clauses in an insurance policy, links the terms in those clauses to relevant case law and previous Clyde & Co advices on the meaning of those terms, and provides guidance to Clyde & Co’s lawyers on the correct interpretation of such clauses."

const coverage2 =
	"Coverage Advisor is driven by a classification algorithm. It has been trained on four types of insurance policy clauses:"

const coverage2a =
	"Claim notification"

const coverage2b =
	"Reasonable precaution"

const coverage2c =
	"Fair representation"

const coverage2d =
	"Fraud"

const coverage3 =
	"The current version of Coverage Advisor is able to read all of the clauses above but has been configured to link only claim notification clauses to relevant case law and Clyde & Co advices on the terms in such clauses."

const coverage4 =
	"Clyde & Co has developed a successful business advising on whether a claim is covered under an insurance policy. However, our opportunities to advise our insurance clients in relation to certain coverage issues are becoming more limited. Coverage Advisor is a step towards augmenting our coverage advice where possible and bringing to our insurance clients the benefit of the firm’s collective expertise in this space. It has the potential to be an efficient and cost-effective service which we could provide to our insurance clients who need initial coverage guidance."

const coverage5 =
	"If you think Coverage Advisor may be of assistance in your practice, or if you have a similar idea, please contact the Data Lab."

const coverage6 =
	"This project was run in collaboration with Mark Wing (Partner) who was the legal lead. Versions of this proof have been proposed to the Lab by legal staff in the US, Scotland and Australia."

const coverage_conclusion =
	"This project is ongoing, but it has the potential to become a time and cost-effective service for insurer clients who need initial guidance on a claim. If, after testing this tool, " +
	"you have an idea of how the technology can be implemented in other areas, please do let us know."

const coverage_not_ready =
	"We have not configured this version of Coverage Advisor to provide a perspective on reasonable precaution, fair representation and fraud clauses. If you would like to see a result providing coverage advice, please press 'BACK' to return to the previous page and insert a claim notification clause in the 'Policy Wording' box or select one of the first two options from the drop-down menu."

const coverage_not_ready_2 =
	"This is where the advice for claim notification clauses is displayed. As you selected a "

const coverage_not_ready_3 =
	" clause, please press next."

const coverage_no_precedent =
	'The claim notification clause is expressed to be a condition precedent to liability. This means that you are entitled to decline the claim for late notification.'

const coverage_yes_precedent_1 =
	'The claim notification clause is not expressed to be a condition precedent to liability and there is no due observance clause. This means that you are not entitled to decline the claim for late notification.'

const coverage_yes_precedent_2 =
	'If the insurer has suffered any prejudice the insurer is entitled to offset such prejudice against the value of the claim. Prejudice is difficult to prove but can occur if, for example, the policyholder failed to notify the insurer of litigation and default judgment was entered.'

const coverage_time_immediately_1 =
	'Immediate notice means with "all reasonable speed considering the circumstances of the case" (Re Coleman\'s Depositories). Two months was held to be a breach of the condition to notify immediately after an accident, even though the notification was made 3 days after the injured third party made a claim (Re Williams and the Lancashire and Yorkshire Accident Insurance Co).'

const coverage_time_immediately_2 =
	'These words require a promptness with which written notice is to be given if there has been an event likely to give rise to a claim.'

const coverage_time_immediately_3 =
	'NB: when considering whether notice was given "immediately" all existing circumstances must be taken into account, including the available means of knowledge of the insured\'s personal representative of the existence of the policy and the identity of the insurance company. Consideration must then be given to the effect of any breach.'

const coverage_time_asarp_1 =
	'These words require a promptness with which written notice is to be given if there has been an event likely to give rise to a claim.'

const coverage_time_asarp_2 =
	'NB: when considering whether notice was given "as soon as possible" all existing circumstances must be taken into account, including the available means of knowledge of the insured\'s personal representative of the existence of the policy and the identity of the insurance company. Consideration must then be given to the effect of any breach.'

const coverage_time_asarp =
	'The insured had made four attempts to notify the insurer of which only the fourth constituted effective notice. It was held that this was not "as soon as reasonably practicable" (HLB Kidsons v Lloyd\'s Underwriters).'

const find1 =
	"Find A Friend enables Clyde & Co partners to identify their professional counterparts across Clyde & Co's global partner network."

const find2 =
	"Find A Friend uses natural language processing to compare a partner's profile (ie expertise and matters) with the profiles of other partners in the Find A Friend database. Find A Friend identifies partners with similar expertise, which it terms 'Neighbours'. The closeness of Neighbours is displayed in a 3D plot graph. The closer Neighbours are in the graph, the more skills and/or work types they share."

const find3 =
	"Find A Friend could facilitate internal networking, collaboration and cross-selling across Clyde & Co offices by giving partners access to an internal support and potential referral network. It could enable Neighbours from different offices to exchange ideas and/or resources. It would also help partners to assist clients who are looking for advisors around the world with similar skill sets to their contact partner."

const find4 =
	"The significant breadth and depth of our global network sets us apart positively from our competitors. However, the tools available to our people to get a quick and accurate view of other people's skills and matters are limited / cumbersome. Many lawyers revert to email circulars to obtain such information."

const find5 =
	"If you think Find A Friend may help you develop your internal network or enable you to collaborate with your counterparts elsewhere, or if you have an idea how it could be improved, please contact the Data Lab."

const find6 =
	"This project was run in collaboration with Mark Wing (Partner) who was the legal lead."

const find_conclusion =
	"This project enables fee earners to get to know other people in the firm to network and socialise, but might ultimately save time and costs for clients."

export {
	description,
	contact1,
	contact2,
	homeP1,
	homeP2,
	homeP2a,
	homeP2b,
	homeP3,
	homeP4,
	about1,
	about2,
	about2a,
	about2b,
	about2c,
	about3,
	about4,
	about5,
	about6,
    homeHeading,
	databreach1,
	databreach1a,
	databreach1b,
	databreach2,
	databreach2a,
	databreach2b,
	databreach2c,
	databreach2d,
	databreach2e,
	databreach3,
	databreach4,
	databreach4a,
	databreach4b,
	databreach4c,
	databreach5,
	databreach6,
	databreach_example,
	databreach_conclusion,
	directions1,
	directions1a,
	directions1b,
	directions1c,
	directions1d,
	directions2,
	directionsSpan2,
	directions2a,
	directions2b,
	directions2c,
	directions2d,
	directions3,
	directions4,
	directions5,
	directions6,
	directions7,
	directions_conclusion,
	reserving1,
	reserving2,
	reserving2a,
	reserving2b,
	reserving2c,
	reserving2d,
	reserving3,
	reserving4,
	reserving5,
	reserving6,
	reserving7,
	reserving8,
	reserving_intro,
	reserving_result,
	reserving_conclusion1,
	reserving_conclusion2,
	fraud1,
	fraud2,
	fraud2a,
	fraud2b,
	fraud2c,
	fraud3,
	fraud4,
	fraud4a,
	fraud4b,
	fraud5,
	fraud6,
	fraud7,
	fraud_intro,
	fraud_conclusion,
	coverage1,
	coverage2,
	coverage2a,
	coverage2b,
	coverage2c,
	coverage2d,
	coverage3,
	coverage4,
	coverage5,
	coverage6,
	coverage_conclusion,
	coverage_not_ready,
	coverage_not_ready_2,
	coverage_not_ready_3,
	coverage_no_precedent,
	coverage_yes_precedent_1,
	coverage_yes_precedent_2,
	coverage_time_immediately_1,
	coverage_time_immediately_2,
	coverage_time_immediately_3,
	coverage_time_asarp_1,
	coverage_time_asarp_2,
	find1,
	find2,
	find3,
	find4,
	find5,
	find6,
	find_conclusion
};
