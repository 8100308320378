const examples = [
  {
    value: 'On learning of any circumstances likely to give rise to a claim or on receiving verbal or written notice of any claim you must tell the insurer as soon as possible and give the Insurer all the assistance they may reasonably require.',
    label: 'On learning of any circumstances likely to give rise to a claim or on receiving verbal or written notice of any claim you must tell the insurer as soon as possible and give the Insurer all the assistance they may reasonably require.'
  }, {
    value: 'It is a condition precedent to the insurer’s liability under this part that in the event of any circumstances which could give rise to a claim the insured will immediately give notice to the insurer in writing.',
    label: 'It is a condition precedent to the insurer’s liability under this part that in the event of any circumstances which could give rise to a claim the insured will immediately give notice to the insurer in writing.'
  }, {
    value: 'You and any other person this insurance applies to must take all reasonable precautions to prevent accidents, loss or damage and keep insured property in good condition.',
    label: 'You and any other person this insurance applies to must take all reasonable precautions to prevent accidents, loss or damage and keep insured property in good condition.'
  }, {
    value: 'The insured shall take all reasonable precautions to prevent occurrences which may give rise to loss, destruction or damage.',
    label: 'The insured shall take all reasonable precautions to prevent occurrences which may give rise to loss, destruction or damage.'
  }, {
    value: 'You must take reasonable care to provide complete and accurate answers to the questions we ask when you take out, make changes to and renew your policy.',
    label: 'You must take reasonable care to provide complete and accurate answers to the questions we ask when you take out, make changes to and renew your policy.'
  }, {
    value: 'At inception and renewal of this policy the insured must disclose to the insurer all material facts in a clear and accessible manner and not misrepresent any material facts.',
    label: 'At inception and renewal of this policy the insured must disclose to the insurer all material facts in a clear and accessible manner and not misrepresent any material facts.'
  }, {
    value: 'If you or anyone acting for you knowingly makes a fraudulent or exaggerated claim under the policy we may cancel your policy.',
    label: 'If you or anyone acting for you knowingly makes a fraudulent or exaggerated claim under the policy we may cancel your policy.'
  }, {
    value: 'If you or anyone acting for you makes a claim which is fraudulent and/or intentionally exaggerated and/or supported by a fraudulent declaration and/or intentionally misrepresents or withholds any material fact relevant to this insurance; we will not pay any part of your claim or any other claim which you have made under the policy.',
    label: 'If you or anyone acting for you makes a claim which is fraudulent and/or intentionally exaggerated and/or supported by a fraudulent declaration and/or intentionally misrepresents or withholds any material fact relevant to this insurance; we will not pay any part of your claim or any other claim which you have made under the policy.'
  }
]

export {
  examples
};
