import React from "react"
import {
	coverage1,
	coverage2,
	coverage2a,
	coverage2b,
	coverage2c,
	coverage2d,
	coverage3,
	coverage4,
	coverage5,
	coverage6,
} from '../descriptions'

function FormComponentFirst() {

	const titleCoverage = "Coverage Advisor"

	return (
		<div>
			<div className='projects-container-text'>
				<br/>
				<h2 className="projects-container-introduction-subtitle">{titleCoverage}</h2>
				<div style={{textAlign: 'justify'}}>
					<p className='projects-container-introduction-text'>{coverage1}</p>
					<p className='projects-container-introduction-text'>{coverage2}</p>
					<ul className='projects-container-introduction-text'>
						<li>{coverage2a}</li>
						<li>{coverage2b}</li>
						<li>{coverage2c}</li>
						<li>{coverage2d}</li>
					</ul>
					<p className='projects-container-introduction-text'>{coverage3}</p>
					<p className='projects-container-introduction-text'>{coverage4}</p>
					<p className='projects-container-introduction-text'>{coverage5}</p>
					<p className='projects-container-introduction-text'>{coverage6}</p>
				</div>
			</div>
			<br/>
			<br/>
		</div>
	)
}

export default FormComponentFirst
