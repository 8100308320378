import React from 'react'
import PersistentDrawerLeftFraud from './MenuFraud'
import HorizontalLabelPositionBelowStepperFraud from './HorizontalStepperFraud'

const StepperCustom = {
  textAlign: 'center',
  marginTop: "4%"
}

const Fraud= () => (
  <div>
    <br />
    <div style={StepperCustom}>
      <HorizontalLabelPositionBelowStepperFraud />
    </div>
  </div>
)

export default Fraud
