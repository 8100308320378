const examples = [
  {
    value: 'Each party shall provide standard disclosure by list to all other parties by 4pm on 01 March 2019.',
    label: 'Each party shall provide standard disclosure by list to all other parties by 4pm on 01 March 2019.'
  }, {
    value: 'By 4pm on 13 February 2019 the parties must give to each other standard disclosure of documents by list and category.',
    label: 'By 4pm on 13 February 2019 the parties must give to each other standard disclosure of documents by list and category.'
  }, {
    value: 'By 5pm on 28 September 2019 each party must serve and file with the Court a list for standard disclosure of electronically stored documents.',
    label: 'By 5pm on 28 September 2019 each party must serve and file with the Court a list for standard disclosure of electronically stored documents.'
  }, {
    value: 'By 5pm on 17 July 2019 the expert reports must be disclosed to the parties.',
    label: 'By 5pm on 17 July 2019 the expert reports must be disclosed to the parties.'
  }, {
    value: 'Expert reports must be filed by 4pm on 23 October 2019.',
    label: 'Expert reports must be filed by 4pm on 23 October 2019.'
  }, {
    value: 'The experts shall meet and produce a statement for the Court identifying the issues on which they agree and disagree by 4pm on 13 January 2019 with reasons given for any disagreement.',
    label: 'The experts shall meet and produce a statement for the Court identifying the issues on which they agree and disagree by 4pm on 13 January 2019 with reasons given for any disagreement.'
  }, {
    value: 'The experts shall, before they exchange their reports, discuss and narrow the issues between them and by 29 September 2019 prepare and file a Statement for the Court showing those issues on which they are agreed and those issues on which they disagree and a summary of their reasons for disagreeing.',
    label: 'The experts shall, before they exchange their reports, discuss and narrow the issues between them and by 29 September 2019 prepare and file a Statement for the Court showing those issues on which they are agreed and those issues on which they disagree and a summary of their reasons for disagreeing.'
  }, {
    value: 'Signed statements of witnesses of fact must be served by 5pm on 13 September 2019.',
    label: 'Signed statements of witnesses of fact must be served by 5pm on 13 September 2019.'
  }, {
    value: 'Each party shall serve on every other party the witness statements of all witnesses of fact to be relied upon by that party. There shall be simultaneous exchange of such statements by 5pm on 12 August 2019.',
    label: 'Each party shall serve on every other party the witness statements of all witnesses of fact to be relied upon by that party. There shall be simultaneous exchange of such statements by 5pm on 12 August 2019.'
  }
]

export {
  examples
};
