import React from 'react'
import HorizontalLabelPositionBelowStepper from './HorizontalStepper'
const StepperCustom = {
  textAlign: 'center',
  marginTop: "4%"
}

const Coverage = () => (
  <div>
    <br />
    <div style = {StepperCustom}>
      <HorizontalLabelPositionBelowStepper />
    </div>
  </div>
)

export default Coverage