import React from "react"
import FormComponentPrecedent from './FormComponentPrecedent'
import {
	coverage_no_precedent,
	coverage_not_ready_2,
	coverage_not_ready_3,
	coverage_time_immediately_1,
	coverage_time_immediately_2,
	coverage_time_immediately_3,
	coverage_time_asarp_1,
	coverage_time_asarp_2,
	coverage_yes_precedent_1,
	coverage_yes_precedent_2
} from '../descriptions'

function FormComponentFourth(props) {

	const textStyle = {
		fontSize: '15px',
		fontWeight: 'normal',
		color: '#67676A',
		paddingBottom: '1%',
		lineHeight: 1.4,
		textAlign: 'justify'
	}

	const a3 = "See Clyde & Co analysis of conditions precedent"

	const a4 = 'https://www.clydeco.com/blog/insurance-hub/article/conditions-precedent-denso-manufacturing-uk-ltd-v-great-lakes-reinsurance-u'

	const a5 = 'The delay between the incident and the notification (in days) is: '

	const a6 = 'The requirement under the policy is for a claim to be notified: '

	const asapUrl = 'https://www.clydeco.com/blog/insurance-hub/article/notification-maccaferri-v-zurich-insurance-court-of-appeal'

	const asapHyper = "See Clyde & Co's analysis Zurich -v- Maccaferri"


	return (
		<div>
			<div className="projects-container-text">
				<br/>
				{props.data.clause.clause_predict === 'Claim notification' ?
					<div>
						<div>
							{props.data.isAware === 'yes' ?
								<p style={textStyle}>{a5} {props.data.frame1}</p>
								: props.data.isAware === 'no' ?
									<p style={textStyle}>{a5} {props.data.frame2}</p> : null}
						</div>
						<br/>
						<div>
							<p style={textStyle}>{a6} <span> <b>{props.data.clause.time_phrase} </b></span></p>
							{props.data.clause.time_phrase === 'Immediately' ?
								<div>
									<p style={textStyle}>{coverage_time_immediately_1}</p>
									<p style={textStyle}>{coverage_time_immediately_2}</p>
									<p style={textStyle}><a href={asapUrl}>{asapHyper}</a></p>
									<p style={textStyle}>{coverage_time_immediately_3}</p>
								</div> :
								props.data.clause.time_phrase === 'As soon as possible' ?
									<div>
										<p style={textStyle}>{coverage_time_asarp_1}</p>
										<p style={textStyle}><a href={asapUrl}>{asapHyper}</a></p>
										<p style={textStyle}>{coverage_time_asarp_2}</p>
									</div>
									: null
							}
							<br/>
						</div>
						<br/>
						<FormComponentPrecedent
							handleChange={props.handleChange}
							data={props.data}
						/>
						<br/>
						<div>
							{props.data.isPrecedent === 'no' ?
								<div>
									<p style={textStyle}>{coverage_yes_precedent_1}</p>
									<p style={textStyle}><a href={a4}>{a3}</a></p>
									<p style={textStyle}>{coverage_yes_precedent_2}</p>
								</div> :
								props.data.isPrecedent === 'yes' ?
									<div>
										<p style={textStyle}>{coverage_no_precedent}</p>
									</div> : null
							}
							<br/>
							<br/>
						</div>
					</div> :
					<h3 className='projects-question'> </h3>
				}
			</div>
			<br/>
		</div>
	)
}

export default FormComponentFourth
