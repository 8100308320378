import React from "react"
import TextField from "@material-ui/core/TextField"
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import {examples} from './coverageData'

const styles = {
	textField: {
		textAlign: 'left'
	},
	menu: {
		width: 400,
		//marginLeft: '5%',
		//marginRight: 'auto',
	}
}


function FormComponentInitial(props) {

	const {classes} = props

	const a4 = "Please copy the text of the policy term you would like Coverage Advisor's assistance with or select one of the examples in the drop-down menu below."

	const a5 =
		'Please copy the text of the policy term you would like assistance with.'

	const coverage_intro =
		'An insurer client has asked for your advice on policy coverage.\n' +
		'Please copy the text of the policy term you would like coverage advice with or select one of the examples below.'

	const example = {
		textAlign: 'left',
		fontSize: 15,
		fontWeight: 'normal',
		color: '#67676A',
		lineHeight: 1.3
	}

	return (
		<div>
			<form>
				<h3 className='projects-question'>{coverage_intro}</h3>
				<br/>
				<br/>
				{/*<h3 className='projects-question'>{a4}</h3>*/}
				{/*<RadioGroup*/}
				{/*	aria-label="Please answer"*/}
				{/*	name="policyType"*/}
				{/*	value={props.data.policyType}*/}
				{/*	onChange={props.handleChange}*/}
				{/*>*/}
				{/*	<FormControlLabel value="PI" control={<Radio color="primary"/>} label="Property Insurance"/>*/}
				{/*	<FormControlLabel value="GL" control={<Radio color="primary"/>}*/}
				{/*					  label="General Liability Insurance (EL, PL or Products)"/>*/}
				{/*</RadioGroup>*/}
				{/*<br/>*/}
			</form>
			<form onSubmit={props.handleSubmit}>
				<br/>
				{/*<h3 className='projects-question'>{a5}</h3>*/}
				<TextField
					id="outlined-multiline-flexible"
					label="Policy Wording"
					multiline
					rowsMax="6"
					value={props.data.wording}
					onChange={props.handleChange}
					margin="normal"
					name="wording"
					variant="outlined"
					fullWidth
				/>
				<br/>
				<br/>
				<p style={example}>Examples:</p>
				<TextField
					id="examples"
					select
					value={props.data.wording}
					className={classes.textField}
					onChange={props.handleChangeText('wording')}
					SelectProps={{
						MenuProps: {
							className: classes.menu,
						},
					}}
					margin="normal"
					fullWidth
				>
					{examples.map(option => (
						<MenuItem key={option.value} value={option.value}>
							<Typography variant="inherit" noWrap>
								{option.label}
							</Typography>
						</MenuItem>
					))}
				</TextField>
				<br/>
				<br/>
			</form>
		</div>
	)
}

export default withStyles(styles)(FormComponentInitial)
