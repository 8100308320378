import React from "react"
import TextField from "@material-ui/core/TextField"
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel';

function FormComponentLiability(props) {

	const a1 = 'When did the incident occur?'
	const a3 = 'Was the policyholder aware of the incident at this date?'

	return (
		<div>
			<form>
				<h3 className='projects-question'>{a1}</h3>
				<br/>
				<TextField
					id="date"
					label="Date"
					type="date"
					fullWidth
					onChange={props.handleChange}
					value={props.data.notification}
					name="notification"
					InputLabelProps={{
						shrink: true
					}}
				/>
				<br/>
				<br/>
				<br/>
				<h3 className='projects-question'>{a3}</h3>
				<br/>
				<RadioGroup
					aria-label="Please answer"
					name="isAware"
					value={props.data.isAware}
					onChange={props.handleChange}
				>
					<FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes"/>
					<FormControlLabel value="no" control={<Radio color="primary"/>} label="No"/>
				</RadioGroup>
				<br/>
			</form>
		</div>
	)
}

export default FormComponentLiability
