import React from "react"
import FormComponentFirstFraud from "./FormComponentFirstFraud"
import FormComponentSecondFraud from "./FormComponentSecondFraud"
import FormComponentThirdFraud from "./FormComponentThirdFraud"

class GetStepContentComponentFraud extends React.Component{

  constructor(props){
    super(props)
  }

  render(){
    const stepIndex = this.props.step
    switch (stepIndex) {
      case 0:
        return (
          <div>
            <FormComponentFirstFraud />
          </div>
        );
      case 1:
        return (
          <div>
            <FormComponentSecondFraud
              handleChange={this.props.handleChange}
              handleChangeText={this.props.handleChangeText}
              handleSubmit={this.props.handleSubmit}
              data={this.props.data}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <FormComponentThirdFraud
              data={this.props.data}
            />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  }
}

export default GetStepContentComponentFraud
