import React from 'react';
import {
	BrowserRouter as Router,
  	NavLink, Link
} from "react-router-dom";
import './App.css'

const classNames = require('classnames')

function NavBar() {
	const [openDrawer, setOpenDrawer] = React.useState(false)
	const clydecoNavBar = classNames({
		'navbar-box': true,
		'nav-active': openDrawer
	});

	return(
		<div style={{marginTop:0, paddingTop: 0}}>
			<div className="clydeco-ai">
				<div style={{width: '50%', marginLeft: '5%'}}>
					<div className="clydeco-ai-text">
						<img src='./clydeco_nav_bar.png' className="logo-clydeco" alt='Logo Clydeco'/>
					</div>
				</div>
				{/*<div className="text-logo">*/}
				{/*	<div>*/}
				{/*		<h2>a DataLab product</h2>*/}
				{/*	</div>*/}
				{/*	<div>*/}
				{/*		<img src='./datalab_icon.png' className="logo" alt='Logo'/>*/}
				{/*	</div>*/}
				{/*</div>*/}
				<div style={{width: '50%', marginRight: '5%'}}>
					{/*<div className="clydeco-ai-text-right">*/}
					{/*	/!*<img src='./datalab_icon_text.png' className="logo" alt='Logo Clydeco'/>*!/*/}
					{/*</div>*/}
				</div>
				<div className="burger" onClick={() => setOpenDrawer(!openDrawer)}>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<div className={clydecoNavBar}>
				<div className="navbar-links">
					<NavLink
						to="/home"
						activeStyle={{
							fontWeight: "bold",
						}}>
						Home
					</NavLink>
					<NavLink
						to="/projects"
						activeStyle={{
							fontWeight: "bold",
						}}>
						Projects
					</NavLink>
					<NavLink
						to="/about-us"
						activeStyle={{
							fontWeight: "bold",
						}}>
						About us
					</NavLink>
					{/*<NavLink*/}
					{/*	to="/contact-us"*/}
					{/*	activeStyle={{*/}
					{/*		fontWeight: "bold",*/}
					{/*	}}>*/}
					{/*	Contact us*/}
					{/*</NavLink>*/}
				</div>
			</div>
		</div>
	)
}

export default NavBar