import React from "react"
import TextField from "@material-ui/core/TextField"

function FormComponentAware(props) {

	const a1 = "When did the customer become aware of the incident?"

	return (
		<div>
			<form>
				<h3 className='projects-question'>{a1}</h3>
				<br/>
				<TextField
					id="date"
					label="Date"
					type="date"
					fullWidth
					onChange={props.handleChange}
					value={props.data.awareness}
					name="awareness"
					InputLabelProps={{
						shrink: true
					}}
				/>
              <br/>
			</form>
		</div>
	)
}

export default FormComponentAware
