import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(15),
    border: '1px solid #dadde9',
    '& b': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}))(Tooltip);

function HoverText(props) {
  return (
    <div>
      <HtmlTooltip
        title={
          <React.Fragment>
            {props.text}
          </React.Fragment>
        }
      >
        <b>{props.keyword}</b>
      </HtmlTooltip>
    </div>
  );
}

export default HoverText;
