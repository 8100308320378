import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
	Redirect
} from "react-router-dom";
import Paper from '@material-ui/core/Paper'
import '../App.css'
import GetStepContentComponent from './GetStepContentComponent'
import {
	coverage_conclusion,
} from '../descriptions'
import StyledButton from "../StyledButton";

const styles = theme => ({
	root: {
		width: '65%',
		margin: 'auto'
	},
	backButton: {
		marginRight: theme.spacing.unit,
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	stepIcon: {
		color: '#00677F !important'
	},
});

function getSteps() {
	return ['Welcome', 'Initial Information', 'Additional Information', 'Advice'];
}

function parseData(str) {
	var mdy = str.split("-")
	return new Date(mdy[0], mdy[1] - 1, mdy[2])
}

function datediff(first, second) {
	return Math.round((second - first) / (1000 * 60 * 60 * 24))
}


const example1 = 'On learning of any circumstances likely to give rise to a ' +
	'claim or on receiving verbal or written notice of any claim you must tell the ' +
	'insurer as soon as possible and give the Insurer all the assistance they may reasonably require.'

class HorizontalLabelPositionBelowStepper extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			activeStep: 0,
			isHome: false,
			email: "",
			isEngW: "",
			isConsumer: "",
			policyType: "PI",
			wording: example1,
			clause: {},
			incident: "2019-02-12",
			notification: "2019-02-12",
			awareness: "2019-02-12",
			cause: "",
			isAware: "",
			isPrecedent: "",
			frame1: 0,
			frame2: 0,
		}
		this.handleChangeInternal = this.handleChangeInternal.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleChange = this.handleChange.bind(this)
	}

	handleNext = () => {
		this.setState(state => ({
			activeStep: state.activeStep + 1,
		}));
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1,
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 1,
			email: "",
			isEngW: "",
			isConsumer: "",
			policyType: "",
			wording: example1,
			clause: {},
			incident: "2019-02-12",
			notification: "2019-02-12",
			awareness: "2019-02-12",
			cause: "",
			isAware: "",
			isPrecedent: "",
			frame1: 0,
			frame2: 0,
		});
	};

	handleClickHome = () => {
		this.setState(prevState => {
			return {
				isHome: !prevState.isHome
			}
		})
	}

	handleChangeInternal(event) {
		const {name, value, type} = event.target

		if (type === "date") {
			this.setState({[name]: value})
			console.log(name, value)
			console.log(this.state.notification)

			if (name === "notification") {
				let daysIncident = parseData(this.state.incident)
				let daysNotification = parseData(value)
				let daysAwareness = parseData(this.state.awareness)
				let daysFrame1 = datediff(daysNotification, daysIncident)
				let daysFrame2 = datediff(daysNotification, daysAwareness)
				this.setState({
					frame1: daysFrame1,
					frame2: daysFrame2
				})
			}
			if (name === "awareness") {
				let daysNotification = parseData(this.state.notification)
				let daysAwareness = parseData(value)
				let daysFrame2 = datediff(daysNotification, daysAwareness)
				this.setState({frame2: daysFrame2})
			}
			if (name === "incident") {
				let daysIncident = parseData(value)
				let daysNotification = parseData(this.state.notification)
				let daysFrame1 = datediff(daysNotification, daysIncident)
				this.setState({frame1: daysFrame1})
			}
			//console.log(daysFrame1)
		} else {
			this.setState({[name]: value})
		}
	}

	handleChange = name => event => {
		this.setState({[name]: event.target.value})
	}

	handleSubmit(event) {
		event.preventDefault()
		axios.post("/api/inference",
			{
				data_new: this.state.wording
			},{
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				}
			})
			.then(function(response){
				this.setState({
					clause: response.data,
					activeStep: this.state.activeStep + 1
				});
			}.bind(this))
	}

	render() {
		const {classes} = this.props;
		const steps = getSteps();
		const {activeStep} = this.state;

		if (this.state.isHome) {
			return <Redirect to={{pathname: '/home'}}/>
		}


		return (
			<div className="projects-container">
				<Paper classes={{root: 'projects-paper'}} elevation={0}>
					<div>
						<div>
							<GetStepContentComponent
								step={activeStep}
								handleChange={this.handleChangeInternal}
								handleChangeText={this.handleChange}
								data={this.state}
							/>
							<div>
								{activeStep === 3 ?
									<StyledButton
										variant="contained"
										color="primary"
										className={classes.backButton}
										onClick={this.handleReset}
									>
										Reset
									</StyledButton>
									:
									<StyledButton
										disabled={activeStep === 0}
										onClick={this.handleBack}
										className={classes.backButton}
									>
										Back
									</StyledButton>}
								{activeStep === 1 ?
									<StyledButton
										type="submit"
										variant="contained"
										color="primary"
										onClick={this.handleSubmit}>
										Next
									</StyledButton> :
									activeStep === 3 ?
										<StyledButton
											onClick={this.handleClickHome}
											variant="contained"
											color="primary"
										>
											Next Project
										</StyledButton>
									:
									<StyledButton variant="contained" color="primary" onClick={this.handleNext}>
										Next
									</StyledButton>
								}
							</div>
						</div>
					</div>
				</Paper>
			</div>
		);
	}
}

HorizontalLabelPositionBelowStepper.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepper);
