import React from "react"
import {
    databreach1,
	databreach1a,
	databreach1b,
    databreach2,
    databreach2a,
    databreach2b,
    databreach2c,
    databreach2d,
	databreach2e,
    databreach3,
    databreach4,
    databreach4a,
    databreach4b,
    databreach4c,
    databreach5,
    databreach6
} from '../descriptions'

function FormComponentFirstExtraction() {

    const titleDataBreach = "Data Breach PII Identifier";

    return (
        <div>
            <div className='projects-container-text'>
                <br/>
                <h2 className="projects-container-introduction-subtitle">{titleDataBreach}</h2>
                <div>
                    <p className='projects-container-introduction-text'>{databreach1}<strong>Identifier</strong>{databreach1a}<strong>PII</strong>{databreach1b}</p>
                    <p className='projects-container-introduction-text'>{databreach2}</p>
                    <ul className='projects-container-introduction-text'>
						<li>{databreach2a}</li>
						<li>{databreach2b}</li>
						<li>{databreach2c}</li>
						<li>{databreach2d}</li>
                        <li>{databreach2e}</li>
					</ul>
                    <p className='projects-container-introduction-text'>{databreach3}</p>
                    <p className='projects-container-introduction-text'>{databreach4}</p>
                    <ul className='projects-container-introduction-text'>
						<li>{databreach4a}</li>
						<li>{databreach4b}</li>
						<li>{databreach4c}</li>
					</ul>
                    <p className='projects-container-introduction-text'>{databreach5}</p>
                    <p className='projects-container-introduction-text'>{databreach6}</p>
                </div>
            </div>
            <br/>
            <br/>
        </div>
    )
}

export default FormComponentFirstExtraction
