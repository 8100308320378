import React from 'react'
import PersistentDrawerLeftFind from './MenuFind'
import HorizontalLabelPositionBelowStepperFind from './HorizontalStepperFind'

const StepperCustom = {
  textAlign: 'center',
  marginTop: "4%"
}

const Find= () => (
  <div>
    <br />
    <div style={StepperCustom}>
      <HorizontalLabelPositionBelowStepperFind />
    </div>
  </div>
)

export default Find
