import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles';
import {
	Redirect
} from "react-router-dom";
import StyledButton from "../StyledButton";
import Paper from '@material-ui/core/Paper'
import '../App.css'
import GetStepContentComponentExtraction from './GetStepContentComponentExtraction'
import {
	databreach_conclusion,
	databreach_example,
} from '../descriptions'

const styles = theme => ({
	root: {
		width: '65%',
		margin: 'auto'
	},
	backButton: {
		marginRight: theme.spacing.unit,
	},
	instructions: {
		marginTop: theme.spacing.unit,
		marginBottom: theme.spacing.unit,
	},
	stepIcon: {
		color: '#00677F !important'
	},
});

function getSteps() {
	return ['Welcome', 'Get Information', 'Results'];
}

class HorizontalLabelPositionBelowStepperExtraction extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			activeStep: 0,
			isFraud: false,
			inputText: databreach_example,
			email: false,
			date: false,
			currency: false,
			cities: false,
			name: false,
			isTextExtracted: false,
			entitiesText: {},
		}
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleChangeText = this.handleChangeText.bind(this)
	}

	handleNext = () => {

		this.setState(state => ({
			activeStep: state.activeStep + 1,
		}));
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1,
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 1,
			inputText: databreach_example,
			email: false,
			date: false,
			currency: false,
			cities: false,
			name: false,
		});
	};

	handleClickFraud = () => {
		this.setState(prevState => {
			return {
				isFraud: !prevState.isFraud
			}
		})
	}

	handleSubmit(event) {
		event.preventDefault()
		axios.post("/api/extract",
			{
				data_options:{
					'text': this.state.inputText,
					'email': this.state.email,
					'date': this.state.date,
					'currency': this.state.currency,
					'cities': this.state.cities,
					'name': this.state.name
				}
		},{
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			}
		}).
		then(function(response){
			this.setState({
				entitiesText: response.data,
				isTextExtracted: !this.state.isTextExtracted,
				activeStep: this.state.activeStep + 1
			});
			}.bind(this))
	}

	handleChange = name => event => {
		this.setState({[name]: event.target.checked})
	}

	// handleChangeText(event) {
	// 	const {name, value} = event.target
	// 	this.setState({[name]: value})
	// }

	handleChangeText = name => event => {
		this.setState({[name]: event.target.value})
	}

	render() {
		const {classes} = this.props;
		const steps = getSteps();
		const {activeStep} = this.state;

		if (this.state.isFraud) {
			return <Redirect to={{pathname: '/fraud'}}/>
		}

		return (
			<div className="projects-container">
				<Paper classes={{root: 'projects-paper'}} elevation={0}>
					<div>
						<div>
							<GetStepContentComponentExtraction
								step={activeStep}
								handleChange={this.handleChange}
								handleChangeText={this.handleChangeText}
								handleSubmit={this.handleSubmit}
								data={this.state}
							/>
							<div>
								{activeStep === 2 ?
									<StyledButton
										variant="contained"
										color="primary"
										className={classes.backButton}
										onClick={this.handleReset}
									>
										Reset
									</StyledButton>
									:
									<StyledButton
										disabled={activeStep === 0}
										onClick={this.handleBack}
										className={classes.backButton}
									>
										Back
									</StyledButton>}
								{activeStep === 1 ?
									<StyledButton
										type="submit"
										variant="contained"
										color="primary"
										onClick={this.handleSubmit}>
										Next
									</StyledButton> :
									activeStep === 2 ?
										<StyledButton
											onClick={this.handleClickFraud}
											variant="contained"
											color="primary"
										>
											Next Project
										</StyledButton>
										:
										<StyledButton variant="contained" color="primary" onClick={this.handleNext}>
											Next
										</StyledButton>
								}
							</div>
						</div>
					</div>
				</Paper>
			</div>
		);
	}
}

HorizontalLabelPositionBelowStepperExtraction.propTypes = {
	classes: PropTypes.object,
};

export default withStyles(styles)(HorizontalLabelPositionBelowStepperExtraction);
