import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  }
});

function CheckboxesExtraction(props){
  const { classes } = props;

  return (
    <div>
      <div className={classes.root}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                checked={props.data.date}
                onChange={props.handleChange('date')}
                value="date" />
              }
              label="Dates"

            />
            <FormControlLabel
              control={
                <Checkbox
                checked={props.data.email}
                onChange={props.handleChange('email')}
                value="email" />
              }
              label="Emails"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.data.currency}
                  onChange={props.handleChange('currency')}
                  value="currency"
                />
              }
              label="Financials"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.data.cities}
                  onChange={props.handleChange('cities')}
                  value="cities"
                />
              }
              label="Locations"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.data.name}
                  onChange={props.handleChange('name')}
                  value="name"
                />
              }
              label="Names"
            />
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );

}

CheckboxesExtraction.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckboxesExtraction );
