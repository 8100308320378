const MachineLearningKeyword =
  "Machine learning is a subset of artificial intelligence; it is the ability of a machine to retrieve a set of data and learn for itself."

const NlpKeyword =
  "Natural language processing (NLP) makes it possible for computers to read text, hear speech, interpret it, measure sentiment and determine which parts are important"

const PredictiveKeyword =
  "Predictive analytics seeks to forecast future events such as customer behaviour and machine failure."

const ProcessMappingKeyword =
  "Process mapping is a way to note the activities related to a specific business process."

const ClassificationKeyword =
  "Classification algorithms categorise information."

export {
  MachineLearningKeyword,
  NlpKeyword,
  PredictiveKeyword,
  ProcessMappingKeyword,
  ClassificationKeyword
}
