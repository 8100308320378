import React from 'react'
import Plot from 'react-plotly.js'
import '../App.css'

class SkillsPlot extends React.Component {

	constructor(props) {
		super(props)
	}

	render() {

		var dataSkills = this.props.tableSkills
		var data1 = []
		var numberNeighbours = this.props.result.length

		for (var i = 0; i < numberNeighbours + 1; i++) {
			data1[i] = {
				x: [dataSkills[i].X1],
				y: [dataSkills[i].X2],
				z: [dataSkills[i].X3],
				text: dataSkills[i].BillName + '<br>' + dataSkills[i].OfficeDescription,
				name: dataSkills[i].OfficeDescription,
				mode: 'markers',
				opacity: 0.7,
				marker: {
					size: 5,
					line: {
						width: 0.5,
						color: 'white'
					}
				},
				type: 'scatter3d',
				hoverinfo: 'text'
			}
		}

		var layout = {
			// width: 420,
			// height: 400,
			title: '3D Partner Space',
			margin: {
				l: 10,
				r: 10,
				t: 65,
				b: 20,
				pad: 4
			},
			//legend: {orientation:'h'},
			showlegend: false,
			hovermode: 'x'
		}
		var config = {responsive: true}


		return (
			<div style={{margin: 0}}>
				<Plot
					data={data1}
					layout={layout}
					className="find-plot"
					config={config}
				/>
			</div>
		);
	}
}

export default SkillsPlot
